import { AssignedContext } from '@contexts/AssignedContext'
import { TicketContext } from '@contexts/TicketContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { ITicket, TicketProps } from '@/types/ITicket'
import { Button, Card, Col, List, Pagination, Row, Tag, Typography } from 'antd'
import { useState } from 'react'
import {
  ClockCircleOutlined,
  EditOutlined,
  EllipsisOutlined
} from '@ant-design/icons'
import { ModalContext } from '@contexts/ModalContext'
import { Link, useNavigate } from 'react-router-dom'
import ModalTicket from '../Modal'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import ProgressTicketsDrawer from '@components/Ticket/ProgressTicketsDrawer'
import { useTranslation } from 'react-i18next'
import { Text } from '@components/common/Text'
import DrawerProvider, { DrawerContext } from '@contexts/DrawerContext'
import Ticket from '@pages/Ticket'
import useWindowResize from '@hooks/useWindowResize'
import TicketHead from '../Head'
import TicketDrawer, { useTicketDrawer } from '../TicketDrawer'

const { Paragraph } = Typography

const StatusHead = ({
  status
}: {
  status: keyof typeof TICKETSTATUSESCOLORS
}) => {
  const { t } = useTranslation()
  return (
    <Tag
      color={TICKETSTATUSESCOLORS[status].color}
      icon={
        TICKETSTATUSESCOLORS[status as keyof typeof TICKETSTATUSESCOLORS].icon
      }
    >
      {t(status)}
    </Tag>
  )
}

const DrawerItemHead = ({ data }: { data: ITicket }) => {
  const { t } = useTranslation()
  return (
    <Link to={`ticket/${data.tkid}`} state={data}>
      <Row style={{ width: '100%' }}>
        <Text> {data.title}</Text>
        <Col span={24}>
          <StatusHead status={data.status} />
          <Tag color={TICKETSPRIORITIESCOLORS[data.priority].color}>
            {t(data.priority)}
          </Tag>
        </Col>
      </Row>
    </Link>
  )
}
const AssignTicketsActions = ({ data }: { data: ITicket }) => {
  const openTicket = useTicketDrawer()
  const commonProps = { tkid: data.tkid, type: 'text' }
  return (
    <Row justify="space-around">
      {data ? (
        <>
          <ModalTicket {...commonProps} action="edit" icon={<EditOutlined />} />
          <ModalTicket
            {...commonProps}
            action="schedule"
            icon={<ClockCircleOutlined />}
          />
          <Button
            onClick={() => openTicket(data)}
            type="text"
            icon={<EllipsisOutlined key="ellipsis" />}
          />
        </>
      ) : null}
    </Row>
  )
}

const useAssignTicketsItem = ({ data }: TicketProps) => {
  const [ticket, setTicket] = useState<ITicket | undefined>(data)
  return { ticket, setTicket }
}

const AssignTicketsItem = ({ data }: { data: ITicket }) => {
  const { ticket, setTicket } = useAssignTicketsItem({ data })
  return (
    <TicketDrawer ticket={ticket}>
      {ticket ? (
        <TicketContext.Provider
          value={{ data: ticket, setData: setTicket, loading: false }}
        >
          <Card
            style={{ marginTop: 16 }}
            actions={[<AssignTicketsActions data={ticket} />]}
          >
            <Card.Meta
              title={<DrawerItemHead data={ticket} />}
              description={
                <Paragraph ellipsis={{ rows: 4 }}>
                  {ticket.last_followup_summary
                    ? ticket.last_followup_summary
                    : ticket.content}
                </Paragraph>
              }
            />
            <ProgressTicketsDrawer data={ticket} />
          </Card>
        </TicketContext.Provider>
      ) : null}
    </TicketDrawer>
  )
}
const AssignTicketsList = ({
  getTickets,
  page
}: {
  getTickets: (page: number) => void
  page: number
}) => {
  const { data, loading } = useGenericContext(AssignedContext)
  return (
    <>
      <List
        rowKey={item => item?.uuid}
        dataSource={data?.data}
        loading={loading}
        renderItem={item => <AssignTicketsItem data={item} />}
      />
      <Row justify="end">
        <Pagination
          defaultCurrent={1}
          current={page}
          onChange={getTickets}
          total={data?.meta.total}
          pageSize={5}
        />
      </Row>
    </>
  )
}

export default AssignTicketsList
