import { useDcContext } from '@pages/StatusProblem'
import { AlertOutlined, CheckOutlined } from '@ant-design/icons'
import { IGraph } from '@/types/IGraphStatisc'
import { IDc } from '@/types/IDc'
import { api } from '@helpers/api'
import useFetch from '@hooks/useFetch'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Card, Statistic, Flex, Typography } from 'antd'

const Summary = () => {
  const { data } = useDcContext()

  const getStatusDc = () => {
    const itemStatistic: IGraph[] = []
    data.map((item, index) => {
      const status = getStatusProblem(
        `/status/problem/hasProblems?dcs=${item.uuid}`
      )
      pushStatusData(itemStatistic, item, status.hasProblems, index)
    })

    return itemStatistic
  }
  const dcs = getStatusDc()
  return <GraphStatistc data={dcs} />
}

const GraphStatistc = ({ data }: { data: IGraph[] }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Flex data-cy="status-summary" wrap="wrap" justify="center" gap="large">
      {data &&
        data.map(item => (
          <Card
            bordered={false}
            style={{
              backgroundColor: theme[item.color],
              width: '200px',
              textAlign: 'center'
            }}
          >
            <Statistic
              title={
                <Typography.Text style={{ color: 'white' }}>
                  {t(`${item.label}`)}
                </Typography.Text>
              }
              value={' '}
              valueStyle={{ color: 'white' }}
              prefix={item.icon}
            />
          </Card>
        ))}
    </Flex>
  )
}

const getStatusProblem = (url: string) => {
  const { data, error } = useFetch<IDc, undefined>({
    func: () => api.get(url)
  })

  return !error ? data : false
}

const pushStatusData = (
  itemStatistic: IGraph[],
  item: IDc,
  state: boolean,
  index: number
) => {
  itemStatistic.push({
    key: index,
    label: item.shortname,
    color: state === true ? 'red' : 'green',
    icon: state === true ? <AlertOutlined /> : <CheckOutlined />
  })
}

export default Summary
