import { Form, Select } from 'antd'
import type { SelectProps } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { IOrder } from '@/types/IOrder'

const SelectDate = ({ data }: { data: IOrder }) => {
  const { t } = useTranslation()
  const compDay = data?.created_at ? data.created_at : dayjs()
  const monthsArray = [12, 36, 48, 60]

  const monthOptions: SelectProps['options'] = monthsArray.map(months => ({
    label: `${months} ${t('MONTHS')}`,
    value: dayjs(compDay).add(months, 'month').format('YYYY-MM-DD')
  }))
  return (
    <Form.Item
      name="expire_at"
      rules={[{ required: true, message: t('requiredItem') }]}
      data-cy="select-date"
      label={t('EXPIRE_AT')}
    >
      <Select options={monthOptions} style={{ width: '100%' }} />
    </Form.Item>
  )
}

export default SelectDate
