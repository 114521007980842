import { useState, useRef, useEffect } from 'react'
import { Button, Form, Row, Steps, Typography } from 'antd'
import Modal from '@common/Modal'
import IconButton from '@common/IconButton'
import { IconButtonProps } from '@/types/IconButtonProps'
import { ModalContext } from '@contexts/ModalContext'
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next'
import CreateService from '@components/Service/CreationForm'
import EditService from '@components/Service/EditionForm'
import { IService } from '@/types/IService'
import useModal from '@hooks/useModal'
import { ServiceContext } from '@contexts/ServiceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageInstance } from 'antd/es/message/interface'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { MessageContext } from '@contexts/MessageContext'
import useTableContext from '@hooks/useTableContext'
import { ITableContext } from '@/types/ITableCard'

const Text = Typography.Text

interface ModalServiceProps extends IconButtonProps {
  data?: IService
}

interface ServiceProviderProps
  extends Omit<ModalServiceProps, 'name'>,
    WithTranslation {}

type onRequestServiceParams = {
  service: Partial<IService> | undefined
  values: IService['steps']
  onCancel: () => void
  messageApi: MessageInstance
  table: undefined | ITableContext
}
const onRequestService = ({
  service,
  values,
  onCancel,
  messageApi,
  table
}: onRequestServiceParams) => {
  if (service?.uuid) {
    return api
      .put(`/ticket/service/${service.uuid}`, values)
      .then(e => {
        onCancel()
        table ? table.update(e.data.uuid, e.data) : null
        messageApi.success(e.data.name)
      })
      .catch(e => handleError(e, messageApi, 'Create Service Error'))
  }
  api
    .post('ticket/service', { ...service, ...values })
    .then(e => {
      onCancel()
      messageApi.success(e.data.name)
    })
    .catch(e => handleError(e, messageApi, 'Edit Service Error'))
}

const initialValues = (service: Partial<IService> | undefined) => {
  if (service) {
    return {
      ...service,
      sector: service.sector?.slug,
      category: service.category?.slug,
      steps: service?.steps
        ? service.steps.map(step => ({
            sector: step.sector.slug,
            tasks: step.tasks.map(task => ({ task: task.task }))
          }))
        : []
    }
  }
}
const ModalServiceActionButtons = () => {
  const { onCancel } = useGenericContext(ModalContext)
  const { t } = useTranslation()
  return (
    <Row justify="space-between" style={{ marginTop: '8px' }}>
      <Button onClick={onCancel}>{t('CANCEL')}</Button>
      <Button htmlType="submit" data-cy={'finishStep'} type="primary">
        {t('CONFIRM')}
      </Button>
    </Row>
  )
}

const useModalContentService = () => {
  const { service } = useGenericContext(ServiceContext)
  const messageApi = useGenericContext(MessageContext)
  const { onCancel } = useGenericContext(ModalContext)
  const table = service ? useTableContext() : undefined
  const onFinish = (values: any) =>
    onRequestService({ service, values, onCancel, messageApi, table })
  return onFinish
}

const ModalContentService = withTranslation()(({ t }: ServiceProviderProps) => {
  const { service } = useGenericContext(ServiceContext)
  const onFinish = useModalContentService()
  return (
    <Form initialValues={initialValues(service)} onFinish={onFinish}>
      <div style={{ marginBottom: '8px' }}>
        {t('CREATEEDITSERVICE')}
        {service?.name ? ' - ' + service.name : ''}
      </div>
      <CreateService />
      <Text>{t('TASKS')}</Text>
      <EditService />
      <ModalServiceActionButtons />
    </Form>
  )
})

const ModalService = ({ data, ...props }: ModalServiceProps) => {
  const { open, onCancel, onOpen } = useModal()
  const [service, setService] = useState<Partial<IService> | undefined>(data)
  useEffect(() => {
    setService(data)
  }, [data])
  return (
    <ModalContext.Provider value={{ open, onCancel, onOpen }}>
      <ServiceContext.Provider value={{ service, setService }}>
        <Modal
          {...{
            open,
            onCancel,
            width: '55%',
            footer: null,
            destroyOnClose: true
          }}
        >
          <ModalContentService />
        </Modal>
        <IconButton {...props} onClick={onOpen} />
      </ServiceContext.Provider>
    </ModalContext.Provider>
  )
}

export default ModalService
