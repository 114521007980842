import { useState } from 'react'
import { Button, ButtonProps } from 'antd'
import Modal from '@common/Modal'
import useModal from '@hooks/useModal'
import FormCompany from '../Form'
import { ICompany } from '@/types/ICompany'
import ModalFooter from '@components/common/ModalFooter'
import { api } from '@helpers/api'
import type { IRequest } from '@/types/ICollection'

interface ModalCompanyProps extends ButtonProps {
  action: 'create' | 'edit'
  data?: ICompany | undefined
}

const ModalCompany = ({ action, data, ...props }: ModalCompanyProps) => {
  const numForm = Math.floor(Math.random() * 10000).toString()
  const { open, onOpen, onCancel } = useModal()
  const [company, setCompany] = useState<ICompany | undefined>()
  const handleClick = async () => {
    if (action === 'edit') {
      await api
        .get(`/company/${data?.uuid}`)
        .then((response: IRequest<ICompany>) => setCompany(response.data))
    }
    onOpen()
  }

  return (
    <>
      <Modal
        footer={
          <ModalFooter onClose={onCancel} action={action} form={numForm} />
        }
        width="60%"
        centered
        open={open}
        onCancel={onCancel}
      >
        <FormCompany
          id={numForm}
          data={company}
          action={action}
          onClose={onCancel}
        />
      </Modal>
      <Button {...props} onClick={handleClick} />
    </>
  )
}

export default ModalCompany
