import { IDevice } from '@/types/IDevice'
import { Space, Tag } from 'antd'

type DeviceInterfacesProps = {
  interfaces: IDevice['interfaces']
}
const DeviceInterfaces = ({ interfaces }: DeviceInterfacesProps) => {
  return (
    <>
      {Array.isArray(interfaces)
        ? interfaces?.map((elem, index) => (
            <Space key={index} wrap>
              {elem?.ips?.map((ip, index) => (
                <Tag key={index} color="blue-inverse">
                  {ip}
                </Tag>
              ))}
              {elem?.mac ? <Tag color="purple-inverse">{elem?.mac}</Tag> : null}
            </Space>
          ))
        : null}
    </>
  )
}
export default DeviceInterfaces
