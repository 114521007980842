import { Typography, Space, Tag, Row, TagProps, Tooltip, Button } from 'antd'
import { generateTabItem } from '@common/Tab'
import { useTranslation } from 'react-i18next'
import { IDevice } from '@/types/IDevice'
import { ColumnsType } from 'antd/es/table'
import { IBackupReport } from '@/types/IBackupReport'
import humanizeSizes from '@helpers/humanizeSizes'
import Icon from '@common/Icon'
import { Link } from 'react-router-dom'
import DeviceIcons from '@components/Device/Icons'
import { DATEFORMAT } from '@constants/dateformat'
import dayjs from 'dayjs'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { IBackupRespoint } from '@/types/IBackupRespoint'
import useSearchParams from '@hooks/useSearchParams'
import DeviceDrawer from '@components/Device/DeviceDrawer'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { useDrawerContext } from '@contexts/DrawerContext'

const Text = Typography.Text

const deviceColProps = {
  title: 'DEVICE',
  dataIndex: 'device',
  key: 'device'
}

const results = {
  Success: { name: '#A0D911', icon: <CheckCircleOutlined /> },
  Failed: { name: '#FF4D4F', icon: <CloseCircleOutlined /> },
  Warning: { name: '#EDB007', icon: <ExclamationCircleOutlined /> }
}

const states = {
  InProgress: { icon: <SyncOutlined spin />, color: 'processing' },
  Completed: { icon: <CheckCircleOutlined />, color: 'lime-inverse' },
  Pending: { icon: <ClockCircleOutlined />, color: '#edb007' }
}

interface CustomTagProps extends Omit<TagProps, 'children'> {
  children: string
}

interface ResultButtonProps {
  result: IBackupReport['result']
  reason: string | null
}

const ResultButton = ({ result, reason }: ResultButtonProps) => {
  const params = useSearchParams()
  return (
    <>
      {result ? (
        <Tooltip title={result !== 'Success' ? reason : null}>
          <button onClick={() => params.toogleItem('result', result)}>
            <Tag
              style={{
                backgroundColor: results[result]?.name || 'gray',
                color: 'white'
              }}
              icon={results[result].icon}
            >
              {result}
            </Tag>
          </button>
        </Tooltip>
      ) : null}
    </>
  )
}

const CustomTag = ({ children, ...props }: CustomTagProps) => {
  const { t } = useTranslation()
  return <Tag {...props}>{children ? t(children) : ''}</Tag>
}

const DeviceCol = ({ device }: { device: IDevice }) => (
  <DeviceDrawer device={device}>
    <InternalDeviceCol />
  </DeviceDrawer>
)

const InternalDeviceCol = () => {
  const { data } = useGenericContext(DeviceContext)
  const { open } = useDrawerContext(`/device/${data.uuid}`, data)
  return (
    <button onClick={open}>
      <Space>
        {data.os ? <DeviceIcons kind={data.os.kind} /> : null}
        {data?.name}
        <Icon name="fa-light fa-external-link" />
      </Space>
    </button>
  )
}

export const reportColumns: ColumnsType<IBackupReport> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'STATE',
    dataIndex: 'state',
    key: 'state',
    render: (_, { state }) => (
      <Row>
        <CustomTag
          color={states[state].color}
          icon={states[state].icon}
          children={state}
        />
      </Row>
    )
  },
  {
    title: 'RESULT',
    dataIndex: 'result',
    key: 'result',
    render: (_, { result, reason }) => {
      // vem null
      return <ResultButton {...{ ...{ reason, result } }} />
    }
  },
  {
    title: 'TOTALSIZE',
    dataIndex: 'totalsize',
    key: 'totalsize',
    render: (_, { totalsize }) => (
      <Text>{totalsize != 0 ? humanizeSizes(totalsize) : ''}</Text>
    )
  },
  {
    ...deviceColProps,
    render: (_, { device }) => <DeviceCol device={device} />
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'creation_time',
    key: 'creation_time',
    render: (text: string) => (text ? dayjs(text).format(DATEFORMAT) : null)
  },
  {
    title: 'ENDAT',
    dataIndex: 'end_time',
    key: 'end_time',
    render: end_time => {
      return end_time !== null ? dayjs(end_time).format(DATEFORMAT) : null
    }
  }
]

export const respointColumns: ColumnsType<IBackupRespoint> = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
    render: (_, { type }) => {
      const colors = {
        Increment: 'cyan-inverse',
        Full: 'geekblue-inverse'
      }
      return <CustomTag color={colors[type] || 'gray'}>{type}</CustomTag>
    }
  },
  {
    ...deviceColProps,
    render: (_, { device }) => <DeviceCol device={device} />
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'creation_time',
    key: 'creation_time',
    render: (text: string) => (text ? dayjs(text).format(DATEFORMAT) : '')
  }
]
export const renderingTabFilter = (path = '') => {
  const { t } = useTranslation()
  const renderingWithTab = [
    generateTabItem(
      { key: '1', label: t('REPORT'), url: `/backup/report${path}` },
      reportColumns
    ),
    generateTabItem(
      { key: '2', label: t('RESPOINT'), url: `/backup/respoint${path}` },
      respointColumns
    )
  ]
  return renderingWithTab
}
