import Device from '@pages/Device'
import { IDevice } from '@/types/IDevice'
import DeviceHead from '../DeviceHead'
import { DeviceContext } from '@contexts/DeviceContext'
import DrawerProvider from '@contexts/DrawerContext'
import { PropsWithChildren, useState } from 'react'

interface DeviceDrawerProps extends PropsWithChildren {
  device: IDevice | string
}
const DeviceDrawer = ({ device, children }: DeviceDrawerProps) => {
  const [data, setData] = useState(
    typeof device !== 'string' ? device : undefined
  )

  return (
    <DeviceContext.Provider value={{ data, setData }}>
      <DrawerProvider component={Device} head={DeviceHead}>
        {children}
      </DrawerProvider>
    </DeviceContext.Provider>
  )
}

export default DeviceDrawer
