import styled from 'styled-components';

export const Navbar = styled.div`
  background-color: ${props => props.theme.blue};
  width: 100%;
  height: auto; 
  display: flex; 
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  max-width: 200px; 
  height: auto; 
`;

export const OnboardText = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 2em;
  width: 100%;
  height: auto;
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`
