import styled from 'styled-components'

export const Welcome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #2077ae;
  transition: all 1s;
`

export const Image = styled.img`
  width: 200px;
`
