import { ReactNode, useEffect } from 'react'
import { Row, Col, Avatar, Alert, AlertProps, Timeline, Tooltip } from 'antd'
import { IFollowup } from '@/types/IFollowup'
import SubTitle from '@common/SubTitle'
import { useTranslation, withTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { useTheme } from 'styled-components'
import { useUser } from '@contexts/UserContext'
import FollowupImage from '../FollowupImage'
import FollowupDocument from '../FollowupDocument'
import Icon from '@components/common/Icon'
import { IUser } from '@/types/IUser'
import { FollowupContext } from '@contexts/FollowupContext'
import FollowupAudio from '../FollowupAudio'
import FollowupVideo from '../FollowupVideo'
import { IAttachment } from '@/types/IFollowup'
import { TFunction } from 'i18next'
import { FollowupProps } from '@/types/IFollowup'
import { generateHistory } from '../FollowupTicketHistory'
import FollowupList from '../FollowupList'
import SupportAvatar from '@components/common/SupportAvatar'

interface FollowupAlertProps extends AlertProps {
  message: string
}

interface DotAttachment {
  isPrivate: boolean
  user: IUser
  icon: string
}

const useFollowup = () => {
  const { tkid, refresh } = useGenericContext(FollowupContext)
  useEffect(() => {
    refresh()
  }, [tkid])
}

const Followup = () => {
  useFollowup()
  const { followup, loading, isError } = useGenericContext(FollowupContext)
  if (loading) return <FollowupAlert type="info" message="SEARCHINGFOLLOWUPS" />

  if (!loading && isError)
    return <FollowupAlert type="error" message="FOLLOWUPREQUESTERROR" />
  return (
    <Row gutter={[12, 12]}>
      <FollowupTitle />
      {!followup?.data?.length && (
        <FollowupAlert type="warning" message="NOFOLLOWUPS" closable />
      )}
      {followup ? <FollowupTimeline data={followup.data} /> : null}
    </Row>
  )
}

const FollowupTitle = withTranslation()(({ t }: { t: TFunction }) => (
  <SubTitle level={3} data-cy="followup-title">
    {t('HISTORY')}
  </SubTitle>
))

const FollowupTimeline = ({ data }: FollowupProps) => {
  const items: any = []
  data.map(e => {
    if (e.action) {
      generateHistory(e, items)
    } else {
      e.attachments
        ? generateTimelineAttachment(e, items)
        : generateTimeline(e, items)
    }
  })

  return (
    <Col lg={{ span: 18, offset: 3 }} xs={{ span: 22, offset: 1 }}>
      <Timeline items={items} />
    </Col>
  )
}

const FollowupAlert = ({ message, ...props }: FollowupAlertProps) => {
  const { t } = useTranslation()
  return (
    <Alert
      {...props}
      message={t(message)}
      style={{ width: '100%' }}
      role=""
      showIcon
    ></Alert>
  )
}

const generateTimeline = (followup: IFollowup, items: any) => {
  items.push({
    children: <FollowupList followup={followup} />,
    dot:
      followup?.user?.level !== 1 ? (
        <FollowupSupportAvatar followup={followup} />
      ) : (
        <FollowupClientAvatar followup={followup} />
      )
  })
}

const generateTimelineAttachment = (followup: IFollowup, items: any) => {
  const { images, documents, audios, videos } = filterAttachments(followup)
  followup.followup !== 'ANEXO' && generateTimeline(followup, items)
  const setItem = (icon: string, children: ReactNode) => {
    items.push({
      children: <Col span={18}>{children}</Col>,
      dot: (
        <DotAttachment
          user={followup?.user}
          isPrivate={followup.is_private}
          icon={icon}
        />
      )
    })
  }

  images.length > 0 &&
    setItem('fa-solid fa-image', <FollowupImage attachments={images} />)
  documents.length > 0 &&
    setItem('fa-solid fa-file', <FollowupDocument attachments={documents} />)
  processAttachments(
    audios,
    'fa-light fa-headphones-simple',
    FollowupAudio,
    setItem
  )
  processAttachments(videos, 'fa-light fa-play', FollowupVideo, setItem)
}

const filterAttachments = (followup: IFollowup) => {
  const filterType = (types: string[]) => {
    return followup.attachments.filter(attachment =>
      types.includes(attachment.type)
    )
  }

  let images: IAttachment[] = filterType(['image'])
  let documents: IAttachment[] = filterType(['application', 'text'])
  let audios: IAttachment[] = filterType(['audio'])
  let videos: IAttachment[] = filterType(['video'])

  return { images, documents, audios, videos }
}

const processAttachments = (
  attachments: IAttachment[],
  icon: string,
  Component: React.FC<any>,
  setItem: any
) => {
  attachments.length > 0 &&
    attachments.map((attachment: IAttachment) => {
      setItem(icon, <Component key={attachment.uuid} attachment={attachment} />)
    })
}

const DotAttachment = ({ user, isPrivate, icon }: DotAttachment) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const title = isPrivate ? t('PRIVATE') : t('PUBLIC')
  return (
    <Tooltip title={title}>
      <Avatar
        data-cy="attachment-avatar"
        style={{
          backgroundColor:
            (isPrivate && theme.yellowDark) ||
            (user?.level !== 1 ? theme.blueDark : theme.blue)
        }}
        icon={<Icon name={icon} color="white" />}
      />
    </Tooltip>
  )
}

const FollowupSupportAvatar = ({ followup }: { followup: IFollowup }) => {
  return (
    <Tooltip title={followup?.user?.name}>
      <SupportAvatar
        is_private={followup.is_private}
        level={followup.user.level}
        data-cy="support-avatar"
        sprite={followup?.user?.sprite}
      >
        {followup?.user?.name?.charAt(0)}
      </SupportAvatar>
    </Tooltip>
  )
}

const FollowupClientAvatar = ({ followup }: { followup: IFollowup }) => {
  const { user } = useUser()
  const initial = followup?.user?.name?.charAt(0)
  const theme = useTheme()
  const img =
    user.uuid === followup?.user?.uuid && user.avatar ? (
      <img src={`data:image/png;base64,${user.avatar}`} />
    ) : undefined

  return (
    <Tooltip title={followup?.user?.name}>
      <Avatar
        data-cy="client-avatar"
        style={{ backgroundColor: theme.blue }}
        src={img}
      >
        {!user?.avatar || img === undefined ? initial : null}
      </Avatar>
    </Tooltip>
  )
}

export default Followup
