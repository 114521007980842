import { useState, useEffect, useContext } from 'react'
import { Switch, Tag, Row, Select, Form, Space, Col, Typography } from 'antd'
import { api } from '@helpers/api'
import {
  InitialNamesType,
  DefaultSearchSelectProps,
  SupportComponentsProps,
  TicketSelectProps
} from '@/types/ITicketModalItems'
import { mapItemswithNameandUUIDFilter } from '@helpers/mapItemswithNameandUUIDFilter'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import Icon from '@common/Icon'
import { SearchSelectWithForm } from '@common/SearchSelectWithForm'
import SelectForAll from '../SelectForAll'
import RadioForAll from '../RadioForAll'
import { ModalContext } from '@contexts/ModalContext'
import SUsersSelect from '@components/Ticket/SUserSelect'
import { convertKeysToTranslatedOptions } from '@helpers/convertKeysToOptions'
import { useTranslation } from 'react-i18next'
import { humanizeDurationsCompact } from '@helpers/humanizeDurations'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import useSearchSelect from '@hooks/useSearchSelect'
import usePaginationSelect from '@hooks/usePaginationSelect'
import { IService } from '@/types/IService'
import { ITicket } from '@/types/ITicket'

const { Option } = Select
const { Text } = Typography

type IsStepsFinishedType = {
  isStepsFinished: boolean
}

const StatusTicketSelect = ({ tkid }: { tkid?: string | number }) => {
  const context = useContext(ModalContext)
  const { t } = useTranslation()
  const [options, setOptions] = useState<{ label: string; value: string }[]>()

  const convertedObj = convertKeysToTranslatedOptions(
    TICKETSTATUSESCOLORS,
    t
  ).slice(0, -3)

  useEffect(() => {
    if (tkid && context?.open) {
      api
        .get<IsStepsFinishedType>(`/ticket/${tkid}/isStepsFinished`)
        .then(e => {
          e.data.isStepsFinished
            ? setOptions(convertedObj)
            : setOptions(convertedObj.slice(0, -2))
        })
      return
    }
    setOptions(convertedObj)
  }, [context?.open, tkid])
  return <SelectForAll span={6} name="status" options={options} />
}

const PriorityTicketSelect = () => {
  const { t } = useTranslation()
  const options = convertKeysToTranslatedOptions(
    TICKETSPRIORITIESCOLORS,
    t
  ).slice(0, -2)
  return (
    <SelectForAll span={6} id="priority" name="priority" options={options} />
  )
}

export const SupportComponents = ({
  data,
  type,
  form
}: SupportComponentsProps) => {
  return (
    <>
      {type !== 'create' ? (
        <>
          {data?.status !== 'CLOSED' ? (
            <StatusTicketSelect {...{ tkid: data?.tkid }} />
          ) : null}
          {/*<PriorityTicketSelect />*/}
          <SUsersSelect span={5} data={data} />
        </>
      ) : null}
      {<PriorityTicketSelect />}
      <ServicesSelect data={data} form={form} />
      <DefaultSearchSelect name="sector" span={5} data={data} />
      <DefaultSearchSelect name="company" data={data} span={6} />
      <RadioForAll name="category" required />
    </>
  )
}

const ticketSearchSelectFuncs = () => ({
  sector: async (e?: string) =>
    await getGenericListsandFilter(`sector?search=${e}`),
  company: async (e?: string) =>
    await mapItemswithNameandUUIDFilter(`company?search=${e}`)
})

const initialValueSearchSelect = ({
  name = 'service',
  data
}: {
  name: InitialNamesType
  data?: ITicket
}) => {
  try {
    const initialValue = [
      {
        label: data?.[name].name ? data[name].name : null,
        value: data?.[name].uuid ? data[name].uuid : ''
      }
    ]
    return initialValue
  } catch {
    return undefined
  }
}

export const DefaultSearchSelect = ({
  name,
  data,
  span = 6,
  ...props
}: DefaultSearchSelectProps) => {
  const { t } = useTranslation()
  const func = ticketSearchSelectFuncs()[name]
  const { loading, options, onSearch } = useSearchSelect({
    initialValue: initialValueSearchSelect({ name, data }),
    func
  })

  return (
    <SearchSelectWithForm
      {...props}
      span={span}
      name={name}
      allowClear
      optionLabelProp="label"
      placeholder={t('CHOOSEANITEM')}
      loading={loading}
      onFocus={() => onSearch('')}
      onSearch={onSearch}
      filterOption={false}
      options={options}
      showSearch
    />
  )
}

export const ServicesSelect = ({
  data,
  form,
  span = 12,
  ...props
}: TicketSelectProps) => {
  const getServices = async (e?: string, page = 1) => {
    return await api
      .get(`ticket/service?search=${e}&page=${page}&perPage=15`)
      .then(e => e.data)
  }
  const { loading, options, onSearch, onPopupScroll } = usePaginationSelect({
    initialValue: data && data?.service !== null ? [data?.service] : undefined,
    func: getServices
  })

  const handleServiceSelect = (value: string) => {
    const selectedService = options?.find(
      (service: IService) => service.uuid === value
    )
    const fieldsToUpdate = {
      category: selectedService?.category?.slug,
      sector: selectedService?.sector?.slug,
      priority: selectedService?.priority
    }

    form?.setFieldsValue(fieldsToUpdate)
  }

  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Form.Item name="service">
        <SearchSelectWithForm
          {...props}
          name="service"
          loading={loading}
          onSearch={onSearch}
          onFocus={async () => await onSearch('')}
          onChange={handleServiceSelect}
          onPopupScroll={onPopupScroll}
        >
          {renderOptions(options)}
        </SearchSelectWithForm>
      </Form.Item>
    </Col>
  )
}

const renderOptions = (options: any) => {
  return (
    <>
      {!!options
        ? options.map((e: IService) => (
            <Option value={e.uuid} key={e.uuid} label={e.name}>
              <Row style={{ width: '100%' }} justify="space-between">
                <Text>{e.name}</Text>
                {e.expected_time ? (
                  <Tag color="blue-inverse">
                    {humanizeDurationsCompact(e.expected_time)}
                  </Tag>
                ) : null}
              </Row>
            </Option>
          ))
        : null}
    </>
  )
}

export const IsPrivateConfirm = ({
  defaultValue = false,
  isFollowup = false
}) => {
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Space>
        <Form.Item
          name={isFollowup ? 'f_is_private' : 'is_private'}
          valuePropName="checked"
          noStyle
        >
          <Switch
            defaultChecked={defaultValue}
            checkedChildren={<Icon name="fal fa-lock" color="white" />}
            unCheckedChildren={<Icon name="fal fa-users" color="white" />}
          />
        </Form.Item>
        <Typography.Text>
          {isFollowup ? t('PRIVATEFOLLOWUP') : t('PRIVATETICKET')}
        </Typography.Text>
      </Space>
    </Col>
  )
}
