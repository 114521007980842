import FilterSelect from "@components/common/FilterSelect";
import useTicketSelect from "@hooks/useTicketSelect";
import useQueryItems from "@hooks/useQueryItems";
import { IService } from "@/types/IService";
import { Select } from "antd";

const searchServices = async (search = '') =>
  useQueryItems(search, 'ticket/service');

const ServiceSelect = () => {
  const { options, ...serviceProps } = useTicketSelect({
    name: 'services',
    func: searchServices,
  });

  return (
    <FilterSelect
      {...serviceProps}
      options={options || []}
      placeholderKey="SERVICE"
      data-cy="filterService"
      optionRenderer={(e: IService) => (
        <Select.Option key={e.uuid} value={e.uuid}>
          {e.name}
        </Select.Option>
      )}
    />
  );
};

export default ServiceSelect;
