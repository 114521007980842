import Title from '@common/Title'
import { Tabs, Col, Row } from 'antd'
import { renderingWithTab } from '@components/Monitoring/Table'
import { useTranslation } from 'react-i18next'

import DeviceFilterSelect from '@components/common/DeviceFilterSelect'

const translateColumns = (tabs, t) => {
  const newTabs = tabs.map(tab => {
    return { ...tab, label: t(tab.label) }
  })
  return newTabs
}

const Monitoring = () => {
  const { t } = useTranslation()
  const renderWithTab = translateColumns(renderingWithTab, t)
  return (
    <Row>
      <Col span={24} md={{ span: 8 }}>
        <Title name={t('ALERTS')} />
      </Col>
      <Col span={24} md={{ offset: 8, span: 8 }}>
        <DeviceFilterSelect />
      </Col>
      <Tabs
        style={{ width: '100%' }}
        destroyInactiveTabPane={true}
        items={renderWithTab}
        type="card"
        size="large"
      />
    </Row>
  )
}
export default Monitoring
