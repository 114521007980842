import { Space } from 'antd'
import Icon from '@components/common/Icon'
import { ZoneRecord } from '@/types/IDns'
import ModalDns from '../Modal'
import DeleteButton from '@components/common/DeleteButton'

const DeleteZoneRecord = ({ record }: { record: ZoneRecord }) => {
  return (
    <DeleteButton
      url={`/dns/zone/${record.zone.zone_name}/record/${record.uuid}`}
      uuid={record.uuid}
      name={record.hostname}
    />
  )
}

const EditZoneRecord = ({ record }: { record: ZoneRecord }) => {
  return (
    <ModalDns
      zone_record={record}
      action="edit"
      icon={<Icon name="fa-light fa-pen" color="icon" />}
      type="primary"
    />
  )
}

const ShowDnsActions = ({ record }: { record: ZoneRecord }) => {
  return (
    <Space>
      <EditZoneRecord record={record} />
      <DeleteZoneRecord record={record} />
    </Space>
  )
}

export default ShowDnsActions
