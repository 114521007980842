import { Select, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { IRequest } from '@/types/ICollection'
import { ICompany } from '@/types/ICompany'
import { mapCleanFormatFilter } from '@helpers/MapCleanFormatFilter'
import useTicketSelect from '@hooks/useTicketSelect'

const getCompanies = async (search = '') => {
  return await api
    .get(`company?search=${search}&perPage=99`)
    .then((e: IRequest<ICompany>) =>
      mapCleanFormatFilter(e.data, 'uuid', 'name')
    )
}

const CompaniesSelect = ({ span = 24 }: { span: number }) => {
  const { t } = useTranslation()
  const selectProps = useTicketSelect({
    name: 'company',
    func: getCompanies
  })

  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Select
        {...selectProps}
        mode="multiple"
        showSearch
        style={{ width: '100%', marginBottom: '5px' }}
        allowClear
        filterOption={false}
        placeholder={t('FILTERBYCOMPANY')}
        data-cy="filtercompany"
      />
    </Col>
  )
}

export default CompaniesSelect
