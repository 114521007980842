import type { ColumnsType } from 'antd/es/table'
import { TabsProps } from 'antd'
import { IStatusProblem } from '@/types/IStatusProblem'
import { Typography, Tag, Space } from 'antd'
import { useDcContext } from '@pages/StatusProblem'
import { useTranslation } from 'react-i18next'
import { generateTabItem } from '@components/common/Tab'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import Actions from '../Actions'
import { useUser } from '@contexts/UserContext'

const { Text } = Typography

const columnsStatusProblems: ColumnsType<IStatusProblem> = [
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, data) => <Actions data={data} />
  },
  {
    title: 'DCS',
    dataIndex: 'dcs',
    key: 'dcs',
    render: dcs => <Dcs dcs={dcs} />
  },
  {
    title: 'TITLE',
    dataIndex: 'title',
    key: 'title',
    render: title => <Text>{title}</Text>
  },
  {
    title: 'PROBLEM',
    dataIndex: 'problem',
    key: 'problem',
    render: problem => <Text>{problem}</Text>
  },
  {
    title: 'RESOLVE_PREDICTION_AT',
    dataIndex: 'resolve_prediction_at',
    key: 'resolve_prediction_at',
    render: resolve_prediction_at =>
      dayjs(resolve_prediction_at).format(DATEFORMAT)
  },
  {
    title: 'RESOLVED_AT',
    dataIndex: 'resolved_at',
    key: 'resolved_at',
    render: resolved_at => <ResolvedAt data={resolved_at} />
  }
]

const ResolvedAt = ({ data }: { data: string }) => {
  const { t } = useTranslation()
  return (
    <>
      {data ? (
        <Text>{dayjs(data).format(DATEFORMAT)}</Text>
      ) : (
        <Text>{t('NOT_RESOLVED')}</Text>
      )}
    </>
  )
}

const Dcs = ({ dcs }: { dcs: string[] }) => {
  const { data } = useDcContext()
  function filterUuid() {
    return data.filter(item => dcs.includes(item.uuid))
  }
  const dc = filterUuid()
  return (
    <>
      {dcs
        ? dc.map(item => (
            <Space direction="vertical">
              <Tag color="blue-inverse">{item.shortname}</Tag>
            </Space>
          ))
        : null}
    </>
  )
}

export const renderingWithTab = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const columns =
    user.level > 950
      ? columnsStatusProblems
      : columnsStatusProblems.filter(column => column.key !== 'actions')
  const Tabs: TabsProps['items'] = [
    generateTabItem(
      {
        key: '1',
        url: '/status/problem?status=notResolved',
        label: t('NOT_RESOLVED')
      },
      columns
    ),
    generateTabItem(
      {
        key: '2',
        url: '/status/problem?status=resolved',
        label: t('SOLVED')
      },
      columns
    )
  ]
  return Tabs
}
