import FollowupProvider from '@contexts/FollowupContext'
import { TicketContext } from '@contexts/TicketContext'
import { ITicket } from '@/types/ITicket'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import DrawerProvider, { DrawerContext } from '@contexts/DrawerContext'
import Ticket from '@pages/Ticket'
import TicketHead from '../Head'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowResize from '@hooks/useWindowResize'
import { useGenericContext } from '@hooks/useGenericContext'

interface TicketDrawerProps extends PropsWithChildren {
  ticket?: ITicket | undefined | string
}

export const useTicketDrawer = () => {
  const { openDrawer } = useGenericContext(DrawerContext)
  const { setData } = useGenericContext(TicketContext)
  const { width } = useWindowResize()
  const navigate = useNavigate()
  const openTicket = (data: ITicket) => {
    setData(data)
    width > 720
      ? openDrawer(data)
      : navigate(`/ticket/${data.tkid}`, { state: data })
  }
  return openTicket
}

const InternalTicketDrawer = ({ children }: PropsWithChildren) => {
  const { open } = useGenericContext(DrawerContext)
  const { data } = useGenericContext(TicketContext)
  const initRef = useRef(true)
  const [location, _] = useState(
    window.location.pathname.concat(window.location.search)
  )
  useEffect(() => {
    if (!initRef.current) {
      window.history.pushState(
        { page: 2 },
        '',
        open ? `/ticket/${data.tkid}` : location
      )
    }
    initRef.current = false
  }, [open])
  return children
}

const TicketDrawer = ({ ticket, children }: TicketDrawerProps) => {
  const [data, setData] = useState(
    typeof ticket !== 'string' ? ticket : undefined
  )
  return (
    <FollowupProvider tkid={typeof ticket === 'string' ? ticket : data?.tkid}>
      <TicketContext.Provider
        value={{
          data,
          setData,
          byDrawer: true,
          tkid: typeof ticket === 'string' ? ticket : data?.tkid
        }}
      >
        <DrawerProvider component={Ticket} head={TicketHead}>
          <InternalTicketDrawer>{children}</InternalTicketDrawer>
        </DrawerProvider>
      </TicketContext.Provider>
    </FollowupProvider>
  )
}

export default TicketDrawer
