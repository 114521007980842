import { useTranslation } from 'react-i18next'
import { IDevice } from '@/types/IDevice'
import powered from '@constants/powered'
import { Tag } from 'antd'

const DeviceStateTag = ({ state }: { state: IDevice['state'] }) => {
  const { t } = useTranslation()
  return <>{state ? <Tag color={powered[state]}>{t(state)}</Tag> : null}</>
}

export default DeviceStateTag
