import { Row } from 'antd'
import useFetch from '@hooks/useFetch'
import { useParams } from 'react-router-dom'
import { DeviceContextType, IDevice } from '@/types/IDevice'
import { api } from '@helpers/api'
import RenderPage from '@common/RenderPage'
import { DeviceContext } from '@contexts/DeviceContext'
import DeviceHead from '@components/Device/DeviceHead'
import DeviceTabs from '@components/Device/DeviceTabs'

const DeviceContent = ({ byData }: { byData?: boolean }) => {
  return (
    <Row style={{ width: '100%' }}>
      {!byData ? <DeviceHead /> : null}
      <DeviceTabs />
    </Row>
  )
}

const DeviceByState = () => {
  return <DeviceContent byData={true} />
}

const DeviceProvider = (props: DeviceContextType) => (
  <DeviceContext.Provider value={{ ...props }}>
    <DeviceContent byData={props.byData} />
  </DeviceContext.Provider>
)
const DeviceByFetch = ({ uuid }: { uuid?: string }) => {
  const { data, loading, error, setData } = useFetch({
    func: () => api.get(`/device/${uuid}`)
  })

  return (
    <RenderPage {...{ data, error }} loading={loading}>
      <DeviceProvider data={data} setData={setData} />
    </RenderPage>
  )
}

const Device = ({ data }: { data?: IDevice | undefined }) => {
  const { uuid } = useParams()
  if (typeof data === 'object') {
    return <DeviceByState />
  } else {
    return (
      <DeviceByFetch {...{ uuid: typeof data === 'string' ? data : uuid }} />
    )
  }
}

export default Device
