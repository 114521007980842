import TiersModal from '@components/Tiers/Modal'
import { useTiersTab } from '@components/Tiers/Table'
import Title from '@components/common/Title'
import { Col, Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

const Tiers = () => {
  const { t } = useTranslation()
  const items = useTiersTab()
  const theme = useTheme()
  return (
    <Row style={{ width: '100%' }} justify="space-between">
      <Col>
        <Title name={t('TIERS')} />
      </Col>
      <Col>
        <TiersModal
          data-cy="create-tier-open-modal"
          block
          style={{ backgroundColor: theme.green, color: 'white' }}
        >
          {t('CREATETIER')}
        </TiersModal>
      </Col>
      <Tabs items={items} style={{ width: '100%' }} destroyInactiveTabPane />
    </Row>
  )
}

export default Tiers
