import useWindowResize from '@hooks/useWindowResize'
import { TableWithQuery } from '../Table'
import { CardWithQuery } from '@common/Card'
import { TableCardProps } from '../../types/ITableCard'

const ResposiveTableCard = <T,>({
  url,
  columns,
  children,
  ...props
}: TableCardProps<T>) => {
  const { width } = useWindowResize()
  return (
    <>
      {width > 768 ? (
        <TableWithQuery {...props} url={url} columns={columns}>
          {children}
        </TableWithQuery>
      ) : (
        <CardWithQuery url={url} columns={columns} />
      )}
    </>
  )
}

export default ResposiveTableCard
