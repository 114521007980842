import Title from '@common/Title'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { columns } from '@components/Autofill/Table'
import ModalAutofill from '@components/Autofill/Modal'
import { Row, Col } from 'antd'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import SearchByQueryInput from '@components/common/SearchByQueryInput'

const AutoFillHead = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <>
      <Col span={24}>
        <Title name={t('AUTOFILL')} xs={24} md={8} />
      </Col>
      <SearchByQueryInput />
      <Col xl={{ span: 3, offset: 13 }} xs={{ span: 24 }}>
        <ModalAutofill
          block
          name="fa-light fa-plus"
          style={{ backgroundColor: theme.green }}
          mode="create"
          data-cy="open-create-autofill-button"
        >
          {t('CREATEAUTOFILL')}
        </ModalAutofill>
      </Col>
    </>
  )
}
const Autofill = () => {
  return (
    <Row style={{ width: '100%' }} gutter={[8, 8]}>
      <AutoFillHead />
      <ResponsiveTableCard
        style={{ width: '100%' }}
        {...{ columns, url: '/ticket/autofill?' }}
      />
    </Row>
  )
}

export default Autofill
