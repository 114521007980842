import { useEffect, useState } from 'react'
import { api, getSprite } from '@helpers/api'
import { RouterProvider } from 'react-router-dom'
import { clearCookies } from '@helpers/clearCookies'
import { useUser } from '@contexts/UserContext'
import Welcome from '@components/Welcome'
import PublicRoute from '@routes/PublicRoute'
import PrivateRoute from '@routes/PrivateRoute'

const useRouterContext = () => {
  const { user, setUser, setIsLogged, islogged } = useUser()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!document.cookie.includes('---OPEN')) {
      setUser(undefined)
      setIsLogged(false)
    } else {
      api
        .get('/session/user')
        .then(e => {
          setUser(e.data)
          getSprite()
          setIsLogged(true)
        })
        .catch(() => {
          clearCookies()
          setUser(undefined)
          setIsLogged(false)
        })
    }
    setLoading(false)
  }, [document.cookie, islogged])

  return { loading, islogged, user }
}

const RouterContext = () => {
  const { loading, islogged, user } = useRouterContext()

  if (!loading && islogged !== undefined) {
    return (
      <RouterProvider
        router={!islogged || !user?.uuid ? PublicRoute : PrivateRoute}
      />
    )
  }
  return <Welcome />
}

export default RouterContext
