import { TICKETLIMITS } from '@constants/ticketlimits'
import { InputProps, Col, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface TitleProps extends InputProps {
  span?: number
  initialValue?: string
}

const TitleForAll = ({ span = 12, initialValue = '' }: TitleProps) => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Form.Item
        rules={TICKETLIMITS.map(e => ({ ...e, message: t(e.message) }))}
        name="title"
        label={`${t('title')}:`}
        initialValue={initialValue}
      >
        <Input />
      </Form.Item>
    </Col>
  )
}

export default TitleForAll
