import { useUser } from '@contexts/UserContext'
import { useEffect, useState } from 'react'
import { api } from '@helpers/api'
import DeviceDrawer from '../DeviceDrawer'
import { useDrawerContext } from '@contexts/DrawerContext'
import Icon from '@components/common/Icon'
import { IDevice } from '@/types/IDevice'
import { Button, Space } from 'antd'

const DeviceParent = ({
  parent
}: {
  parent: Pick<IDevice, 'uuid' | 'name'>
}) => {
  const { user } = useUser()
  const [data, setData] = useState()
  useEffect(() => {
    if (user.level > 1 && parent?.uuid) {
      api.get(`/device/${parent.uuid}`).then(e => {
        setData(e.data)
      })
    }
  }, [])
  if (!data) return <>{parent.name}</>
  return (
    <DeviceDrawer device={data}>
      <InternalDeviceParent data={data} parent={parent} />
    </DeviceDrawer>
  )
}

const InternalDeviceParent = ({ parent, data }: any) => {
  const { open } = useDrawerContext(`/device/${parent.uuid}`, data)
  return (
    <Button type="primary" onClick={open}>
      <Space>
        {parent.name}
        <Icon name="fa-light fa-external-link" color="white" />
      </Space>
    </Button>
  )
}

export default DeviceParent
