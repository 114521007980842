import { useState, useEffect } from 'react'
import { translateColumns } from '@helpers/translateColumns'
import { IGraphStatiscCount } from '@/types/IGraphStatisc'
import { IPie } from '@/types/IGraphPie'
import { useTranslation } from 'react-i18next'
import useSocket from './useSocket'

const useSummary = (
  func: () => Promise<IGraphStatiscCount[] | IPie[]>,
  name: string
) => {
  const { t } = useTranslation()
  const localStorageData = localStorage.getItem(name)
  const initialData = localStorageData ? JSON.parse(localStorageData) : null
  const [data, setData] = useState<IGraphStatiscCount[] | IPie[] | null>(
    initialData
  )
  const fetchData = async () => {
    const data = await func().then(item =>
      item.slice().sort((a, b) => a.key - b.key)
    )

    const translatedData = data[0].type
      ? translateColumns(data, t, 'type')
      : data
    setData(translatedData)
    localStorage.setItem(name, JSON.stringify(translatedData))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useSocket(fetchData)
  return { data }
}

export default useSummary
