import {
  Alert,
  Col,
  Tag,
  Collapse,
  Flex,
  Space,
  Card,
  Row,
  Divider
} from 'antd'
import { Text } from '@components/common/Text'
import { IHwspecs } from '@/types/IDeviceHardware'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { DeviceHardwareContextType } from '@/types/IDeviceHardware'
import { DeviceHardwareContext } from '@contexts/DeviceHardwareContext'
const useDeviceHardwareContext = () =>
  useGenericContext<DeviceHardwareContextType>(DeviceHardwareContext)

interface RecursiveChildrenProps {
  children: {
    key: string
    value: IHwspecs
  }
}

const SpecsHardware = () => {
  const { t } = useTranslation()
  const { data } = useDeviceHardwareContext()
  return (
    <Col span={24}>
      <Divider>{t('HW_SPECS')}</Divider>
      {data?.hwspecs ? (
        <SpecsCollapse data={data.hwspecs} />
      ) : (
        <Alert
          data-cy="spec-alert"
          showIcon
          message={t('NO_REGISTRY')}
          style={{ width: '100%' }}
          type="warning"
        ></Alert>
      )}
    </Col>
  )
}

const SpecsCollapse = ({ data }: { data: IHwspecs }) => {
  const { t } = useTranslation()
  const items = Object.entries(data).map(([key, value]) => ({
    key: key.toString(),
    label: t(`${key.toString().toUpperCase()}`),
    children: <Children children={value} />
  }))
  return <Collapse data-cy="specs-collapse" items={items} />
}

const Children = ({ children }: { children: IHwspecs }) => {
  const items = Object.entries(children).map(([key, value]) => ({
    key: key.toString(),
    value: value
  }))
  return (
    <Space direction="vertical">
      {items.map(item =>
        item.key !== 'meta' && item.key !== 'data' ? (
          <Space>
            <Text strong>{`${item.key}:`}</Text>
            <Text>{`${item.value}`}</Text>
          </Space>
        ) : (
          <RecursiveChildren children={item} />
        )
      )}
    </Space>
  )
}

const RecursiveChildren = ({ children }: RecursiveChildrenProps) => {
  const { t } = useTranslation()

  return (
    <Row gutter={16}>
      {children.key === 'meta' ? (
        <Col span={24}>
          <Card
            data-cy="spec-children-overview"
            title={t('OVERVIEW')}
            type="inner"
          >
            <Children children={children.value} />
          </Card>
        </Col>
      ) : (
        <Col span={24}>
          <Card data-cy="spec-children-info" title={t('INFO')} type="inner">
            <Flex wrap="wrap">
              {children.value?.map(item => (
                <Tag color="geekblue" style={{ marginBottom: '10px' }}>
                  <Children children={item} />
                </Tag>
              ))}
            </Flex>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default SpecsHardware
