import { IService } from '@/types/IService'
import { useTheme } from 'styled-components'
import { IRequestList } from '@/types/ICollection'
import { ColumnsType } from 'antd/es/table'
import { TFunction } from 'i18next'
import useConfirmAction from '@hooks/useConfirmAction'
import { useTranslation } from 'react-i18next'
import IconButton from '@common/IconButton'
import { api } from '@helpers/api'
import { Tag, Row, Space, Modal } from 'antd'
import ModalService from '@components/Service/Modal'
import { IPriorities } from '@/types/ITicket'
import { humanizeDurationsCompact } from '@helpers/humanizeDurations'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
interface PropsWithChildrenText {
  children: string
}
const TranslatedText = (props: PropsWithChildrenText) => {
  const { t } = useTranslation()
  return <span>{t(props.children)}</span>
}

const items = (data: IService, t: TFunction) => [
  {
    key: '1',
    span: 3,
    label: 'NAME',
    children: <span>{t(data.name)}</span>
  },
  {
    key: '2',
    span: 3,
    label: 'SECTOR',
    children: <span>{t(data.sector?.name || '')}</span>
  },
  {
    key: '3',
    span: 3,
    label: 'COMPLEXITY',
    children: <span>{t(data.complexity)}</span>
  },
  {
    key: '4',
    span: 3,
    label: 'CATEGORY',
    children: <span>{data.category?.name || ''}</span>
  },
  {
    key: '5',
    span: 3,
    label: 'EXPECTEDTIME',
    children: (
      <span>
        {data.expected_time ? humanizeDurationsCompact(data.expected_time) : 0}
      </span>
    )
  }
]

const DeleteServiceButton = ({ data }: { data: IService }) => {
  const [modal, contextHolder] = Modal.useModal()
  const { t } = useTranslation()
  const { confirm } = useConfirmAction({
    modal,
    func: () => api.delete(`ticket/service/${data.uuid}`),
    descriptions: items(data, t).map(item => {
      return { ...item, label: t(item.label) }
    }),
    messages: {
      title: 'AREYOUSUREDELETESERVICE',
      name: data.name,
      onSucess: 'SUCESSDELETESERVICE',
      onError: 'ERRORDELETESERVICE'
    }
  })
  return (
    <>
      {contextHolder}
      <IconButton
        danger
        onClick={confirm}
        name="fa-light fa-trash"
        id="deleteService"
      />
    </>
  )
}

const Actions = ({ data }: { data: IService }) => {
  const theme = useTheme()
  return (
    <Space>
      <ModalService
        data={data}
        style={{ backgroundColor: theme.geekblue, color: theme.white }}
        name="fa-light fa-edit"
        data-cy="edit-service"
      />
      <DeleteServiceButton data={data} />
    </Space>
  )
}

export const columns: ColumnsType<IService> = [
  {
    title: 'ACTIONS',
    dataIndex: 'action',
    key: 'action',
    render: (_, action: IService) => <Actions data={action} />
  },
  {
    title: '#',
    dataIndex: 'category_id',
    key: 'category_id'
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'SECTOR',
    dataIndex: 'sector',
    key: 'sector',
    render: sector => <span>{sector?.name || ''}</span>
  },
  {
    title: 'DESCRIPTION',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'COMPLEXITY',
    dataIndex: 'complexity',
    key: 'complexity',
    render: requestby => <TranslatedText>{requestby}</TranslatedText>
  },
  {
    title: 'CATEGORY',
    dataIndex: 'category',
    key: 'category',
    render: category => <span>{category ? category?.slug : null}</span>
  },
  {
    title: 'PRIORITY',
    dataIndex: 'priority',
    key: 'priority',
    render: (priority: IPriorities) => {
      return (
        <Tag color={TICKETSPRIORITIESCOLORS[priority].color || 'gray'}>
          <TranslatedText>{priority}</TranslatedText>
        </Tag>
      )
    }
  },
  {
    title: 'REQUESTBY',
    dataIndex: 'request_by',
    key: 'request_by',
    render: requestby => <TranslatedText>{requestby}</TranslatedText>
  },
  {
    title: 'EXPECTEDTIME',
    dataIndex: 'expected_time',
    key: 'expected_time',
    render: (time: number) => (
      <Row justify="center">
        {time !== null ? (
          <Tag color="blue-inverse">{humanizeDurationsCompact(time)}</Tag>
        ) : null}
      </Row>
    )
  },
  {
    title: 'STEPS',
    dataIndex: 'steps',
    key: 'steps',
    render: (steps: IService['steps']) =>
      steps ? steps.reduce((a, b) => a + (b.tasks.length || 1), 0) : 0
  }
]
