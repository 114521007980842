import Title from '@common/Title'
import { useTranslation } from 'react-i18next'
import { Col, Row, Tabs } from 'antd'
import { renderingTabFilter } from '@components/Server/Table'
import type { TabsProps } from 'antd'
import CompaniesSelect from '@components/common/CompaniesSelect'
import CreateServer from '@components/Server/CreateServer'
import SearchByQueryInput from '@components/common/SearchByQueryInput'
import { DeviceContext } from '@contexts/DeviceContext'
import { useState } from 'react'
import { IDevice } from '@/types/IDevice'
import Device from '@pages/Device'
import DrawerProvider from '@contexts/DrawerContext'
import DeviceHead from '@components/Device/DeviceHead'

const Servers = () => {
  const [data, setData] = useState<IDevice | undefined>(undefined)
  const { t } = useTranslation()
  const renderingWithTab: TabsProps['items'] = renderingTabFilter(
    'device?type=BAREMETAL'
  )

  return (
    <DeviceContext.Provider value={{ data, setData }}>
      <DrawerProvider component={Device} head={DeviceHead}>
        <Title name={t('SERVERS')} />
        <Row gutter={[8, 8]}>
          <CompaniesSelect span={8} />
          <SearchByQueryInput />
          <CreateServer />
        </Row>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={renderingWithTab}
          type="card"
          size="large"
        />
      </DrawerProvider>
    </DeviceContext.Provider>
  )
}

export default Servers
