export const READINGCOMPUTEDCOLORS = {
  'State Deasserted': { color: 'orange-inverse' },
  Present: { color: 'green-inverse' },
  'Presence detected': { color: 'gold-inverse' },
  Connected: { color: 'darkgreen' },
  'N/A': { color: 'red-inverse' },
  'Fully Redundant': { color: 'purple-inverse' },
  'Drive Present': { color: 'geekblue-inverse' },
  Absent: { color: 'darkred' }
}
