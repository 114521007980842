import { TICKETSTATUSESCOLORS } from "@constants/ticketstatuses";
import useTicketSelect from '@hooks/useTicketSelect';
import FilterSelect from "@components/common/FilterSelect";
import { Select } from "antd";

const searchStatusFilter = async (searchText = "") => {
  return Object.entries(TICKETSTATUSESCOLORS)
    .filter(([status]) =>
      status.toLowerCase().includes(searchText.toLowerCase())
    )
    .map(([status, { color, icon }]) => ({
      value: status,
      label: status,
      color,
      icon
    }));
};

const StatusFilterSelect = () => {
  const { options, ...props} = useTicketSelect({
    name: 'status',
    func: searchStatusFilter
  });

  return (
    <FilterSelect
      options={options}
      placeholderKey="FILTERBYSTATUS"
      data-cy="filterStatus"
      optionRenderer={({ value, label, color, icon }) => (
        <Select.Option key={value} value={value}>
          <span style={{ color }}>
            {icon} {label}
          </span>
        </Select.Option>
      )}
      {...props}
    />
  );
};

export default StatusFilterSelect;
