import {
  useImperativeHandle,
  useRef,
  forwardRef,
  useState,
  Dispatch,
  SetStateAction
} from 'react'
import { ModalProps, Form, DatePicker, Input, Row, Button, Select } from 'antd'
import Modal from '@common/Modal'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import { api } from '@helpers/api'
import { formatRangeDate } from '@helpers/formatRangeDate'
import type { UnitTypeShort } from 'dayjs/esm'
import {
  ServicesSelect,
  DefaultSearchSelect
} from '@components/Ticket/TicketItemsForSupport'
import { SearchSelectWithForm } from '@components/common/SearchSelectWithForm'
import useSearchSelect from '@hooks/useSearchSelect'

const Option = Select.Option

type Date = {
  [K in UnitTypeShort as `$${K}`]: number
} & {
  $H: number
}

type ReportTicketType = {
  assign_to: string
  search: string
  sector: string
  service: string
  time: [Date, Date]
}

type ImperativeType = {
  report: () => void
}

type ReportProps = {
  loading: Dispatch<SetStateAction<boolean>>
}

const { RangePicker } = DatePicker

const fullWidth = { width: '100%' }

const formatRange = (range: [Date, Date]) => ({
  start_at: formatRangeDate(range[0]),
  end_at: formatRangeDate(range[1])
})

const formatReport = (submit: ReportTicketType) => ({
  ...formatRange(submit.time),
  search: submit.search || '',
  assignedSectors: submit.sector ? String(submit.sector) : '',
  assignedTo: submit.assign_to || '',
  notAssignedSectors: submit.sector?.includes('comercial') ? '' : 'comercial',
  services: String(submit.service) || ''
})

const onFinish = async (
  report: ReportTicketType,
  loading: Dispatch<SetStateAction<boolean>>
) => {
  const formatedReport = formatReport(report)
  return api
    .get('/ticket/report/export', {
      params: formatedReport,
      responseType: 'blob'
    })
    .then(response => {
      const anchor = document.createElement('a')
      ;(anchor.href = window.URL.createObjectURL(response.data)),
        (anchor.download = 'report.xlxs'),
        document.body.appendChild(anchor)
      anchor.click()
      window.URL.revokeObjectURL(anchor.href)
      loading(false)
    })
}

const SUsersSelect = () => {
  const { options, onSearch, onFocus, loading } = useSearchSelect({
    func: (search = '') =>
      api.get(`susers?search=${search}`).then(e => e.data.data)
  })
  return (
    <SearchSelectWithForm name="assign_to" {...{ onSearch, onFocus, loading }}>
      {options
        ? options.map(e => (
            <Option value={e.uuid} key={e.uuid} children={e.name} />
          ))
        : null}
    </SearchSelectWithForm>
  )
}

const ReportTickets = forwardRef<ImperativeType | undefined, ReportProps>(
  ({ loading }, ref) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    useImperativeHandle(ref, () => ({
      report() {
        loading(true)
        form.submit()
      }
    }))

    return (
      <Row {...fullWidth}>
        <Form.Item
          rules={[{ required: true, message: t('requiredItem') }]}
          name="time"
          label={`${t('PERIOD')}:`}
        >
          <RangePicker showTime />
        </Form.Item>
        <DefaultSearchSelect
          allowClear
          name="sector"
          span={24}
          required={false}
        />
        <SUsersSelect span={24} required={false} allowClear />
        <ServicesSelect
          allowClear
          style={{ width: '100%' }}
          span={24}
          required={false}
          mode="multiple"
        />
        <Form.Item name="search" label={`${t('SEARCH')}:`}>
          <Input />
        </Form.Item>
      </Row>
    )
  }
)

const ModalReportTickets = (props: ModalProps) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const childRef = useRef<ImperativeType>()
  const onReport = () => {
    if (childRef.current) childRef.current.report()
  }

  return (
    <Modal
      {...props}
      width={'33%'}
      footer={[
        <Button
          loading={loading}
          icon={<Icon name="fa-light fa-file-export" color="white" />}
          type="primary"
          onClick={onReport}
        >
          {t('EXPORT')}
        </Button>
      ]}
    >
      <ReportTickets ref={childRef} loading={setLoading} />
    </Modal>
  )
}

export default ModalReportTickets
