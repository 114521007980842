import { Button } from 'antd'
import Icon from '@common/Icon'
import { useTheme } from 'styled-components'

interface DeleteButtonProps {
  uuid: string
  onDelete: () => void
}

const DeleteButton = ({ uuid, onDelete }: DeleteButtonProps) => {
  const theme = useTheme()
  if (uuid === 'noGroup') {
    return <></>
  }

  return (
    <Button
      data-cy={`delete-group-${uuid}`}
      danger
      onClick={onDelete}
      style={{ backgroundColor: theme.blue }}
      type="primary"
      icon={<Icon name="fa-solid fa-trash" color="white" />}
    />
  )
}
export default DeleteButton
