import { Row, Col, Form, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';

interface TimeRangeInputProps {
  namePrefix: string;
  label: string;
  startPlaceholder: string;
  endPlaceholder: string;
  validateTimeDifference: (startTime: any, endTime: any) => boolean;
}

const TimeRangeInput = ({
  namePrefix,
  label,
  startPlaceholder,
  endPlaceholder,
  validateTimeDifference,
  ...props
}: TimeRangeInputProps) => {
  const { t } = useTranslation();

  return {
    label,
    component: (
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            {...props}
            name={`${namePrefix}_start_at`}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_end_at`)) {
                    return Promise.resolve();
                  }
                  if (validateTimeDifference(value, getFieldValue(`${namePrefix}_end_at`))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')));
                },
              }),
            ]}
          >
            <TimePicker
              format="HH:mm:ss"
              style={{ width: '100%' }}
              placeholder={startPlaceholder}
              showNow={false}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={`${namePrefix}_end_at`}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || !getFieldValue(`${namePrefix}_start_at`)) {
                    return Promise.resolve();
                  }
                  if (validateTimeDifference(getFieldValue(`${namePrefix}_start_at`), value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('FORMONBOARD.TIME_ERROR')));
                },
              }),
            ]}
          >
            <TimePicker
              format="HH:mm:ss"
              style={{ width: '100%' }}
              placeholder={endPlaceholder}
            />
          </Form.Item>
        </Col>
      </Row>
    ),
    rules: [{ required: true }],
  };
};

export default TimeRangeInput;
