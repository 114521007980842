export const level = [
  { value: 1, label: 'CUSTOMER' },
  { value: 600, label: 'EMPLOYEES' },
  { value: 700, label: 'COMMERCIAL' },
  { value: 750, label: 'COMMERCIAL_MANAGER'},
  { value: 800, label: 'FINANCE' },
  { value: 900, label: 'SUPPORT' },
  { value: 950, label: 'SUPPORT_MANAGER'},
  { value: 1000, label: 'ROOT' }
]
