import { useState, Dispatch, SetStateAction } from 'react'
import {
  Row,
  Col,
  Button,
  Steps,
  Popconfirm,
  Typography,
  Tooltip,
  Space
} from 'antd'
import Modal from '@common/Modal'
import useModal from '@hooks/useModal'
import { ordersteps } from '@constants/ordersteps'
import { useTranslation } from 'react-i18next'
import { IOrder, ICustomer } from '@/types/IOrder'
import { api } from '@helpers/api'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import ModalDocument from '../ModalDocument'
import Icon from '@components/common/Icon'
import ModalFooter from '@components/common/ModalFooter'
import FormCompany from '@components/Company/Form'
import TicketOrderButton from '../TicketOrderButton'
import { useUser } from '@contexts/UserContext'
import { getOrderDocument } from '@helpers/getOrderDocument'

interface ModalStepsProps {
  data: IOrder
  items: { key: string; title: string; content: string }[]
  content: string
  setCurrent: Dispatch<SetStateAction<number>>
  current: number
}

const { Text } = Typography

const OrderSteps = ({ data }: { data: IOrder }) => {
  const { t } = useTranslation()
  const [current, setCurrent] = useState(
    ordersteps.findIndex(item => item.title === data.status)
  )

  const download = async () => {
    const response = await getOrderDocument(data.uuid)
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${data.uuid}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const items = ordersteps.map(item => ({
    key: item.title,
    title: t(item.title),
    content: t(item.content)
  }))

  return (
    <>
      <Steps current={current} items={items} size="small" />
      <Row style={{ marginTop: '10px' }} wrap>
        <Col lg={10}>
          {current < ordersteps.length - 1 && (
            <>
              <ModalSteps {...{ data, items, setCurrent, current }} />
              {current === 1 && <RejectedButton data={data} />}
            </>
          )}
          {current === ordersteps.length - 1 && (
            <TicketOrderButton tickets={data.ticket_ids} />
          )}
        </Col>
        <Col lg={2} offset={12}>
          <Space>
            <Tooltip title={t('DOWNLOAD')}>
              <Button
                type="primary"
                data-cy="download-document"
                icon={<Icon name="fa-solid fa-download" color="white" />}
                onClick={download}
              />
            </Tooltip>
            <ModalDocument data={data} data-cy="download-document" />
          </Space>
        </Col>
      </Row>
    </>
  )
}

const ModalSteps = ({ data, current, items, setCurrent }: ModalStepsProps) => {
  const { user } = useUser()
  const numForm = Math.floor(Math.random() * 10000).toString()
  const messageApi = useGenericContext(MessageContext)
  const { t } = useTranslation()
  const { open, onOpen, onCancel } = useModal()

  const next = async () => {
    const step = getNextStep(current, data)
    try {
      await api.put(`/order/${data.uuid}`, {
        status: ordersteps[current + step].title
      })
      messageApi.success(t('ORDERSTATUSSUCESS'))
      setCurrent(current + step)
    } catch (e) {
      if (e.response.data.message === 'Invalid company') {
        onOpen()
        messageApi.error(t('INVALIDCOMPANY'), 10)
        return
      }
      messageApi.error(e.response.data.message, 10)
    }
  }

  const customer = formatPhonesEmails(data.customer)
  return (
    <>
      <Modal
        footer={
          <ModalFooter onClose={onCancel} action={'create'} form={numForm} />
        }
        width="60%"
        centered
        open={open}
        onCancel={onCancel}
      >
        <FormCompany
          id={numForm}
          data={customer}
          action={'create'}
          onClose={onCancel}
        />
      </Modal>
      <Popconfirm
        title={t('NEXTSTEP')}
        description={
          <Text
            style={{
              display: 'block',
              maxWidth: '400px'
            }}
          >
            {items[current].content}
          </Text>
        }
        onConfirm={() => next()}
      >
        <Button type="primary" disabled={current === 1 && user.level < 750}>
          {t('NEXTSTEP')}
        </Button>
      </Popconfirm>
    </>
  )
}

const getNextStep = (current: number, data: IOrder) => {
  const isDraftWithNoDiscount =
    ordersteps[current].title === 'DRAFT' && !data.discount_percents
  return isDraftWithNoDiscount ? 2 : 1
}

const formatPhonesEmails = (data: ICustomer) => {
  if (typeof data?.emails === 'string') {
    data.emails = [data.emails]
  }

  if (typeof data?.phones === 'string') {
    let formattedPhone = data.phones.replace(/[^\d+]/g, '')
    if (!formattedPhone.startsWith('+')) {
      formattedPhone = '+' + formattedPhone
    }
    data.phones = [formattedPhone]
  }

  return data
}

const RejectedButton = ({ data }: { data: IOrder }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const messageApi = useGenericContext(MessageContext)

  const rejected = async () => {
    try {
      await api.put(`/order/${data.uuid}`, {
        status: 'REJECTED'
      })
      messageApi.success(t('ORDERSTATUSSUCESS'))
    } catch (e) {
      messageApi.error(e.response.data.message, 10)
    }
  }

  return (
    <Popconfirm
      title={t('REJECTORDER')}
      description={
        <Text
          style={{
            display: 'block',
            maxWidth: '400px'
          }}
        >
          {t('AREYOUSUREREJECTORDER')}
        </Text>
      }
      onConfirm={() => rejected()}
    >
      <Button
        style={{ marginLeft: '10px' }}
        type="primary"
        danger
        disabled={user.level < 750}
      >
        {t('REJECTORDER')}
      </Button>
    </Popconfirm>
  )
}

export default OrderSteps
