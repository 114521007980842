export const REQUESTBYICONS = {
  APP: 'fa-light fa-mobile-notch',
  EMAIL: 'fa-light fa-envelope',
  PHONE: 'fa-light fa-phone',
  DIRECT: 'fa-light fa-user',
  LETTER: 'fa-light fa-envelope',
  WEB: 'fa-light fa-globe',
  WHATSAPP: 'fa-brands fa-whatsapp',
  AUTO: 'fa-light fa-robot',
  OTHER: 'fa-solid fa-cicle-question',
  UNDEFINED: 'fa-solid fa-cicle-question',
  UNKNOWN: 'fa-solid fa-cicle-question'
}
