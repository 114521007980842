import {
  SwapOutlined,
  UserSwitchOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import { Typography, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { ITicketHistory } from '@/types/ITicketHistory'
import StatusPriorityTag from '../StatusPriorityTag'
import dayjs from 'dayjs'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { DATEFORMAT } from '@constants/dateformat'

const { Text } = Typography

export const generateHistory = (history: ITicketHistory, items: any) => {
  const actionsMap = {
    CHANGED_STATUS: () => [StatusChange(history)],
    CHANGED_ASSIGN: () => [AssignChange(history)],
    CHANGED_SCHEDULE_AT: () => [ScheduleChange(history)],
    CHANGED_ASSIGN_AND_STATUS: () => [
      StatusChange(history),
      AssignChange(history)
    ]
  }

  const actions = actionsMap[history.action]

  if (actions) {
    actions().forEach(action => {
      items.push({
        children: action.children,
        dot: action.dot
      })
    })
  }
}

const StatusChange = (history: ITicketHistory) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return {
    children: (
      <Space
        data-cy="ticket-history-status"
        style={{ marginLeft: '20px' }}
        wrap
      >
        <Text>{t('CHANGED_STATUS')}</Text>
        <StatusPriorityTag
          data={history.old_value}
          obj={TICKETSTATUSESCOLORS}
        />
        <Text>{t('FOR')}</Text>
        <StatusPriorityTag
          data={history.new_value}
          obj={TICKETSTATUSESCOLORS}
        />
      </Space>
    ),
    dot: <SwapOutlined style={{ fontSize: '30px', color: theme.blueDark }} />
  }
}

const ScheduleChange = (history: ITicketHistory) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return {
    children: (
      <Text data-cy="ticket-history-schedule" style={{ marginLeft: '20px' }}>
        {`${t('CHANGED_SCHEDULE_AT')} ${dayjs(history.schedule_at).format(DATEFORMAT)}`}
      </Text>
    ),
    dot: (
      <ClockCircleOutlined
        style={{ fontSize: '25px', color: theme.blueDark }}
      />
    )
  }
}

const AssignChange = (history: ITicketHistory) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return {
    children: (
      <p data-cy="ticket-history-assign" style={{ marginLeft: '20px' }}>
        {`${t('CHANGED_ASSIGN')} ${history.assign_to?.name}`}
      </p>
    ),
    dot: (
      <UserSwitchOutlined style={{ fontSize: '30px', color: theme.blueDark }} />
    )
  }
}
