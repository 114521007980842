import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Input, Form, message } from "antd";
import { useUser } from '@contexts/UserContext';
import styled from "styled-components";
import axios from "axios";
import { FormItemProps } from "antd/lib";

const API_URL = import.meta.env.VITE_API_URL;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ResetPasswordCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
`;

interface PasswordFormItemProps extends FormItemProps {
  placeholder: string;
}

const PasswordFormItem = ({ placeholder, ...props }: PasswordFormItemProps) => {
  return (
    <Form.Item
      rules={[
        { required: true, message: `Please enter your ${props.label?.toString().toLowerCase()}!` },
        { min: 8, message: "Password must be at least 8 characters." },
      ]}
      {...props}
    >
      <Input.Password placeholder={placeholder} />
    </Form.Item>
  );
};

const updatePassword = async (password: string, token: string) => {
  return axios.put(`${API_URL}/session/user`, 
    { password },
    { headers: { 'Authorization': `Bearer ${token}` } }
  );
};

const fetchUserData = async (token: string) => {
  const response = await axios.get(`${API_URL}/session/user`, {
    headers: { 'Authorization': `Bearer ${token}` }
  });
  return response.data;
};

const setAuthCookie = (token: string) => {
  document.cookie = `---OPEN=${token}---; path=/; max-age=32000`;
};

const handlePasswordReset = async (
  password: string,
  confirmPassword: string,
  token: string,
  setUser: (user: any) => void,
  setIsLogged: (isLogged: boolean) => void,
  navigate: (path: string) => void,
  setLoading: (loading: boolean) => void
) => {
  if (password !== confirmPassword) {
    message.error("Passwords do not match!");
    return;
  }

  setLoading(true);

  updatePassword(password, token)
    .then(() => fetchUserData(token))
    .then((userData) => {
      setAuthCookie(token);
      setUser(userData);
      setIsLogged(true);
      message.success("Password updated successfully!");
      navigate("/");
    })
    .catch(() => {
      message.error("Failed to reset password. Please try again.");
    })
    .finally(() => {
      setLoading(false);
    });
};

const ResetPasswordForm = ({ onFinish, loading }: { onFinish: (values: { password: string; confirmPassword: string }) => void; loading: boolean }) => (
  <Form onFinish={onFinish} layout="vertical">
    <PasswordFormItem
      label="New Password"
      name="password"
      placeholder="Enter new password"
    />
    <PasswordFormItem
      label="Confirm New Password"
      name="confirmPassword"
      placeholder="Confirm new password"
    />
    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block>
        Reset Password
      </Button>
    </Form.Item>
  </Form>
);

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsLogged, setUser } = useUser();
  const { state: token } = useLocation();

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    handlePasswordReset(
      values.password,
      values.confirmPassword,
      token,
      setUser,
      setIsLogged,
      navigate,
      setLoading
    );
  };

  return (
    <Container>
      <ResetPasswordCard title="Reset Password">
        <ResetPasswordForm onFinish={onFinish} loading={loading} />
      </ResetPasswordCard>
    </Container>
  );
};

export default ResetPassword;
