import { MouseEventHandler } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

interface ModalFooterProps {
  form: string
  action: 'create' | 'edit'
  onClose: MouseEventHandler<HTMLElement>
}

const ModalFooter = ({ onClose, form }: ModalFooterProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Button
        onClick={onClose}
        danger
        type="primary"
        children={t('CANCEL')}
        form={form}
      />
      <Button
        children={t('CONFIRM')}
        type="primary"
        form={form}
        key="submit"
        htmlType="submit"
      />
    </>
  )
}
export default ModalFooter
