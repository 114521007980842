import { Select } from "antd";
import { useTranslation } from "react-i18next";
import useSearchParams from "@hooks/useSearchParams";

const { Option } = Select

const useSortSelect = () => {
    const params = useSearchParams(true);
  
    const sortOptions = [
      { value: 'priority', label: 'Priority' },
      { value: 'status', label: 'Status' },
      { value: 'created_at', label: 'Created At' },
      { value: 'updated_at', label: 'Updated At' },
    ];
  
    const getSelectedSort = () => {
      return params.getItem('sort') || '';
    };
  
    const onChangeSort = (value: string) => {
      if (value) {
        params.setItem('sort', value);
      }
    };
  
    const selectedSort = getSelectedSort() || undefined;
  
    return { selectedSort, onChangeSort, sortOptions };
  };
  
  const SortSelect = () => {
    const { t } = useTranslation();
    const { selectedSort, onChangeSort, sortOptions } = useSortSelect();
  
    return (
      <Select
        style={{ width: '100%' }}
        placeholder={t('SORT_BY')}
        value={selectedSort || undefined}
        onChange={onChangeSort}
        allowClear
        data-cy="filterSort"
      >
        {sortOptions.map(option => (
          <Option key={option.value} value={option.value}>
            {t(option.label)} (Asc)
          </Option>
        ))}
        {sortOptions.map(option => (
          <Option key={`-${option.value}`} value={`-${option.value}`}>
            {t(option.label)} (Desc)
          </Option>
        ))}
      </Select>
    );
  };

  export default SortSelect;
