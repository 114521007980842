import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postLogin, api, getSprite } from '@helpers/api'
import { useUser } from '@contexts/UserContext'
import { Logo, Image } from './style.js'
import { Link } from 'react-router-dom'
import FormLogin from '../../components/FormLogin'
import { Alert, Row, Col, Typography, Space } from 'antd'
import { useTranslation } from 'react-i18next'

const urlPlayStore =
  'https://play.google.com/store/apps/details?id=center.opendata.app&utm_source=webapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
const urlAppleStore = 'https://apps.apple.com/app/open-datacenter/id6449605126'

interface LoginProps {
  errorCredential: boolean
  loading: boolean
  onFinish: any
}

const Error = ({ error }: { error: boolean }) => {
  const { t } = useTranslation()
  return error ? <Alert message={t('errorCredential')} type="error" /> : null
}

const checkStatusFetch = (
  response: any,
  setErrorCredential: any,
  setIsLogged: any,
  setUser: any,
  navigate: any
) => {
  if (response.status === 200) {
    setToken(`---OPEN=${response.data.token}---; path=/; max-age=32000`)
    handleLogin(setUser, setIsLogged)
    redirectRoute(navigate)
  } else {
    setErrorCredential(true)
  }
}

const setToken = (token: string) => {
  document.cookie = token
}

const handleLogin = async (setUser: Function, setIsLogged: Function) => {
  const user = await api.get('/session/user')
  if (user.status === 200) {
    setUser(user.data)
    setIsLogged(true)
  }
}

const redirectRoute = (navigate: any) => {
  if (window.location.href.match(/\/login$/g) != null) {
    navigate('/')
  }
}

const LayoutLogin = ({ errorCredential, loading, onFinish }: LoginProps) => {
  const { t } = useTranslation()
  return (
    <Row style={{ height: '100vh' }}>
      <Col
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        lg={{ span: 12 }}
        style={{
          backgroundColor: '#005892',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Space
          direction="vertical"
          size="small"
          style={{ textAlign: 'center' }}
        >
          <Logo src="/assets/images/logo-opendatacenter-white.png" />
          <Typography.Text style={{ fontSize: '32px', color: 'white' }}>
            Cloud Manager
          </Typography.Text>
        </Space>
      </Col>
      <Col
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        lg={{ span: 12 }}
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Space direction="vertical">
          <FormLogin loading={loading} onFinish={onFinish} size="large" />
          <Error error={errorCredential} />
          <Link to={'/forgot-password'}>{t('FORGOTPASSWORD')}</Link>
          <Space size="small">
            <Link to={urlAppleStore}>
              <Image src="/assets/images/apple-store.png" />
            </Link>
            <Link to={urlPlayStore}>
              <Image src="/assets/images/play-store.png" />
            </Link>
          </Space>
        </Space>
      </Col>
    </Row>
  )
}

const Login = () => {
  const { setIsLogged, setUser } = useUser()
  const [errorCredential, setErrorCredential] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true)
    setErrorCredential(false)
    const { email, password } = values
    const res = await postLogin(email, password).then(e => {
      setLoading(false)
      return e
    })
    checkStatusFetch(res, setErrorCredential, setIsLogged, setUser, navigate)
  }

  return (
    <LayoutLogin
      errorCredential={errorCredential}
      loading={loading}
      onFinish={onFinish}
    />
  )
}

export default Login
