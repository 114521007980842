import { useEffect } from 'react'
import { Row, Tabs } from 'antd'
import axios from 'axios'
import Title from '@common/Title'
import { useUser } from '@contexts/UserContext'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { columns } from '@components/Quotes/Table'

const Quotes = () => {
  const { user } = useUser()
  useEffect(() => {
    axios
      .post(
        'https://painel.opendatacenter.com.br/api/applogin',
        { user: user },
        {
          headers: {
            'Open-Token': 'fed34024a0044bfba8b7cfcedbe46f90',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(e => {
        const url = `https://painel.opendatacenter.com.br/api/loginredirect/${e.data.uuid}/?path=admin/partner`
        window.location.href = url
      })
  }, [])
  return <Title name="Cotações" />
}

export default Quotes
