import { Progress } from 'antd'
import ResponsiveMarquee from '@components/Ticket/ResponsiveMarquee'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import TicketTime from '@components/Ticket/Time'
import useTicketTime from '@hooks/useTicketTime'
import type { ITicket } from '@/types/ITicket'

interface ProgressLineProps {
  data: ITicket
  expected: number
}

export const progressStatuses = {
  UNKNOWN: 'normal',
  UNDEFINED: 'normal',
  NEW: 'active',
  PROCESSING_ATTRIBUTED: 'active',
  PROCESSING_PLANNED: 'normal',
  PENDING: 'normal',
  SOLVED: 'success',
  CLOSED: 'normal'
}

const ProgressLine = ({data, expected}: ProgressLineProps) => {
  const {current, diff} = useTicketTime(data)
  return (
    <Progress 
      strokeColor={TICKETSTATUSESCOLORS[data.status].color}
      status={diff && diff < 0 ? 'exception' : progressStatuses[data.status]}
      percent={
        expected 
          ? Number(((1 - current / expected) * 100).toFixed(1))
          : 0
      }
    />
  )
}


const ProgressTicketsDrawer = ({ data }: { data: ITicket }) => {
  return (
    <>
      {data.expected_time
        ?  
          <>
            <ResponsiveMarquee speed={25}> 
              <TicketTime data={data}/>
            </ResponsiveMarquee>
            <ProgressLine  data={data} expected={data.expected_time}/> 
          </>
          : null}
    </>
  )
}

export default ProgressTicketsDrawer
