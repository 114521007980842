import { useTranslation } from 'react-i18next'
import Title from '@components/common/Title'
import { Col, Row } from 'antd'
import DnsTabs from '@components/Dns/Tabs'
import { useTheme } from 'styled-components'
import Icon from '@components/common/Icon'
import ModalDns from '@components/Dns/Modal'
import { ZoneProvider } from '@contexts/ZoneContext'
import SearchByQueryInput from '@components/common/SearchByQueryInput'

function Dns() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <ZoneProvider>
      <Row
        gutter={[10, 5]}
        style={{ marginBottom: '8px' }}
        justify="space-between"
      >
        <Title name={'Dns'} />
        <SearchByQueryInput />
        <Col xs={{ span: 24 }} xl={{ span: 3 }}>
          <ModalDns
            style={{ backgroundColor: theme.green, color: theme.white }}
            block
            action="create"
            shape='default'
            children={t('CREATERECORD')}
            icon={<Icon name="fa-light fa-plus" color="white" />}
          />
        </Col>
      </Row>
      <DnsTabs />
    </ZoneProvider>
  )
}

export default Dns
