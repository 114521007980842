import { useState } from 'react'
import { MessageInstance } from 'antd/es/message/interface'
import { useTranslation } from 'react-i18next'
import { Form, Row, message } from 'antd'
import TitleForAll from '../TitleForAll'
import DeviceSelectForAll from '../DeviceSelectForAll'
import AutoFillForAll from '../AutofillForAll'
import FilesUploadForAll from '../FilesUploadForAll'
import {
  IsPrivateConfirm,
  SupportComponents
} from '@components/Ticket/TicketItemsForSupport'
import { api } from '@helpers/api'
import { postAttachmentsforTicket } from '@helpers/postAttachmentsforTicket'
import { FormInstance } from 'antd'
import { ModalContext } from '@contexts/ModalContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { useUser } from '@contexts/UserContext'
import { ITicket, ITicketInCreationOrChanged } from '@/types/ITicket'
import useDisableButtonModal from '@hooks/useDisableButtonModal'
import TicketDrawer, { useTicketDrawer } from '../TicketDrawer'

interface CreateTicketProps {
  type: string
  id: string
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const createTicket = async (
  { attachments, ...newTicket }: ITicketInCreationOrChanged,
  messageApi: MessageInstance,
  messages: string[],
  close: (e: ITicket) => void
) => {
  message.loading(messages[2])
  return await api
    .post('/ticket', { ...newTicket, request_by: 'WEB' })
    .then(async e => {
      messageApi.success(`${messages[0]} ${e.data.tkid} ${messages[1]}`, 3)
      close(e.data)
      if (attachments && attachments.length > 0) {
        messageApi.loading(messages[4], 3)
        postAttachmentsforTicket(attachments, e.data.tkid)
          .then(() => messageApi.success(messages[5], 3))
          .catch(() => messageApi.error(messages[6], 3))
          .finally(() => {
            messageApi.destroy()
          })
      }
    })
    .catch(() => messageApi.error(`${messages[3]}`, 3))
}

const MESSAGES = [
  'TICKET',
  'CREATED',
  'CREATINGTICKET',
  'UNEXPECTEDERROR',
  'UPLOADINGFILES',
  'SUCESSUPLOADFILES',
  'FAILUPLOADFILES'
]

const useCreateTicket = (form: FormInstance) => {
  const { t } = useTranslation()
  const { onCancel } = useGenericContext(ModalContext)
  const [disabled, setDisabled] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const openTicket = useTicketDrawer()

  const close = (newTicket: ITicket) => {
    form.resetFields()
    onCancel && onCancel()
    openTicket(newTicket)
  }

  const messages = MESSAGES.map(word => t(word))

  const onFinish = async (newTicket: ITicketInCreationOrChanged) => {
    setDisabled(true)
    await createTicket(newTicket, messageApi, messages, close).finally(() =>
      setDisabled(false)
    )
  }
  return { disabled, onFinish, contextHolder }
}

const CreateTicket = (props: CreateTicketProps) => {
  return (
    <TicketDrawer>
      <InternalCreateTicket {...props} />
    </TicketDrawer>
  )
}

const InternalCreateTicket = ({ setDisabled, ...props }: CreateTicketProps) => {
  const [form] = Form.useForm()
  const { user } = useUser()
  const { contextHolder, disabled, onFinish } = useCreateTicket(form)
  useDisableButtonModal(setDisabled, disabled)
  return (
    <Form {...props} {...{ form, disabled, onFinish, layout: 'vertical' }}>
      {contextHolder}
      <Row gutter={[8, 1]}>
        <TitleForAll span={12} />
        {user?.level && user.level > 1 ? (
          <SupportComponents type="create" form={form} />
        ) : null}
        <DeviceSelectForAll />
        <FilesUploadForAll span={user.level > 1 ? 12 : 24} />
        <AutoFillForAll client={!(user.level && user.level > 1)} />
        {user?.level && user?.level > 1 ? <IsPrivateConfirm /> : false}
      </Row>
    </Form>
  )
}

export default CreateTicket
