import FilterSelect from "@components/common/FilterSelect";
import useTicketSelect from "@hooks/useTicketSelect";
import useQueryItems from "@hooks/useQueryItems";
import { Select } from "antd";
import { SUserOption } from "../SUserSelect";

const searchSusers = async (search = '') => useQueryItems(search, 'susers');

const SUsersSelect = () => {
  const { options, ...props } = useTicketSelect({
    name: 'assignedTo',
    func: searchSusers,
  });

  return (
    <FilterSelect
      {...props}
      options={options}
      placeholderKey="ASSIGNEDTO"
      data-cy="filterassignedTo"
      optionRenderer={(e) => (
        <Select.Option key={e.uuid} label={e.name} value={e.uuid}>
          <SUserOption suser={e} />
        </Select.Option>
      )}
    />
  );
};

export default SUsersSelect;
