import { useState, createContext, ChangeEvent } from 'react'
import { useGenericContext } from '@hooks/useGenericContext'
import { api } from '@helpers/api'
import Modal from '@common/Modal'
import { Row, Col, ModalProps, Typography, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface SurveyResponseType {
  note: number
  comment?: string
  uuid: string
}

interface SurveyContextType {
  data: SurveyResponseType[]
  setData: (obj: SurveyResponseType, index: number) => void
  sendFeedback: () => Promise<void>
  snooze: () => void
  error: boolean
}

interface RatePointProps {
  min: number
  max: number
  index: number
  onClick: (item: number) => void
}

interface FeedbackProps extends ModalProps {
  nps: ISurvey[]
}

const SurveyContext = createContext<SurveyContextType | undefined>(undefined)

export interface ISurvey {
  uuid: string
  name: string
  description: string
  expire_at: string
  note_min: number
  note_max: number
  allow_comment: boolean
  max_snooze_period_seconds: number
}

const RatePoints = ({ min, max, index, onClick }: RatePointProps) => {
  const { data } = useGenericContext(SurveyContext)
  return (
    <Col span={24}>
      <Row justify="start" style={{ margin: '8px 0', gap: '8px' }}>
        {Array.from({ length: max - min + 1 }, (_, i) => i + min).map(
          (item, subIndex) => (
            <Button
              data-cy={`rate-${index}-${subIndex}`}
              key={subIndex}
              onClick={() => onClick(item)}
              type={data[index]?.note === item ? 'primary' : 'default'}
              children={item}
            />
          )
        )}
      </Row>
    </Col>
  )
}

const Description = ({
  onChange
}: {
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
}) => {
  const { t } = useTranslation()
  return (
    <Input.TextArea
      data-cy="nps-textarea"
      onChange={onChange}
      placeholder={t('OPTIONALITEM')}
      rows={4}
    />
  )
}

const FeedbackItem = ({
  opinion,
  index
}: {
  opinion: ISurvey
  index: number
}) => {
  const { setData, data } = useGenericContext(SurveyContext)
  const addNewItem = (label: keyof SurveyResponseType, value: any) => {
    let currentData = data[index] ? data[index] : ({} as SurveyResponseType)
    currentData.uuid = opinion.uuid
    currentData[label as keyof SurveyResponseType] = value
    setData(currentData, index)
  }

  return (
    <Row style={{ width: '100%', marginBottom: '16px' }}>
      <Col span={24}>
        <Typography.Title level={4}>{opinion.name}</Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Paragraph>{opinion.description}</Typography.Paragraph>
      </Col>
      <RatePoints
        {...{
          index,
          max: opinion.note_max,
          min: opinion.note_min,
          onClick: (value: number) => addNewItem('note', value)
        }}
      />
      {opinion.allow_comment ? (
        <Description
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            addNewItem('comment', event.target?.value)
          }}
        />
      ) : null}
    </Row>
  )
}

const FeedbackFooter = () => {
  const { sendFeedback, snooze } = useGenericContext(SurveyContext)
  const { t } = useTranslation()
  return (
    <Row justify="space-between">
      <Button danger type="primary" onClick={snooze} data-cy="nps-snooze">
        {t('ANSWERLATER')}
      </Button>
      <Button type="primary" onClick={sendFeedback} data-cy="nps-send">
        {t('CONFIRM')}
      </Button>
    </Row>
  )
}
interface UseFeedbackProps {
  nps: ISurvey[]
  onClose: any
}
const useFeedback = ({ nps, onClose }: UseFeedbackProps) => {
  const [data, setData] = useState<SurveyResponseType[]>([])
  const [error, setError] = useState(false)

  const addNewResponse = (obj: SurveyResponseType, index: number) => {
    let newData = [...data]
    newData[index] = obj
    setData(newData)
  }

  const sendFeedback = async () => {
    if (
      data.length === nps.length &&
      data.every(item => item && item.hasOwnProperty('note'))
    ) {
      const feedbacks = data.map(
        async ({ uuid, ...survey }) =>
          await api.post(`/nps/${uuid}/answer`, survey)
      )
      return Promise.all(feedbacks).then(() => onClose())
    }
    setError(true)
  }

  const snooze = () => {
    Promise.all(
      nps.map(async ({ uuid }) => await api.post(`/nps/${uuid}/snooze`))
    ).finally(() => onClose())
  }
  return { data, setData: addNewResponse, sendFeedback, snooze, error }
}

const Feedback = ({ nps, onCancel, ...props }: FeedbackProps) => {
  const { error, ...feedbackItems } = useFeedback({ nps, onClose: onCancel })
  const { t } = useTranslation()
  return (
    <SurveyContext.Provider value={{ ...feedbackItems, error }}>
      <Modal
        {...props}
        title={t('SATISFACTIONSURVEY')}
        width={'60%'}
        footer={FeedbackFooter}
      >
        <Row style={{ width: '100%' }} gutter={[8, 8]}>
          <Col span={24}>
            {nps.map((opinion, key) => (
              <FeedbackItem {...{ opinion, key, index: key }} />
            ))}
          </Col>
          {error ? (
            <Typography.Text type="danger">
              {t('ALLNOTESISREQUIRED')}
            </Typography.Text>
          ) : null}
        </Row>
      </Modal>
    </SurveyContext.Provider>
  )
}

export default Feedback
