import {
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons'
import { Button, Row, Col, Card, Form, Input, Space, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import { SearchSelectWithForm } from '@common/SearchSelectWithForm'
import { FormInstance } from 'antd'
import { FormListFieldData } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FormListOperation } from 'antd'
import { ButtonProps } from 'antd'
import useSearchSelect from '@hooks/useSearchSelect'

interface AddItemButtonProps extends ButtonProps {
  add: FormListOperation['add']
}

interface TaskItemFormProps {
  subFields: FormListFieldData[]
  subOpt: FormListOperation
}

interface StepItemFormProps {
  field: FormListFieldData
  move: FormListOperation['move']
  remove: FormListOperation['remove']
  index: number
}

const checkOptions = (options: DefaultOptionType[], form: FormInstance) => {
  const values = form.getFieldsValue()
  const selected = values.steps.filter(Boolean).map(e => e.sector)
  if (values.steps?.filter(Boolean).length) {
    return options.filter(el => !selected.includes(el.value))
  }
  return options
}

const SectorsSelect = ({ ...props }) => {
  const form = Form.useFormInstance()

  const querySectors = async (e?: string) => {
    return await getGenericListsandFilter(`sector?search=${e}`).then(e =>
      checkOptions(e, form)
    )
  }
  const { options, loading, onSearch, onFocus } = useSearchSelect<
    DefaultOptionType[],
    DefaultOptionType[]
  >({
    initialValue: [],
    func: querySectors
  })

  return (
    <SearchSelectWithForm
      {...props}
      showSearch
      label="SECTOR"
      {...{ loading, options, onSearch, onFocus }}
    />
  )
}

const AddItemButton = ({ add, ...props }: AddItemButtonProps) => {
  return (
    <Col span={24}>
      <Button
        data-cy="addStep"
        type="dashed"
        onClick={() => add()}
        block
        {...props}
      />
    </Col>
  )
}

const StepItemForm = ({ field, move, remove, index }: StepItemFormProps) => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 12 }}>
      <Card
        size="small"
        title={`Item ${field.name + 1}`}
        key={field.key}
        extra={
          <Space>
            <CaretUpOutlined onClick={() => move(index, index - 1)} />
            <CaretDownOutlined onClick={() => move(index, index + 1)} />
            <CloseOutlined
              onClick={() => {
                remove(field.name)
              }}
            />
          </Space>
        }
      >
        <SectorsSelect name={[field.name, 'sector']} {...{ field }} />
        <Form.Item label={t('STEPS')}>
          <Form.List name={[field.name, 'tasks']}>
            {(subFields, subOpt) => <TaskItemForm {...{ subFields, subOpt }} />}
          </Form.List>
        </Form.Item>
      </Card>
    </Col>
  )
}
const TaskItemForm = ({ subFields, subOpt }: TaskItemFormProps) => {
  const { t } = useTranslation()
  return (
    <Flex vertical gap="8px">
      {subFields.map((subField: FormListFieldData, subIndex: number) => (
        <Flex gap="4px" key={subIndex}>
          <Form.Item
            noStyle
            name={[subField.name, 'task']}
            rules={[{ required: true, message: t('requiredItem') }]}
          >
            <Input placeholder={t('INSERTONETASK')} />
          </Form.Item>
          <CaretUpOutlined
            onClick={() => subOpt.move(subIndex, subIndex - 1)}
          />
          <CaretDownOutlined
            onClick={() => subOpt.move(subIndex, subIndex + 1)}
          />
          <CloseOutlined
            onClick={() => {
              subOpt.remove(subField.name)
            }}
          />
        </Flex>
      ))}
      <AddItemButton {...{ add: subOpt.add }} block>
        + Add Sub Item
      </AddItemButton>
    </Flex>
  )
}

const EditService = () => {
  return (
    <Row style={{ width: '100%' }} gutter={[16, 16]}>
      <Form.List name="steps">
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <StepItemForm {...{ field, move, remove, index }} key={index} />
            ))}
            <AddItemButton {...{ add }}>+ Add Item</AddItemButton>
          </>
        )}
      </Form.List>
    </Row>
  )
}

export default EditService
