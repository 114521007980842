import { Col, Space, Tag, Collapse as AntCollapse, Flex } from 'antd'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { useTranslation } from 'react-i18next'
import { IDeviceHardware, IInterface } from '@/types/IDeviceHardware'
import { Text } from '@components/common/Text'
import CustomDescriptions from '@components/common/CustomDescriptions'
import { useDeviceHardwareContext } from '../HardwareContent'
import { IDisk } from '@/types/IDeviceHardware'
import { IPartition } from '@/types/IDeviceHardware'

const OverviewHardware = () => {
  const { data } = useDeviceHardwareContext()
  return (
    <Col span={24}>
      <CustomDescriptions
        columns={overviewColumnHardware}
        column={{ xs: 1, sm: 2, md: 2, lg: 6, xl: 6, xxl: 6 }}
        dataSource={data}
      />
    </Col>
  )
}

export const overviewColumnHardware: CustomDescriptionType<IDeviceHardware>[] =
  [
    {
      label: 'CPU',
      key: 'cpu',
      span: { lg: 2, xl: 2, xxl: 2 }
    },
    {
      label: 'MEMORY',
      key: 'mem',
      span: { lg: 2, xl: 2, xxl: 2 }
    },
    {
      label: 'MODEL',
      key: 'model',
      span: { lg: 2, xl: 2, xxl: 2 }
    },
    {
      label: 'BRAND',
      key: 'brand',
      span: { lg: 2, xl: 2, xxl: 2 }
    },
    {
      label: 'INTERSERIAL',
      key: 'interserial',
      span: { lg: 2, xl: 2, xxl: 2 }
    },
    {
      label: 'SERIALNUMBER',
      key: 'serialnumber'
    },
    {
      label: 'SERVICE_TAG',
      key: 'servicetag',
      span: { sm: 2, md: 2 }
    },
    {
      label: 'DISKS',
      key: 'disks',
      render: ({ data }) => <>{data && <CollapseDisk data={data} />}</>,
      span: { sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }
    },
    {
      label: 'PARTITIONS',
      key: 'partitions',
      span: { sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 },
      render: ({ data }) => <>{data && <CollapsePartition data={data} />}</>
    },
    {
      label: 'Pool',
      key: 'pool',
      span: { sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 },
      render: ({ data }) => (
        <>
          {data
            ? data.map((pool: IDeviceHardware['pool']) => (
                <Tag color="gold-inverse">{pool}</Tag>
              ))
            : null}
        </>
      )
    },
    {
      label: 'INTERFACES',
      key: 'interfaces',
      span: { sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 },
      render: ({ data }) => (
        <Flex wrap="wrap" gap={8}>
          {data
            ? data.map((item: IInterface, index: number) => (
                <Tag
                  data-cy="hardware-interfaces"
                  key={index}
                  color="gray-inverse"
                >
                  <Interfaces interfaces={item} />
                </Tag>
              ))
            : null}
        </Flex>
      )
    }
  ]

const CollapseDisk = ({ data }: { data: IDisk[] }) => {
  const { t } = useTranslation()
  const items = data.map((item, index) => ({
    key: index.toString(),
    label: item.group_id,
    children: (
      <Flex wrap="wrap" vertical>
        <Text>{`${t('DISC_ID')}: ${item.group_id}`}</Text>
        <Text>{`${t('TYPE')}: ${item.type}`} </Text>
        <Text>{`${t('MODEL')}: ${item.model}`} </Text>
        <Text>{`${t('CAPACITY')}: ${item.size + ' GB'}`}</Text>
      </Flex>
    )
  }))
  return <AntCollapse items={items} />
}

const CollapsePartition = ({ data }: { data: IPartition[] }) => {
  const { t } = useTranslation()
  const items = data.map(item => ({
    key: item.uuid,
    label: item.name,
    children: (
      <Flex wrap="wrap" vertical>
        <Text>{`Uuid: ${item.uuid}`}</Text>
        <Text>{`${t('NAME')}: ${item.name}`}</Text>
        <Text>{`${t('Naa')}: ${item.naa}`}</Text>
        <Text>{`${t('CAPACITY')}: ${
          Math.floor(item.capacity / 1e9) + ' GB'
        }`}</Text>
      </Flex>
    )
  }))
  return <AntCollapse items={items} />
}

const Interfaces = ({ interfaces }: { interfaces: IInterface }) => {
  const { t } = useTranslation()
  return (
    <Flex wrap="wrap">
      <Space direction="vertical">
        <Tag color="geekblue-inverse">
          {`${t('TYPE')}: ${interfaces.type}`}{' '}
        </Tag>
        <Tag color="purple-inverse">{`Mac: ${interfaces.mac}`}</Tag>
        {interfaces.ips &&
          interfaces.ips.map((ip, index) => (
            <Tag key={index} color="green-inverse">{`Ip: ${ip}`}</Tag>
          ))}
      </Space>
    </Flex>
  )
}

export default OverviewHardware
