import { useUser } from '@contexts/UserContext'
import { AxiosResponse } from 'axios'
import { api } from '@helpers/api'
import { Button, Col, Collapse, Form, Input, Row, Typography } from 'antd'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SplitText from '@components/common/SplitText'

const { Paragraph, Text } = Typography

interface IDeviceSecrets {
  manager?: Array<{
    user: string
    password: string
  }>
  support?: Array<{
    user: string
    password: string
  }>
}
function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function b642ab(base64: string) {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

async function importKey(key: string) {
  let keyData = str2ab(key)
  let cryptokey = await crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'AES-CBC', length: 128 },
    true,
    ['decrypt', 'encrypt']
  )
  return cryptokey
}

const decrypt = async (key: string, iv: string, message: string) => {
  let criptoKey = await importKey(key)
  let ivKey = new Uint8Array(new TextEncoder().encode(iv)).buffer
  let cypherText = b642ab(message)

  let decrypted = await crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: ivKey },
    criptoKey,
    cypherText
  )
  return new TextDecoder().decode(decrypted)
}

const getSecrets = async (
  uuid: string,
  setData: Dispatch<SetStateAction<IDeviceSecrets | undefined>>,
  setError: Dispatch<SetStateAction<AxiosError | undefined>>
) => {
  try {
    const tokens = await api.post('/masterpwd/secrettoken')
    const message = await api.post(`device/${uuid}/secrets`)
    const secret = await decrypt(tokens.data.key, tokens.data.iv, message?.data)
    setData(JSON.parse(secret).secret)
    setError(undefined)
  } catch (error) {
    setError(error)
  }
}

const useDeviceSecrets = (uuid: string) => {
  const [hasMaster, setHasMaster] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<AxiosError | undefined>(undefined)
  const [data, setData] = useState<IDeviceSecrets | undefined>(undefined)

  const fetchSecrets = async (func: Promise<AxiosResponse<any, any>>) => {
    func
      .then(() => {
        setHasMaster(true)
        getSecrets(uuid, setData, setError)
      })
      .catch(e => setError(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchSecrets(api.get('/masterpwd/check/masterpwd'))
  }, [uuid])

  const checkPassword = (values: any) => {
    setLoading(true)
    fetchSecrets(api.post('masterpwd/masterpwd', values))
  }

  return { hasMaster, checkPassword, data, loading, error }
}

const DeviceSecrets = ({ uuid }: { uuid: string }) => {
  const { user } = useUser()
  return user.level > 1 ? <InternalDeviceSecrets uuid={uuid} /> : null
}

const InternalDeviceSecrets = ({ uuid }: { uuid: string }) => {
  const { hasMaster, checkPassword, data, loading, error } =
    useDeviceSecrets(uuid)
  const { t } = useTranslation()
  return (
    <Form onFinish={checkPassword} disabled={loading}>
      {!hasMaster && !data ? (
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} xl={{ span: 20 }}>
            <Form.Item name="masterpwd">
              <Input placeholder={t('INSERTTHEMASTERKEY')} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 4 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              data-cy="submit-device-secret"
            >
              {t('CONFIRM')}
            </Button>
          </Col>
          {error?.response?.data?.hasOwnProperty('message') ? (
            <Text type="danger">{error?.response?.data.message as any}</Text>
          ) : null}
        </Row>
      ) : (
        <SecretData data={data as IDeviceSecrets} />
      )}
    </Form>
  )
}

const CollapseItem = ({ access }: { access: any }) => {
  const { t } = useTranslation()
  return (
    <Row gutter={[8, 8]}>
      {typeof access === 'object'
        ? Object.keys(access).map(key => (
            <Col span={24}>
              {t(key.toUpperCase())}:
              <Paragraph copyable>{access[key]}</Paragraph>
            </Col>
          ))
        : access}
    </Row>
  )
}

const getItems = (data: IDeviceSecrets | undefined, t: TFunction) => {
  if (data) {
    let keys = Object.keys(data)
    return keys.map(key => {
      let item = data[key as keyof typeof data]
      return {
        key: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        children: Array.isArray(item) ? (
          item.map((access: any) => <CollapseItem access={access} />)
        ) : (
          <SplitText>{item ? item : null}</SplitText>
        )
      }
    })
  }
}

const SecretData = ({ data }: { data: IDeviceSecrets }) => {
  const { t } = useTranslation()
  const items = data && getItems(data, t)
  return (
    <>
      {data && typeof data === 'object' ? (
        <Collapse
          items={items}
          collapsible="header"
          defaultActiveKey={Object.keys(data)}
        />
      ) : null}
    </>
  )
}

export default DeviceSecrets
