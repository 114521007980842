import { DnsContext } from '@contexts/DnsContext/index.tsx'
import { ModalContext } from '@contexts/ModalContext'
import useModal from '@hooks/useModal'
import { Button, ButtonProps } from 'antd'
import { ComponentType, MouseEventHandler, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import CreateDns from '../CreationForm.tsx'
import { ZoneContext } from '@contexts/ZoneContext/index.tsx'
import Modal from '@components/common/Modal/index.tsx'
import EditDns from '../EditionForm/index.tsx'
import { ZoneRecord } from '@/types/IDns.ts'
import ModalFooter from '@components/common/ModalFooter/index.tsx'

interface ModalDnsProps extends Omit<ButtonProps, 'type'> {
  type?: string
  action: 'create' | 'edit'
  hostname?: string | number
  zone_record?: ZoneRecord
}

interface IDnsOptions {
  component: ComponentType<{
    id: string
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  }>
  call: string
}

const options = (
  record: string | undefined,
  zoneName: string
): Record<string, IDnsOptions> => {
  const { t } = useTranslation()
  return {
    create: {
      component: CreateDns,
      call: `${t('CREATERECORDAT')} ${zoneName}`
    },
    edit: {
      component: EditDns,
      call: `${t('UPDATERECORD')} #${record}`
    }
  }
}

const CustomButton = ({ ...props }) => {
  const Tag = Button
  return <Tag {...props} />
}

const ModalDns = ({ zone_record, action, ...props }: ModalDnsProps) => {
  const { open, onCancel, onOpen } = useModal()
  const { zoneName } = useContext(ZoneContext)
  const numForm = Math.floor(Math.random() * 10000).toString()
  const option = options(zone_record?.hostname, zoneName)
  const [disabled, setDisabled] = useState(false)
  const [zoneRecord, setZoneRecord] = useState<ZoneRecord>(
    zone_record || ({} as ZoneRecord)
  )

  const Component = option[action as keyof typeof options].component

  return (
    <ModalContext.Provider value={{ open, onCancel, onOpen }}>
      <DnsContext.Provider value={{ zoneRecord, setZoneRecord, action }}>
        <Modal
          title={option[action as keyof typeof options].call}
          open={open}
          footer={
            <ModalFooter onClose={onCancel} action={action} form={numForm} />
          }
          onCancel={onCancel}
          centered
          width="25%"
        >
          <Component id={numForm} setDisabled={setDisabled} />
        </Modal>
        <CustomButton
          {...props}
          data-cy={`modal-dns-${action}`}
          onClick={onOpen}
        />
      </DnsContext.Provider>
    </ModalContext.Provider>
  )
}

export default ModalDns
