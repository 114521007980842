import { IGroup, IColumn } from '@types/IGroups.d'
import { api } from '../api'

export const addGroupColumns = async (columns: any) => {
  const result: IGroup = await api.get('/group?perPage=999')
  if (result.status === 200) {
    const groups = formatGroup(result.data.data)

    groups.forEach(group => {
      if (!columns.some(col => col.id === group.id)) {
        columns.push(group)
      }
    })
  }
}

const formatGroup = (group: IGroup[]) => {
  const groups = group.map(groupItem => ({
    id: groupItem.uuid,
    title: groupItem.name,
    items: []
  }))
  return groups
}

export const submitNewGroup = async (
  values: { name: string },
  setColumns: any,
  columns: IColumn[]
) => {
  const result = await fetchAddGroup(values)
  if (result != null) {
    const groups = formatGroup([result.data])
    setColumns([...columns, groups[0]])
  }
}

const fetchAddGroup = async (values: { name: string }) => {
  if (values.name) {
    return await api.post('/group', values)
  }
}
