import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useCustomContext } from '@contexts/StyleContext'
import { columns } from '@components/Ticket/Table'
import OverviewCounter from '@components/Ticket/OverviewCounter'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { api } from '@helpers/api'
import { useTheme } from 'styled-components'
import type { ITicket } from '@/types/ITicket'
import { translateColumns } from '@helpers/translateColumns'
import './style.css'
import { Layout, Row, Col, Table as AntTable, Typography } from 'antd'

const { Content } = Layout
const customConfig = {
  token: {
    fontSize: 24
  },
  components: {
    Table: {
      cellFontSize: 24
    }
  }
}

const updateTime = (
  setSpTime: Dispatch<SetStateAction<string>>,
  setMiaTime: Dispatch<SetStateAction<string>>,
  setUtcTime: Dispatch<SetStateAction<string>>
) => {
  const date = new Date()
  setSpTime(date.toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' }))
  setMiaTime(date.toLocaleTimeString('pt-BR', { timeZone: 'America/New_York' }))
  setUtcTime(date.toUTCString().split(' ')[4])
}
const useOverviewTime = () => {
  const [spTime, setSpTime] = useState('')
  const [miaTime, setMiaTime] = useState('')
  const [utcTime, setUtcTime] = useState('')

  useEffect(() => {
    const update = setInterval(() => {
      updateTime(setSpTime, setMiaTime, setUtcTime)
    }, 1000)
    return () => {
      clearInterval(update)
    }
  }, [])
  return { spTime, miaTime, utcTime }
}

const OverviewTime = () => {
  const { spTime, miaTime, utcTime } = useOverviewTime()

  return (
    <Typography.Text
      data-cy="time-speed"
      style={{ color: '#fff', fontSize: 30 }}
    >
      {spTime && miaTime && utcTime
        ? `
        GYN ${spTime} |
          MIA ${miaTime} |
          UTC ${utcTime}`
        : null}
    </Typography.Text>
  )
}

const OverviewImage = ({ error }: { error?: AxiosError }) => {
  return (
    <Row>
      <img src="/assets/images/logo-only-white.png" style={{ width: 70 }} />
      {!error ? (
        <Row align={'middle'}>
          <div className="overview-tickets-pulse"> </div>
          <Typography.Text style={{ color: 'white', fontWeight: 'bold' }}>
            ONLINE
          </Typography.Text>
        </Row>
      ) : (
        <Row align={'middle'}>
          <div className="overview-tickets-pulse-off"></div>
          <Typography.Text style={{ color: 'white', fontWeight: 'bold' }}>
            OFFLINE
          </Typography.Text>
        </Row>
      )}
    </Row>
  )
}

const OverviewHeader = ({ error }: { error?: AxiosError }) => {
  return (
    <Row
      justify={'space-between'}
      align="middle"
      style={{
        margin: 0,
        height: '100px',
        padding: '0 10px',
        background: '#017BFE'
      }}
    >
      <OverviewImage error={error} />
      <OverviewCounter />
      <OverviewTime />
    </Row>
  )
}

const OverviewTable = ({ data }: { data: { data: ITicket[] } }) => {
  const { t } = useTranslation()
  return (
    <AntTable
      bordered
      size="small"
      columns={translateColumns(columns.slice(1), t)}
      dataSource={data?.data}
    />
  )
}

const useOverviewTickets = () => {
  const { search } = useLocation()
  const [data, setData] = useState<ITicket[]>()
  const [error, setError] = useState<AxiosError | undefined>()

  const getTickets = async (search: string) => {
    await api
      .get(`/ticket${search}`)
      .then((e: AxiosResponse<ITicket[]>) => {
        setData(e.data)
        setError(undefined)
      })
      .catch(e => setError(e))
      .finally(() => setTimeout(() => getTickets(search), 5000))
  }

  useEffect(() => {
    getTickets(search)
  }, [search])

  return { data, error }
}

const OverviewTickets = () => {
  const { setCustomProvider } = useCustomContext()
  const { data, error } = useOverviewTickets()
  useEffect(() => setCustomProvider(customConfig), [])
  const theme = useTheme()
  return (
    <Content style={{ backgroundColor: theme.background, height: '100vh' }}>
      <Row>
        <Col span={24}>
          <OverviewHeader error={error} />
        </Col>
        <Col span={24}>{data ? <OverviewTable data={data} /> : null}</Col>
      </Row>
    </Content>
  )
}

export default OverviewTickets
