import { Row, Image, Alert, Col, Flex, Spin, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { DeviceHardwareContextType } from '@/types/IDeviceHardware'
import { DeviceHardwareContext } from '@contexts/DeviceHardwareContext'
import useFetch from '@hooks/useFetch'
import { api } from '@helpers/api'
const useDeviceHardwareContext = () =>
  useGenericContext<DeviceHardwareContextType>(DeviceHardwareContext)

interface IPhoto {
  front_image: string
  rear_image: string
}

const PhotoHardware = () => {
  const { t } = useTranslation()
  const { uuid } = useDeviceHardwareContext()
  const { data, error, loading } = useFetch<string, undefined>({
    func: () => api.get(`/device/${uuid}/photo`),
    initialValue: undefined,
    params: undefined
  })

  return (
    <Col span={24}>
      <Divider>{t('HW_PHOTO')}</Divider>
      {loading && data.length === 0 ? <LoaderPhoto /> : null}
      {!loading && data && !error ? <ImageHardware data={data} /> : null}
      {!loading && error ? (
        <Alert
          data-cy="spec-alert"
          showIcon
          message={t('NO_REGISTRY')}
          style={{ width: '100%' }}
          type="warning"
        />
      ) : null}
    </Col>
  )
}

const LoaderPhoto = () => (
  <Flex justify="center">
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  </Flex>
)

const ImageHardware = ({ data }: { data: IPhoto }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
        <Image
          data-cy="front-image"
          src={`data:image/jpeg;base64,${data.front_image}`}
          alt="front_image"
        />
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
        <Image
          src={`data:image/jpeg;base64,${data.rear_image}`}
          alt="binary image"
        />
      </Col>
    </Row>
  )
}

export default PhotoHardware
