import { Select, Tag } from "antd";
import { TICKETSPRIORITIESCOLORS } from "@constants/ticketspriorities";
import useTicketSelect from '@hooks/useTicketSelect';
import FilterSelect from "@components/common/FilterSelect";

type TicketPrioritiesColors = {
  [key: string]: { color: string };
};

const mapObjectToOptions = (obj: Record<string, any>) => {
  return Object.keys(obj).map(key => ({
    value: key,
    label: key
  }));
};

const searchPriorities = async () => {
  return mapObjectToOptions(TICKETSPRIORITIESCOLORS);
};

const PriorityFilterSelect = () => {
  const { options, ...props} = useTicketSelect({
    name: 'priority',
    func: searchPriorities
  });

  return (
    <FilterSelect
      options={options}
      placeholderKey="FILTERBYPRIORITY"
      data-cy="filterPriority"
      optionRenderer={({ value, label }) => (
        <Select.Option key={value} value={value}>
          <Tag color={(TICKETSPRIORITIESCOLORS as TicketPrioritiesColors)[value]?.color}>
            {label}
          </Tag>
        </Select.Option>
      )}
      {...props}
    />
  );
};

export default PriorityFilterSelect;
