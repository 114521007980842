import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '@contexts/UserContext'
import {
  Rate,
  Flex,
  Typography,
  Input,
  Button,
  message,
  Row,
  Col,
  Space
} from 'antd'
import useFetch from '@hooks/useFetch'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import RenderPage from '@common/RenderPage'
import { Helmet } from 'react-helmet-async'

const API_KEY = import.meta.env.VITE_API_URL
interface TicketRating {
  uuid: string
  rate: number | null
  comment: string
  user: {
    name: string
    uuid: string
    email: string
  }
}

type UseRatingResponse = {
  data: TicketRating
  setRated: Dispatch<SetStateAction<boolean>>
}

const textStyles = { color: 'white' }

const useRatingResponse = ({ data, setRated }: UseRatingResponse) => {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const [rating, setRating] = useState({
    rate: data.rate,
    comment: data.comment
  })

  const editRate = (item: 'rate' | 'comment', value: string | number) => {
    setRating(prevRating => ({ ...prevRating, [item]: value }))
  }

  const sendRate = (value: string | number) => {
    editRate('rate', value)
    return axios.put(`${API_KEY}/ticket/rating/${data.uuid}`, rating)
  }

  const finishRate = () => {
    if (rating.rate)
      sendRate(rating.rate)
        .then(() => {
          messageApi.success(t('FEEDBACKSENT'), 1000)
          setRated(true)
        })
        .catch(() => {
          messageApi.error(t('FEEDBACKSENTERROR'), 1000)
        })
    messageApi.error(t('RATINGISREQUIRED'))
  }
  return { editRate, sendRate, rating, finishRate, contextHolder }
}

const RatingForm = ({ data, setRated }: UseRatingResponse) => {
  const { t } = useTranslation()
  const { sendRate, editRate, rating, finishRate, contextHolder } =
    useRatingResponse({ data, setRated })
  return (
    <>
      <Flex>
        {contextHolder}
        <Rate
          value={rating.rate ? rating.rate : undefined}
          style={{ fontSize: '60px', marginBottom: '15px' }}
          onChange={sendRate}
        />
      </Flex>
      <Input.TextArea
        style={{ width: '50%', minWidth: '80vw', marginBottom: 10 }}
        value={rating.comment}
        onChange={e => editRate('comment', e.target.value)}
        autoSize={{ minRows: 4, maxRows: 30 }}
        placeholder={t('ADDOPTIONALCOMMENT')}
      />
      <Button size="large" onClick={finishRate}>
        {t('CONFIRM')}
      </Button>
    </>
  )
}

const Rating = ({ data }: { data: TicketRating }) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography.Text style={{ fontSize: '22px', color: 'white' }}>
        {`${data.user.name}, ${t('YOUROPINIONISIMPORTANTFORUS')}!`}
      </Typography.Text>
      <Typography.Paragraph style={textStyles}>
        {t('WHATYOUTHINKABOUTTHESUPPORTANDRESOLUTIONINTHISTICKET?')}
      </Typography.Paragraph>
      <Flex style={{ margin: '30px 0 10px' }}>
        <Typography.Text style={textStyles}>
          {' '}
          {t('CHOOSETO1FROM5FORRATE')}{' '}
        </Typography.Text>
      </Flex>
    </>
  )
}

const FinishRating = () => {
  const { t } = useTranslation()
  return (
    <Typography.Text style={{ fontSize: '22px', color: 'white' }}>
      {t('THANKYOUFORYOUROPINION')}
    </Typography.Text>
  )
}

const ticketRating = (uuid: string | undefined) => {
  return {
    func: async () => await axios.get(`${API_KEY}/ticket/rating/${uuid}`),
    initialValue: undefined,
    params: undefined
  }
}

const useTicketRating = () => {
  const { uuid } = useParams()
  const { setIsThemeDark } = useUser()
  const [rated, setRated] = useState(false)
  useEffect(() => {
    setIsThemeDark(false)
  }, [uuid])

  const { data, loading, error } = useFetch<TicketRating, undefined>(
    ticketRating(uuid)
  )

  return { data, loading, error, rated, setRated }
}

const TicketRating = () => {
  const { t } = useTranslation()
  const { rated, ...rating } = useTicketRating()
  return (
    <RenderPage {...rating}>
      <Helmet>
        <title>{t('TICKETRATING')}</title>
      </Helmet>
      <Flex
        style={{ padding: 10, height: '100vh', backgroundColor: '#2277ae' }}
        align="center"
        vertical
        justify="center"
      >
        <img src="/assets/images/logo-only-white.png" style={{ width: 120 }} />
        {!rated ? (
          <>
            <Rating data={rating.data} />
            <RatingForm data={rating.data} setRated={rating.setRated} />
          </>
        ) : (
          <FinishRating />
        )}
      </Flex>
    </RenderPage>
  )
}

export default TicketRating
