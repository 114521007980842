import useModal from '@hooks/useModal'
import Modal from '@components/common/Modal'
import { Button, ButtonProps, Form, Row } from 'antd'
import { ISector } from '@types/ISector'
import InputWithForm from '@components/common/InputWithForm'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import useTableContext from '@hooks/useTableContext'
interface SectorModalProps extends ButtonProps {
  sector?: ISector
}

const ModalContent = () => {
  const { t } = useTranslation()
  return (
    <Row>
      <InputWithForm required name="name" label={t('NAME')} />
      <InputWithForm required name="slug" label={t('SLUG')} />
    </Row>
  )
}

const onRequestSector = async (
  isEdit: boolean,
  values: ISector,
  uuid: string | undefined
) => {
  if (isEdit) return await api.put(`/sector/${uuid}`, values)
  return await api.post('/sector', values)
}

const useSectorModal = (
  onCancel: () => void,
  isEdit: boolean,
  uuid: string | undefined
) => {
  const { t } = useTranslation()
  const messageApi = useGenericContext(MessageContext)
  const { update } = useTableContext()
  const errorMessage = t(isEdit ? 'EDITSECTORERROR' : 'CREATESECTORERROR')
  const onFinish = async (values: any) => {
    await onRequestSector(isEdit, values, uuid)
      .then(e => {
        update(e.data.uuid, e.data)
        messageApi.success(
          `${e.data.name} ${t(!isEdit ? 'CREATED' : 'UPDATED')}`
        )
        onCancel()
      })
      .catch(err => handleError(err, messageApi, errorMessage))
  }
  return { onFinish }
}

const ConfirmButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      htmlType="submit"
      type="primary"
      {...props}
      data-cy="submit-sector-modal"
    >
      {t('CONFIRM')}
    </Button>
  )
}

const SectorModal = ({ sector, ...props }: SectorModalProps) => {
  const { open, onOpen, onCancel } = useModal()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { onFinish } = useSectorModal(onCancel, !!sector, sector?.uuid)
  return (
    <Form initialValues={sector} onFinish={onFinish} form={form}>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={[<ConfirmButton onClick={() => form.submit()} />]}
        title={`${sector ? t('EDIT') : t('CREATE')} ${t('SECTOR')}`}
      >
        <ModalContent />
      </Modal>
      <Button onClick={onOpen} {...props} />
    </Form>
  )
}
export default SectorModal
