import { useState, useEffect } from 'react'
import {
  Form,
  FormInstance,
  Row,
  Col,
  Button,
  Card,
  Radio,
  Input,
  InputNumber
} from 'antd'
import useForm from '@hooks/useForm'
import type { IOrder, IItemOrderRequest } from '@/types/IOrder'
import { SearchSelect } from '@components/User/Form'
import { CloseOutlined } from '@ant-design/icons'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import FormItemsOrderRequest from '../FormItemsOrderRequest'
import { useGenericContext } from '@hooks/useGenericContext'
import { OrderContext } from '@contexts/OrderContext'
import FormUser from '@components/Company/FormUser'
import SelectDate from '../SelectDate'
import { removeNullValues } from '@helpers/removeNullValues'
import useTableContext from '@hooks/useTableContext'
import { FormOrderProps, SearchSelectCRMProps } from '@/types/IOrder'
import dayjs from 'dayjs'

const initialValues = (data: Partial<IOrder> | undefined) => {
  if (data) {
    return {
      ...data,
      customer: data?.customer?.uuid,
      opportunity: data?.opportunity?.uuid,
      expire_at: dayjs(data?.expire_at).format('YYYY-MM-DD')
    }
  }
}

const handleFinish = (values: IOrder, onFinish: (object: any) => void) => {
  const formattedValues = {
    ...removeNullValues(values),
    items: values.items.map(item => ({
      product: { uuid: item.product.uuid, device: item.product?.device },
      order_request: removeNullValues(
        item.order_request.items.map(formatOrderItem)
      )
    }))
  }

  onFinish(formattedValues)
}

const formatOrderItem = (orderItem: IItemOrderRequest) => {
  const orderItems = {
    uuid: orderItem.item.uuid,
    children: orderItem.children?.items?.map(item => ({
      uuid: item.item.uuid,
      current: item.current ?? orderItem.current
    }))
  }

  if (orderItem.item.type === 'BOOL') {
    if (orderItem.is_used === true)
      return { ...orderItems, current: 1, is_used: true }
    return
  }

  return {
    ...orderItems,
    current:
      orderItem.children?.items?.length > 0
        ? orderItem.children.items.length
        : orderItem.current
  }
}

const FormOrder = ({ data }: FormOrderProps) => {
  const { refreshTable } = useTableContext()
  const [form] = Form.useForm()
  const { action, numForm, setData, onClose } = useGenericContext(OrderContext)
  const { t } = useTranslation()
  const { onFinish, disabled, contextHolder, responseData } = useForm(
    action,
    onClose,
    '/order',
    data?.uuid
  )

  useEffect(() => {
    if (responseData?.uuid) {
      refreshTable('/order?sort=-created_at')
    }
  }, [responseData])

  return (
    <Form
      {...{ form, disabled }}
      layout="vertical"
      initialValues={initialValues(data)}
      onFinish={values => handleFinish(values, onFinish)}
      id={numForm}
      onValuesChange={(_, allValues) =>
        setData(prev => ({ ...prev, ...allValues }))
      }
    >
      {contextHolder}
      <Row gutter={[16, 16]}>
        <SearchSelectCRM data={data} name="customer" />
        <SearchSelectCRM data={data} name="opportunity" />
        <Col lg={8} xs={24}>
          <SelectDate data={data} />
        </Col>
        <InputPercent />
        <Col span={24}>
          <FormUser
            action={action}
            data={data?.contract_signatures}
            nameForm="contract_signatures"
            title="SIGNATURE"
          />
        </Col>
        <Col span={24}>
          <span>{t('PRODUCTS')}</span>
          <FormItems form={form} />
        </Col>
      </Row>
    </Form>
  )
}

const SearchSelectCRM = ({ data, name }: SearchSelectCRMProps) => {
  return (
    <Col lg={8} xs={24}>
      <SearchSelect
        initialValue={
          data?.customer
            ? [{ value: data[name]?.uuid, label: data[name]?.name }]
            : []
        }
        require={true}
        name={name.toUpperCase()}
        searchUrl={`crm/${name}?`}
      />
    </Col>
  )
}

const FormItems = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation()
  const { data } = useGenericContext(OrderContext)
  const [dcName, setDcName] = useState(data?.items)
  const onChange = async (value: string, key: number) => {
    const result = await api.get(`/product/${value}`)
    const { items, ...product } = result.data

    const values = form.getFieldValue('items')
    values[key] = {
      order_request: {
        items: result.data.items.map(item => ({
          item: item,
          current: item.min
        }))
      },
      product: product
    }
    form.setFieldValue('items', values)
    setDcName(values)
  }
  return (
    <Form.List name="items">
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
            {fields.map(({ key, name }) => {
              return (
                <Col xs={24} xl={12} key={key}>
                  <Card
                    style={{ width: '100%' }}
                    type="inner"
                    key={name}
                    size="small"
                    title={`${t('PRODUCT')} ${name + 1}`}
                    extra={<CloseOutlined onClick={() => remove(name)} />}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={18} xl={18} xs={24}>
                        <SearchSelect
                          require={true}
                          name={[name, 'product', 'uuid']}
                          searchUrl="product?"
                          placeholder={t('PRODUCT')}
                          onChange={value => onChange(value, name)}
                          data-cy={`select-product-${name}`}
                        />
                      </Col>
                      {dcName && dcName[name] ? (
                        <>
                          <Col xl={{ span: 4, offset: 2 }} xs={24}>
                            <Form.Item
                              name={[name, 'datacenter']}
                              label={'Datacenter'}
                            >
                              <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                              >
                                <Radio>
                                  {`${dcName[name]?.product?.dc?.shortname}`}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>

                          <Col span={18} xl={18} xs={24}>
                            {dcName[name]?.product?.type === 'BAREMETAL' && (
                              <SearchSelect
                                require={true}
                                name={[name, 'product', 'device']}
                                searchUrl={`device?pool=${dcName[name]?.product?.dc?.uuid}&`}
                                placeholder={t('HOST')}
                              />
                            )}
                          </Col>
                        </>
                      ) : null}
                      <Col span={24}>
                        <FormItemsOrderRequest name={name} form={form} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <Form.Item data-cy="add-items">
            <Button block type="primary" onClick={() => add()}>
              {t('ADD_PRODUCT')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

const InputPercent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={24} lg={4}>
        <Form.Item name="discount_percents" label={t('DISCOUNT')}>
          <InputNumber addonAfter="%" min={0} max={100} />
        </Form.Item>
      </Col>
      <Col xs={24} lg={20}>
        <Form.Item name="discount_motive" label={t('DISCOUNT_MOTIVE')}>
          <Input.TextArea rows={2} autoSize={{ minRows: 2, maxRows: 4 }} />
        </Form.Item>
      </Col>
    </>
  )
}
export default FormOrder
