import Title from '@common/Title'
import { Card, Col, Row, Typography } from 'antd'
import { useTheme } from 'styled-components'
import Button from '@common/Button'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Icon from '@common/Icon'

interface IRenderContacts {
  title: string
  url: string
  name: string
  icon: string
}

const RenderContacts = ({
  url,
  name,
  icon
}: Pick<IRenderContacts, 'url' | 'name' | 'icon'>) => {
  return (
    <p>
      <Link to={url}>
        <Button
          data-cy={`button-action-${url}`}
          style={{ color: 'white' }}
          icon={<Icon name={icon} color="white" />}
        />
      </Link>
      <Typography.Text style={{ marginLeft: 10 }}>{name}</Typography.Text>
    </p>
  )
}

const ContentCard = ({ url, name }: Pick<IRenderContacts, 'url' | 'name'>) => {
  return (
    <>
      <RenderContacts
        url="https://wa.me/+551140031650"
        name="(11) 4003-1650"
        icon="fab fa-whatsapp"
      />
      <RenderContacts url={url} name={name} icon="fal fa-envelope" />
      <RenderContacts
        url="tel:+556240031650"
        name="4003-1650"
        icon="fal fa-phone"
      />
    </>
  )
}

const RenderCards = ({
  title,
  url,
  name
}: Pick<IRenderContacts, 'title' | 'url' | 'name'>) => {
  const theme = useTheme()
  return (
    <Col xs={{ span: 24 }} xl={{ span: 6 }}>
      <Card
        title={title}
        bordered={false}
        styles={{ header: { backgroundColor: theme?.blue, color: 'white' } }}
      >
        <ContentCard url={url} name={name} />
      </Card>
    </Col>
  )
}

const Contacts = () => {
  const { t } = useTranslation()
  const contactData = [
    { title: t('SUPPORT'), email: 'suporte@opendatacenter.com.br' },
    { title: t('SALES'), email: 'comercial@opendatacenter.com.br' },
    { title: t('FINANCE'), email: 'financeiro@opendatacenter.com.br' },
    {
      title: t('CUSTOMERRELATIONSHIP'),
      email: 'atendimento@opendatacenter.com.br'
    }
  ]

  return (
    <>
      <Title name={t('CONTACTS')} />
      <Row gutter={[16, 16]}>
        {contactData.map((contact, index) => (
          <RenderCards
            key={index}
            title={contact.title}
            url={`mailto:${contact.email}`}
            name={contact.email}
          />
        ))}
      </Row>
    </>
  )
}

export default Contacts
