import { Space, message, Popconfirm } from 'antd'
import Button from '@components/common/Button'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import { IStatusProblem } from '@/types/IStatusProblem'
import ModalStatusProblem from '../Modal'

const Actions = ({ data }: { data: IStatusProblem }) => {
  return (
    <Space>
      <ModalStatusProblem
        action="edit"
        icon={<Icon name="fa-light fa-edit" color="white" />}
        data={data}
        type="primary"
      />
      <DeleteProblemButton data={data} />
    </Space>
  )
}

const DeleteProblemButton = ({ data }: { data: IStatusProblem }) => {
  const { t } = useTranslation()
  const deleteStatusProblem = async () => {
    await api
      .delete(`status/problem/${data.uuid}`)
      .then(() => message.success(t('SUCESSDELETEPROBLEM'), 2))
      .catch(() => message.error(t('ERRORDELETEPROBLEM'), 2))
  }
  return (
    <Popconfirm
      title={t('DELETESTATUSPROBLEM')}
      description={t('AREYOUSUREDELETESTATUSPROBLEM')}
      onConfirm={deleteStatusProblem}
    >
      <Button
        data-cy="delete-status-problem"
        danger
        icon={<Icon name="fa-light fa-trash" color="white" />}
      />
    </Popconfirm>
  )
}

export default Actions
