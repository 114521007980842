import { useTranslation } from 'react-i18next';
import { Input, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { GenericSelect } from '@components/GenericSelect';
import TimeRangeInput from '@components/OnBoard/TimeRangeImput';

const createSelect = (name: string, label: string, options: { label: string; value: string }[]) => ({
  name,
  label,
  component: <GenericSelect name={name} options={options} noStyle />,
  rules: [{ required: true }],
})

const createInput = (name: string, label: string, type?: string) => ({
  name,
  label,
  component: <Input type={type} />,
  rules: [{ required: true }],
})

const createTextArea = (name: string, label: string, rows: number) => ({
  name,
  label,
  component: <Input.TextArea rows={rows} />,
  rules: [{ required: true }],
})

const disableDate = (date: dayjs.Dayjs) => dayjs() < date;

const validateTimeDifference = (startTime: dayjs.Dayjs, endTime: dayjs.Dayjs) => {
  const diffInHours = endTime.diff(startTime, 'hour', true);
  return diffInHours >= 3 && endTime.isAfter(startTime);
}

export const getSegmentOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t('FORMONBOARD.TECHNOLOGY'), value: 'Tecnologia' },
    { label: t('FORMONBOARD.HEALTH'), value: 'Saúde' },
    { label: t('FORMONBOARD.EDUCATION'), value: 'Educação' },
    { label: t('FORMONBOARD.FINANCE'), value: 'Finanças' },
    { label: t('FORMONBOARD.RETAIL'), value: 'Varejo' },
    { label: t('FORMONBOARD.LOGISTICS'), value: 'Logística' },
    { label: t('FORMONBOARD.OTHER'), value: 'Outro' },
  ]
}

export const getEmployeeSizeOptions = () => {
  const { t } = useTranslation();
  return [
    { label: `1-10 ${t('FORMONBOARD.EMPLOYEES')}`, value: '1-10' },
    { label: `11-50 ${t('FORMONBOARD.EMPLOYEES')}`, value: '11-50' },
    { label: `51-200 ${t('FORMONBOARD.EMPLOYEES')}`, value: '51-200' },
    { label: `201-500 ${t('FORMONBOARD.EMPLOYEES')}`, value: '201-500' },
    { label: `501-1000 ${t('FORMONBOARD.EMPLOYEES')}`, value: '501-1000' },
    { label: t('FORMONBOARD.MORETHAN1000'), value: '+1000' },
  ]
}
export const getContactPreferences = () => {
  const { t } = useTranslation();
  return [
    { value: 'E-mail', label: t('FORMONBOARD.EMAIL') },
    { value: 'WhatsApp privado', label: t('FORMONBOARD.PRIVATEWHATSAPP') },
    { value: 'Grupo de WhatsApp', label: t('FORMONBOARD.WHATSAPPGROUP') },
    { value: 'Ligação', label: t('FORMONBOARD.PHONECALL') },
  ]
}

export const useFormFields = () => {
  const { t } = useTranslation();

  return [
    {
      name: 'birthday', label: t('FORMONBOARD.BIRTHDAY'),
      component: <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} disabledDate={disableDate} />, rules: [{ required: true }],
    },
    createSelect('cs_contact_preference', t('FORMONBOARD.CONTACT_PREFERENCE'), getContactPreferences()),
    createSelect('cs_segment', t('FORMONBOARD.SEGMENT'), getSegmentOptions()),
    createSelect('cs_employees_size', t('FORMONBOARD.COMPANY_SIZE'), getEmployeeSizeOptions()),
    TimeRangeInput({
      namePrefix: 'cs_working_time', label: t('FORMONBOARD.WORKING_TIME'), startPlaceholder: t('FORMONBOARD.START_TIME'), endPlaceholder: t('FORMONBOARD.END_TIME'), validateTimeDifference,
    }),    
    TimeRangeInput({
      namePrefix: 'cs_backup_time', label: t('FORMONBOARD.BACKUP_TIME'), startPlaceholder: t('FORMONBOARD.BACKUP_START_TIME'), endPlaceholder: t('FORMONBOARD.BACKUP_END_TIME'), validateTimeDifference,
    }),
    TimeRangeInput({
      namePrefix: 'cs_maintenance_time', label: t('FORMONBOARD.MAINTENANCE_TIME'), startPlaceholder: t('FORMONBOARD.MAINTENANCE_START_TIME'), endPlaceholder: t('FORMONBOARD.MAINTENANCE_END_TIME'), validateTimeDifference,
    }),    
    createInput('cs_users_active_usage', t('FORMONBOARD.SIMULTANEOUS_USERS'), 'number'),
    createTextArea('cs_partner_expectations', t('FORMONBOARD.PARTNER_EXPECTATIONS'), 4),
    createTextArea('cs_environment_obs', t('FORMONBOARD.ENVIRONMENT_OBS'), 4),
    createTextArea('cs_environment_usage', t('FORMONBOARD.ENVIRONMENT_USAGE'), 3),
    createTextArea('cs_first_questions', t('FORMONBOARD.FIRST_QUESTIONS'), 4),
  ]
}
