import useTicketSelect from "@hooks/useTicketSelect";
import useQueryItems from "@hooks/useQueryItems";
import { ISector } from "@/types/ISector";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const defaultProps = {
  showSearch: true,
  allowClear: true,
  filterOption: false,
};

const searchSector = async (search = '') => useQueryItems(search, 'sector');

const AssignedSectorsSelect = () => {
  const { t } = useTranslation();
  const { options, ...selectProps } = useTicketSelect({
    name: 'assignedSectors',
    func: searchSector,
  });

  return (
    <Select
      {...defaultProps}
      {...selectProps}
      mode="multiple"
      placeholder={t('sector')}
      style={{ width: '100%' }}
      data-cy="filterSectors"
    >
      {options && Array.isArray(options)
        ? options.map((item: ISector) => (
            <Option key={item.slug} label={item.name} value={item.slug}>
              {item.name}
            </Option>
          ))
        : null}
    </Select>
  );
};

export default AssignedSectorsSelect;
