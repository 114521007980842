import { useState } from 'react'
import { Image, Space, Typography, Skeleton } from 'antd'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { useTheme } from 'styled-components'
import { fallback } from '../../../../public/assets/fallback.js'
import { IAttachment } from '@/types/IFollowup'

const API_URL = import.meta.env.VITE_OPEN_URL
const AttachmentImageAnchor = ({
  href,
  download
}: {
  href: string
  download: string
}) => {
  const theme = useTheme()
  return (
    <a
      data-cy="download-image-followup"
      style={{ color: theme.gray }}
      href={`${API_URL}${href}`}
      download={download}
    >
      <DownloadOutlined style={{ fontSize: '20px' }} />
    </a>
  )
}
const FollowupImage = ({ attachments }: { attachments: IAttachment[] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  let href = attachments[currentImageIndex].path
  let download = attachments[currentImageIndex].name
  return (
    <Image.PreviewGroup
      items={attachments.map(attachment => `${API_URL}${attachment.path}`)}
      preview={{
        toolbarRender: prevGroup => (
          <>
            <Typography.Text
              style={{ textAlign: 'center', marginTop: '8px', color: 'white' }}
            >
              {attachments[currentImageIndex].name}
            </Typography.Text>
            <Space
              style={{
                borderRadius: '50px',
                backgroundColor: 'rgba(0,0,0,0.1)',
                paddingLeft: '24px'
              }}
            >
              <AttachmentImageAnchor {...{ href, download }} />
              {prevGroup}
            </Space>
          </>
        ),
        onChange: current => setCurrentImageIndex(current),
        onVisibleChange: visible => {
          if (!visible) {
            setCurrentImageIndex(0)
          }
        }
      }}
      fallback={fallback}
    >
      <Image
        preview={{
          mask: (
            <>
              <EyeOutlined style={{ marginRight: '4px' }} />
              {`Preview ${
                attachments.length > 1 ? '+' + (attachments.length - 1) : ''
              }`}
            </>
          )
        }}
        width={225}
        height={200}
        data-cy="image-followup"
        src={`${API_URL}${attachments[0].path}`}
        alt={attachments[0].name}
        fallback={fallback}
        style={{ borderRadius: 5 }}
        placeholder={
          <Skeleton.Image active style={{ width: 225, height: 200 }} />
        }
      />
    </Image.PreviewGroup>
  )
}
export default FollowupImage
