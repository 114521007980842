export const ORDER_STATUS = {
  DRAFT: 'blue-inverse',
  APPROVED: 'volcano-inverse',
  SENT: '#FF7310',
  REJECTED: '#edb007',
  DEPLOY: 'lime-inverse',
  PRODUCTION: '#333333',
  WAITING_SUPPORT: '#c6c6c6',
  UPGRADE_REQUESTED: 'blue-inverse',
  UNKNOWN: '#c6c6c6',
  UPGRADE_APPROVED: 'red',
  UPGRADE_SENT: 'red',
  UPGRADE_REJECTED: 'red',
  UPGRADE_DEPLOY: 'red',
  DOWNGRADE_REQUESTED: 'red',
  DOWNGRADE_APPROVED: 'red',
  DOWNGRADE_SENT: 'red',
  DOWNGRADE_REJECTED: 'red',
  DOWNGRADE_DEPLOY: 'red'
}

export const ORDER_TYPES = {
  ORDER: 'green',
  HISTORY: 'gray'
}
