import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
  CloudServerOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  MehOutlined,
  AlertOutlined
} from '@ant-design/icons'
import { Col, Card, Row, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUser } from '@contexts/UserContext'
import GraphPie from '@components/common/GraphPie'
import GraphStatistc from '@components/common/GraphStatistic'
import {
  getTicketCount,
  getBackupCount,
  getStatisticCount
} from '@helpers/getSummaryCount'

const colorTicket = ['#1677ff', '#edb007', '#FF7310', '#333333']
const colorBackup = ['#02B875', '#edb007', '#c72329']

const Summary = () => {
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '30px' }}>
      <SummaryStatistic />
      <SummaryPie />
    </Row>
  )
}

const SummaryStatistic = () => {
  const { t } = useTranslation()

  return (
    <>
      <Col lg={12} md={24} xs={24}>
        <Divider>{t('DEVICES')}</Divider>
        <Card style={{ textAlign: 'center' }} bordered={false}>
          <GraphStatistc
            func={() => getStatisticCount(DeviceSummary.slice(0, 4))}
            name="deviceSummary"
            summary={DeviceSummary.slice(0, 4)}
          />
        </Card>
      </Col>
      <Col lg={12} md={24} xs={24}>
        <Divider>{t('ALERTS')}</Divider>
        <Card style={{ textAlign: 'center' }} bordered={false}>
          <GraphStatistc
            func={() => getStatisticCount(DeviceSummary.slice(4))}
            name="alertsSummary"
            summary={DeviceSummary.slice(4)}
          />
        </Card>
      </Col>
    </>
  )
}

const SummaryPie = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const Ticketurl =
    user.level === 1 ? 'ticket/count' : `ticket/count?assignedTo=${user.uuid}`
  return (
    <>
      <Col lg={12} md={24} xs={24}>
        <Divider>{t('MYTICKETS')}</Divider>
        <GraphPie
          colors={colorTicket}
          name="ticketsSummary"
          func={() => getTicketCount(TicketSummary, Ticketurl)}
        />
      </Col>
      <Col lg={12} md={24} xs={24}>
        <Divider>{t('TODAYBACKUP')}</Divider>
        <GraphPie
          colors={colorBackup}
          name="backupSummary"
          func={() => getBackupCount(BackupSummary)}
        />
      </Col>
    </>
  )
}
const TicketSummary = [
  {
    key: 1,
    label: 'NEW'
  },
  {
    key: 2,
    label: 'PENDING'
  },
  {
    key: 3,
    label: 'PROCESSING'
  },
  {
    key: 4,
    label: 'CLOSED'
  }
]

const BackupSummary = [
  {
    key: 1,
    label: 'Success',
    url: 'backup/report?result=Success&page=1&perPage=1'
  },
  {
    key: 2,
    label: 'Warning',
    url: 'backup/report?result=Warning&page=1&perPage=1'
  },
  {
    key: 3,
    label: 'Failed',
    url: 'backup/report?result=Failed&page=1&perPage=1'
  }
]

const DeviceSummary = [
  {
    key: 1,
    label: 'AMOUNT',
    url: 'device?page=1&perPage=1',
    color: 'blue',
    icon: <CloudServerOutlined />
  },
  {
    key: 2,
    label: 'DEVICEPOWERUP',
    url: 'device?state=poweredOn&page=1&perPage=1',
    color: 'green',
    icon: <ArrowUpOutlined />
  },
  {
    key: 3,
    label: 'DEVICEPOWEROFF',
    url: 'device?state=poweredOff&page=1&perPage=1',
    color: 'red',
    icon: <ArrowDownOutlined />
  },
  {
    key: 4,
    label: 'DEVICESUSPEND',
    url: 'device?state=suspended&page=1&perPage=1',
    color: 'dark',
    icon: <ClockCircleOutlined />
  },
  {
    key: 5,
    label: 'ALERTSWARNING',
    url: 'devicealert?resolved=false&severity=WARN&page=1&perPage=1',
    color: 'teal',
    icon: <CoffeeOutlined />
  },
  {
    key: 6,
    label: 'ALERTSMEDIUM',
    url: 'devicealert?resolved=false&severity=MEDIUM&page=1&perPage=1',
    color: 'yellow',
    icon: <MehOutlined />
  },
  {
    key: 7,
    label: 'ALERTSHIGH',
    url: 'devicealert?resolved=false&severity=HIGH&page=1&perPage=1',
    color: 'orange',
    icon: <ExclamationCircleOutlined />
  },
  {
    key: 8,
    label: 'ALERTSDISASTER',
    url: 'devicealert?resolved=false&severity=DISASTER&page=1&perPage=1',
    color: 'red',
    icon: <AlertOutlined />
  }
]

export default Summary
