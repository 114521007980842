import { REQUESTBYICONS } from "@constants/requestbyicons";
import useTicketSelect from '@hooks/useTicketSelect';
import FilterSelect from "@components/common/FilterSelect";
import { Select } from "antd";

const searchRequestBy = async () => {
  return Object.keys(REQUESTBYICONS).map(request_by => ({
    value: request_by,
    label: request_by
  }));
};

const RequestFilterSelect = () => {
  const { options, ...props} = useTicketSelect({
    name: 'request_by',
    func: searchRequestBy
  });

  return (
    <FilterSelect
      options={options}
      placeholderKey="FILTERREQUESTBY"
      data-cy="filterRequest"
      optionRenderer={({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      )}
      {...props}
    />
  );
};

export default RequestFilterSelect;
