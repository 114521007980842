import {
  Typography,
  Badge,
  Space,
  Tag,
  Row,
  Button,
  ButtonProps,
  Col
} from 'antd'
import { TicketContext } from '@contexts/TicketContext'
import { useState } from 'react'
import { useGenericContext } from '@hooks/useGenericContext'
import useTicketTime from '@hooks/useTicketTime'
import { useTranslation } from 'react-i18next'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import ModalTicket from '../Modal'
import Icon from '@components/common/Icon'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { DEVICETYPES } from '@constants/devicetypes'
import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import humanizeDurations from '@helpers/humanizeDurations'
import useSearchParams from '@hooks/useSearchParams'
import { IDevice } from '@/types/IDevice'
import { useUser } from '@contexts/UserContext'
import DrawerProvider, { useDrawerContext } from '@contexts/DrawerContext'
import { DeviceContext } from '@contexts/DeviceContext'
import Company, { CompanyHead } from '@pages/Company'
import DeviceDrawer from '@components/Device/DeviceDrawer'
import { ITicket } from '@/types/ITicket'
const { Text, Title, Paragraph } = Typography

export const EditTicket = () => {
  return (
    <ModalTicket
      type="text"
      action="edit"
      icon={<Icon name="fa-light fa-pen" />}
    />
  )
}

interface ButtonTagTitleProps extends ButtonProps {
  item: string
  value: string
}

const CustomButton = (props: ButtonProps) => {
  return (
    <>
      {props.children ? (
        <Button {...props} type="default" shape="round">
          <Space>{props.children}</Space>
        </Button>
      ) : null}
    </>
  )
}

const ButtonTagTitle = ({ item, value, ...props }: ButtonTagTitleProps) => {
  const { t } = useTranslation()
  const params = useSearchParams(true)
  const onClick = () => params.toogleItem(item, value)
  return (
    <Button type="link" onClick={onClick} style={{ padding: 0 }}>
      <Tag {...props}>{t(value)}</Tag>
    </Button>
  )
}

const CardTitleLink = () => {
  const { data } = useGenericContext(TicketContext)
  const { open } = useDrawerContext(`/ticket/${data?.tkid}`, data)
  return (
    <>
      <Button type="link" style={{ padding: 0 }} onClick={open}>
        <Title style={{ margin: 0, lineHeight: 1 }} level={4}>
          {data?.is_private ? (
            <span
              className="fa-light fa-lock"
              style={{ marginRight: '10px' }}
            />
          ) : null}
          {data?.title}
        </Title>
      </Button>
      <Text type="secondary"> #{data?.tkid}</Text>
    </>
  )
}

const AvatarCard = () => {
  const { data } = useGenericContext(TicketContext)
  const { diff, current } = useTicketTime(data)
  return (
    <>
      {data?.expected_time ? (
        <Badge
          color={diff && diff < 0 ? 'red' : 'green'}
          status="processing"
          title={`
              C: ${humanizeDurations(current)}
              \n
              D: ${humanizeDurations(diff)}
              \n'
              E: ${humanizeDurations(data.expected_time)}
            `}
        />
      ) : null}
    </>
  )
}

export const CardTitle = () => {
  const { data } = useGenericContext(TicketContext)
  return (
    <Row align="middle" style={{ gap: '8px' }}>
      <AvatarCard />
      <Space align="start" size={8}>
        <CardTitleLink />
        <ButtonTagTitle
          {...{ item: 'status', value: data.status }}
          color={TICKETSTATUSESCOLORS[data.status].color}
        />
        <ButtonTagTitle
          {...{ item: 'priority', value: data.priority }}
          color={TICKETSPRIORITIESCOLORS[data.priority].color}
        />
      </Space>
    </Row>
  )
}

const DeviceButton = ({ device }: { device: { device: IDevice } }) => (
  <DeviceDrawer device={device.device}>
    <InternalDeviceButton />
  </DeviceDrawer>
)

const InternalDeviceButton = () => {
  const { data } = useGenericContext(DeviceContext)
  const { open } = useDrawerContext(`/device/${data.uuid}`, data)
  return (
    <CustomButton onClick={open}>
      <Icon
        name={
          data.os?.kind ? DEVICETYPES[data.os.kind].icon : 'fa-solid fa-server'
        }
      />
      <Text>{data.name}</Text>
    </CustomButton>
  )
}

const DeviceInfoCard = () => {
  const { data } = useGenericContext(TicketContext)
  const [ellipsis, setEllipsis] = useState(false)
  const showMore = () => setEllipsis(true)
  return (
    <Space wrap>
      {ellipsis ? (
        data.devices.map(device => <DeviceButton {...{ device }} />)
      ) : (
        <DeviceButton device={data.devices[0]} />
      )}
      <>
        {data.devices.length > 1 && !ellipsis ? (
          <Button type="default" onClick={showMore}>
            <Text>{' ... '}</Text>
          </Button>
        ) : null}
      </>
    </Space>
  )
}

const CompanyInfoCard = () => (
  <DrawerProvider component={Company} head={CompanyHead}>
    <InternalCompanyInfoCard />
  </DrawerProvider>
)

const InternalCompanyInfoCard = () => {
  const { user } = useUser()
  const { data } = useGenericContext(TicketContext)
  const { open } = useDrawerContext(
    `/company/${data?.company.uuid}`,
    data?.company
  )
  return (
    <CustomButton onClick={user.level !== 1 ? open : undefined}>
      <Icon name="fa-solid fa-building" color="icon" />
      <Text>
        {data?.company.name}{' '}
        {data?.company?.opdc_id ? `#${data.company.opdc_id}` : null}
      </Text>
    </CustomButton>
  )
}

const ContentFollowup = () => {
  const { data } = useGenericContext(TicketContext)
  return (
    <Col span={24}>
      {data.status === 'NEW' || data.last_followup_summary === null ? (
        <Paragraph ellipsis={(true, { rows: 2 })}>{data.content}</Paragraph>
      ) : (
        <Text>{`${data.last_followup_summary}...`}</Text>
      )}
    </Col>
  )
}

export const CardInfos = () => {
  const { data } = useGenericContext(TicketContext)
  const params = useSearchParams(true)
  return (
    <Row gutter={[2, 8]}>
      <Space size={16} wrap>
        {data.suser ? (
          <CustomButton
            onClick={() => params.toogleItem('assignedTo', data.suser.uuid)}
          >
            <Icon name="fa-solid fa-user-check" />
            <Text>{data.suser?.name}</Text>
          </CustomButton>
        ) : null}
        <CustomButton>
          <Icon name="fa-solid fa-calendar" />
          <Text>{dayjs(data.created_at).format(DATEFORMAT)}</Text>
        </CustomButton>
        {data.company ? <CompanyInfoCard /> : null}
        {data.devices?.length ? <DeviceInfoCard /> : null}
      </Space>
      <ContentFollowup />
    </Row>
  )
}

export const CardFilterButtons = () => {
  const { t } = useTranslation()
  const params = useSearchParams(true)
  const { data } = useGenericContext(TicketContext)
  return (
    <Space>
      {[
        { name: 'service', data: data.service?.uuid },
        { name: 'sector', data: data.sector?.slug },
        { name: 'category', data: data.category?.slug }
      ].map(item => (
        <CustomButton
          key={item.name + item.data}
          onClick={() => params.toogleItem(item.name, item.data)}
          type="default"
          shape="round"
        >
          {t(data[item.name as 'category' | 'sector' | 'service']?.name)}
        </CustomButton>
      ))}
    </Space>
  )
}
