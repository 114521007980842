import { useState } from 'react'
import { api } from '@helpers/api'
import { Popconfirm, Space, Button } from 'antd'
import ModalAutofill from '@components/Autofill/Modal'
import { ColumnsType } from 'antd/es/table'
import { IAutofill } from '@/types/IAutofill'
import { useTranslation } from 'react-i18next'
import Icon from '@common/Icon'
import TextMarkdown from '@components/common/TextMarkdown'

const DeleteAutofill = ({ uuid }: { uuid: string }) => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(false)

  const deleteAutofill = async () => {
    setDisabled(true)
    api.delete(`/ticket/autofill/${uuid}`).then(() => setDisabled(false))
  }

  return (
    <Popconfirm
      disabled={disabled}
      title={t('CONFIRMDELETE')}
      onConfirm={deleteAutofill}
    >
      <Button
        data-cy="deleteAutofill"
        danger
        icon={<Icon name="fa-light fa-trash" color="white" />}
        style={{ color: 'white' }}
        type="primary"
      />
    </Popconfirm>
  )
}

const EditAutofill = ({ data }: { data: IAutofill }) => {
  return (
    <ModalAutofill
      data={data}
      name="fa-light fa-pen"
      mode="edit"
      data-cy="editAutofill"
    />
  )
}

const AutofillActions = ({ data }: { data: IAutofill }) => {
  return (
    <Space>
      <EditAutofill data={data} />
      <DeleteAutofill uuid={data.uuid} />
    </Space>
  )
}
export const columns: ColumnsType<IAutofill> = [
  {
    title: 'ACTIONS',
    dataIndex: 'action',
    key: 'action',
    render: (_, data) => <AutofillActions {...{ data }} />
  },
  {
    title: 'NAME',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'CONTENT',
    dataIndex: 'content',
    key: 'content',
    render: content => <TextMarkdown text={content} />
  }
]
