import { api } from './api'

export const getOrderDocument = async (uuid: string) => {
  const response = await api.get(`/order/${uuid}/document`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf'
    }
  })
  return response
}
