import { Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { ICompany } from '@/types/ICompany'
import Actions from '../Actions'

const common = { dataIndex: 'counts' }

export const columns: ColumnsType<ICompany> = [
  {
    title: 'ACTIONS',
    dataIndex: 'name',
    key: 'actions',
    render: (_, company) => <Actions company={company} />
  },
  {
    title: 'ID',
    dataIndex: 'opdc_id',
    key: 'opdc_id'
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    ...common,
    title: 'DEVICES',
    key: 'DEVICES',
    render: counts => <span>{counts.devices}</span>
  },
  {
    ...common,
    title: 'TICKETS',
    key: 'TICKETS',
    render: counts => {
      return <span>{counts.tickets}</span>
    }
  },
  {
    title: 'CREATEDAT',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => <> {text ? dayjs(text).format(DATEFORMAT) : null} </>
  },
  {
    title: 'DELETEDAT',
    dataIndex: 'deleted_at',
    key: 'created_at',
    render: text => (
      <>
        {' '}
        {text ? <Tag color="red">{dayjs(text).format(DATEFORMAT)}</Tag> : null}
      </>
    )
  }
]
