import { Divider, Col, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import { translateColumns } from '@helpers/translateColumns'
import { useDeviceHardwareContext } from '../HardwareContent'
import { ISensor } from '@/types/IDeviceHardware'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { READINGCOMPUTEDCOLORS } from '@constants/sensors'

const SensorsHardware = () => {
  const { t } = useTranslation()
  const { data } = useDeviceHardwareContext()
  const dataSource = data.sensors || []
  return (
    <Col span={24}>
      <Divider>{t('SENSORS')}</Divider>
      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={translateColumns(columns, t)}
      />
    </Col>
  )
}

const columns: ColumnsType<ISensor> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'DESCRIPTION',
    dataIndex: 'desc',
    key: 'desc'
  },
  {
    title: 'ENTITY',
    dataIndex: 'entity',
    key: 'entity'
  },
  {
    title: 'READ_COMP',
    dataIndex: 'reading_computed',
    key: 'reading_computed',
    render: reading_computed => {
      return (
        <>{reading_computed ? <ReadingTag data={reading_computed} /> : null}</>
      )
    }
  },
  {
    title: 'BASE_UNIT',
    dataIndex: 'base_unit',
    key: 'base_unit'
  },
  {
    title: 'READ_RAW',
    dataIndex: 'reading_raw',
    key: 'reading_raw'
  },
  {
    title: 'SENSOR_TYPE',
    dataIndex: 'sensor_type',
    key: 'sensor_type'
  },
  {
    title: 'TIMESTAMP',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: data => dayjs(data).format(DATEFORMAT)
  }
]

const ReadingTag = ({ data }: { data: ISensor['reading_computed'] }) => {
  const status = READINGCOMPUTEDCOLORS[data]
  return (
    <>
      {status?.hasOwnProperty('color') ? (
        <Tag color={status.color || 'gray'}>{data}</Tag>
      ) : (
        <Tag color="blue-inverse">{data}</Tag>
      )}
    </>
  )
}

export default SensorsHardware
