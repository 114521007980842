import { ISector } from '@/types/ISector'
import { MessageContext } from '@contexts/MessageContext'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import useTableContext from '@hooks/useTableContext'
import { Button, Popconfirm, Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import SectorModal from '../Modal'
import Icon from '@components/common/Icon'
import { useTheme } from 'styled-components'
import { generateTabItem } from '@components/common/Tab'
import DeleteButton from '@components/common/DeleteButton'
type SectorProps = {
  sector: ISector
}

const SectorActions = ({ sector }: SectorProps) => {
  const { t } = useTranslation()
  return (
    <Space>
      <SectorModal
        sector={sector}
        icon={<Icon name="fa-light fa-plus" color="white" />}
        type="primary"
        data-cy="open-edit-sector"
      />
      <DeleteButton
        url={`sector/${sector.uuid}`}
        uuid={sector.uuid}
        name={`${t('SECTOR')} ${sector.name}`}
      />
    </Space>
  )
}

const commonActions = {
  title: 'ACTIONS',
  dataIndex: 'name',
  key: 'name'
}

const commonColumns = [
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'SLUG',
    dataIndex: 'slug',
    key: 'slug'
  }
]

const useInactiveSector = (sector: ISector) => {
  const messageApi = useGenericContext(MessageContext)
  const { remove } = useTableContext()
  const { t } = useTranslation()
  const onConfirm = () => {
    api
      .post(`/sector/${sector.uuid}/restore`)
      .then(() => {
        remove(sector.uuid)
      })
      .catch(err => {
        handleError(err, messageApi, t('RESTORESECTORERROR'))
      })
  }
  return onConfirm
}

const InactiveSectorsActions = ({ sector }: SectorProps) => {
  const { t } = useTranslation()
  const onConfirm = useInactiveSector(sector)
  const theme = useTheme()
  return (
    <Popconfirm title={t('RESTORESECTOR')} onConfirm={onConfirm}>
      <Button
        icon={<Icon name="fa-light fa-recycle" color="white" />}
        style={{ backgroundColor: theme.green }}
        data-cy="restore-inative-action"
      />
    </Popconfirm>
  )
}

export const columns: Array<ColumnsType<ISector>> = [
  [
    {
      ...commonActions,
      render: (_, sector) => <SectorActions sector={sector} />
    },
    ...commonColumns
  ],
  [
    {
      ...commonActions,
      render: (_, sector) => <InactiveSectorsActions sector={sector} />
    },
    ...commonColumns
  ]
]

export const useSectorTab = () => {
  const { t } = useTranslation()
  const names = ['ACTIVES', 'INACTIVES']
  const urls = ['/sector', '/sector?inactive=1']
  return columns.map((e, index) =>
    generateTabItem(
      {
        key: index,
        label: t(names[index]),
        url: urls[index]
      },
      e
    )
  )
}
