import { IDevice } from '@/types/IDevice'
import { DeviceContext } from '@contexts/DeviceContext'
import { useContext } from 'react'

const useDeviceContext = () => {
  const context = useContext(DeviceContext)
  const updateDevice = (uuid: string, newItem: IDevice) => {
    uuid === context?.data.uuid && context?.setData(newItem)
  }

  return { updateDevice }
}

export default useDeviceContext
