import { Tag, Divider, Table, Col, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import Descriptions from '@components/common/Descriptions'
import Title from '@components/common/Title'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { useCompany } from '@pages/Company'
import { columns } from '@components/Company/Descriptions'
import RenderPage from '@components/common/RenderPage'
import { useTranslation } from 'react-i18next'
import { ORDER_STATUS, ORDER_TYPES } from '@constants/orderstatus'
import type {
  IOrder,
  IItens,
  IProduct,
  IItemOrderRequest,
  IOrderRequest
} from '@/types/IOrder'
import type { ColumnsType } from 'antd/es/table'
import { translateColumns } from '@helpers/translateColumns'

const Order = ({ data }: { data: IOrder }) => {
  const { t } = useTranslation()
  const itemsColumnsTranslate = translateColumns(itemsColumns, t)
  const { company, loading } = useCompany(data?.company?.uuid)
  return (
    <RenderPage {...{ data: company, loading }}>
      <Descriptions
        columns={columns}
        bordered
        dataSource={company?.uuid ? company : data?.customer}
        size="small"
      />
      <Divider>{t('PRICE')}</Divider>
      <Descriptions
        columns={priceColumns}
        bordered
        dataSource={data}
        size="small"
        column={{ xs: 1, xl: 4 }}
      />
      <Divider>{t('PRODUCTS')}</Divider>
      <Row gutter={[16, 16]}>
        {data.items.map((items: IItens) => (
          <Col span={24}>
            <Descriptions
              columns={productColumns}
              bordered
              dataSource={items.product}
              size="small"
              column={{ xs: 1, xl: 4 }}
            />
            <Table
              key={items.uuid}
              dataSource={transformOrderRequest(items.order_request).items}
              columns={itemsColumnsTranslate}
              rowKey="uuid"
              pagination={false}
            />
          </Col>
        ))}
      </Row>
    </RenderPage>
  )
}

export const OrderHead = ({ data }: { data: IOrder }) => {
  return (
    <Title
      name={data.company?.name ? data.company.name : data.customer.name}
      style={{ margin: 0 }}
    />
  )
}

const ColumnTranslation = ({
  text,
  constant
}: {
  text: string
  constant: any
}) => {
  const { t } = useTranslation()
  return <Tag color={constant[text]}>{t(text)}</Tag>
}

const transformOrderRequest = (orderRequest: IOrderRequest) => {
  const transformItem = item => {
    if (item.children?.length === 0) {
      delete item.children
    } else if (item.children?.items) {
      item.children = item.children.items
    }

    if (Array.isArray(item.children)) {
      item.children = item.children.map(transformItem)
    }

    return item
  }

  orderRequest.items = orderRequest.items.map(transformItem)

  return orderRequest
}

const priceColumns: CustomDescriptionType<IOrder>[] = [
  {
    span: 1,
    label: 'TOTAL_PRICE',
    key: 'price_total',
    render: data => (
      <Typography.Text strong>{`R$ ${data.data}`}</Typography.Text>
    )
  },
  {
    span: 1,
    label: 'STATUS',
    key: 'status',
    render: status => (
      <ColumnTranslation text={status.data} constant={ORDER_STATUS} />
    )
  },
  {
    label: 'TYPE',
    key: 'type',
    render: type => (
      <ColumnTranslation text={type.data} constant={ORDER_TYPES} />
    )
  },
  {
    span: 1,
    label: 'UPDATEAT',
    key: 'updated_at',
    render: updated_at =>
      updated_at.data ? dayjs(updated_at.data).format(DATEFORMAT) : ''
  },
  {
    span: 1,
    label: 'OBS',
    key: 'obs'
  },
  {
    span: 1,
    label: 'DISCOUNT',
    key: 'discount_percents',
    render: discount_percents => (
      <>
        {discount_percents && (
          <Typography.Text>{`${Math.floor(discount_percents.data)}%`}</Typography.Text>
        )}
      </>
    )
  },
  {
    span: 1,
    label: 'DISCOUNT_MOTIVE',
    key: 'discount_motive'
  }
]

const productColumns: CustomDescriptionType<IProduct>[] = [
  {
    span: 1,
    label: 'NAME',
    key: 'name'
  },
  {
    span: 1,
    label: 'DC',
    key: 'dc',
    render: ({ data }) => data && data.shortname
  }
]

const itemsColumns: ColumnsType<IItemOrderRequest> = [
  {
    title: 'NAME',
    dataIndex: 'item',
    key: 'item',
    render: item => <span>{item.item}</span>
  },
  {
    title: 'CURRENT',
    dataIndex: 'current',
    key: 'current',
    render: (_, data) => (
      <>
        <span>{`${data.current}`}</span>
        {data.item?.postfix && <span>{` ${data.item.postfix}`}</span>}
      </>
    )
  },
  {
    title: 'PRICE',
    dataIndex: 'value',
    key: 'value',
    render: value => <Typography.Text>{`R$ ${value}`}</Typography.Text>
  }
]

export default Order
