import { Tooltip, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { TicketProps } from '@/types/ITicket'
import useTicketTime from '@hooks/useTicketTime'
import humanizeDurations from '@helpers/humanizeDurations'
export const TooltipContent = ({ data }: TicketProps) => {
  const { t } = useTranslation()
  return (
    <>
      {t('NEW')}: {humanizeDurations(data.new_time) || null}
      <br />
      {t('PLANNEDANDPENDINGTIME')}:{' '}
      {humanizeDurations(data.plannedpending_time)}
      <br />
      {t('PROCESSING_ATTRIBUTED')}: {humanizeDurations(data.processing_time)}
      <br />
      {t('TOTALTIME')}: {humanizeDurations(data.total_time)}
    </>
  )
}

const DiffTime = ({ diff }: { diff: number | null }) => {
  return (
    <>
      {diff ? (
        <Tag color={diff < 0 ? 'red-inverse' : 'lime-inverse'}>
          {'D: '}
          {diff < 0 ? '+' : '-'}
          {humanizeDurations(diff)}
        </Tag>
      ) : null}
    </>
  )
}

const ExpectedTime = ({ expected }: { expected: number }) => {
  return <Tag color="cyan-inverse">{`E: ${humanizeDurations(expected)} `}</Tag>
}

const TicketTime = ({ data }: TicketProps) => {
  const { current, diff } = useTicketTime(data)
  return (
    <Tooltip title={<TooltipContent data={data} />}>
      <Tag color="blue-inverse"> {`C: ${humanizeDurations(current)} `}</Tag>
      <>
        {data.expected_time ? (
          <ExpectedTime expected={data.expected_time} />
        ) : null}
        <DiffTime {...{ diff }} />
      </>
    </Tooltip>
  )
}

export default TicketTime
