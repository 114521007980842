import { useState, useEffect } from 'react'
import { Dropdown, Button, Space, Tag } from 'antd'
import { queryFrame } from '../ModalFrame'
import { handlePowered, translatedMessages } from '../DeviceActions'
import { useTranslation } from 'react-i18next'
import { Frame } from '../ModalFrame'
import { modeOn, modeOff } from '@constants/powered'
import { useUser } from '@contexts/UserContext'
import { IDevice } from '@/types/IDevice'
import Icon from '@components/common/Icon'
import { DEVICETYPES } from '@constants/devicetypes'
import useWindowResize from '@hooks/useWindowResize'
import { translateColumns } from '@helpers/translateColumns'
import { useDrawerContext } from '@contexts/DrawerContext'
import DeviceDrawer from '../DeviceDrawer'

interface DeviceProps {
  device: IDevice
}

export const DeviceDropdownOrTag = ({ device }: { device: IDevice }) => {
  const { width } = useWindowResize()
  return (
    <>
      {width < 720 ? (
        <Tag color="blue-inverse">{device.name}</Tag>
      ) : (
        <DropdownDevice device={device} />
      )}
    </>
  )
}

const LinkDevice = ({ device }: DeviceProps) => {
  const { width } = useWindowResize()
  const { open } = useDrawerContext(`/device/${device.uuid}`, device)
  return (
    <button onClick={open}>
      <Space>
        {device.os?.kind ? (
          <Icon name={DEVICETYPES[device.os.kind].icon} />
        ) : null}{' '}
        {`${device?.name}${width > 720 ? ` - ${device?.company?.name}` : ''}`}
      </Space>
    </button>
  )
}

const DropdownDevice = ({ device }: DeviceProps) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [url, setUrl] = useState('')
  const [isOn, setIsOn] = useState(device.state)
  const [items, setItems] = useState(translateColumns(modeOn, t, 'label'))
  const { width } = useWindowResize()

  const action = (mode: string, state: string) => {
    const message = translatedMessages(t, mode, device.name)
    handlePowered(mode, device.uuid, message).then(() => setIsOn(state))
  }

  const actionMap: any = {
    console: async () => {
      const res = await queryFrame(
        `/device/${device.uuid}/console?wss=true`,
        'post'
      )
      setUrl(res.data.url)
      setIsOpen(true)
    },
    poweron: () => {
      action('poweron', 'poweredOn')
    },
    poweroff: () => {
      action('poweroff', 'poweredOff')
    },
    reset: () => {
      action('reset', 'poweredOn')
    }
  }

  const onMenuClick = async (e: any) => {
    const action = actionMap[e.key]
    if (action) {
      await action()
    }
  }

  useEffect(() => {
    isOn === 'poweredOn'
      ? setItems(translateColumns(modeOn, t, 'label'))
      : setItems(translateColumns(modeOff, t, 'label'))
  }, [isOn])

  return (
    <DeviceDrawer device={device}>
      {user.level > 1 && width > 720 ? (
        <>
          <Dropdown.Button
            style={{ maxWidth: '0.9rem' }}
            data-cy="device-dropdown-button"
            menu={{ items, onClick: onMenuClick }}
          >
            <LinkDevice device={device} />
          </Dropdown.Button>
          <Frame url={url} open={isOpen} setOpen={setIsOpen} />
        </>
      ) : (
        <Button data-cy="device-button">
          <LinkDevice device={device} />
        </Button>
      )}
    </DeviceDrawer>
  )
}

export default DropdownDevice
