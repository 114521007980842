import Icon from "@components/common/Icon"
import SubTitle from "@components/common/SubTitle"
import { useUser } from "@contexts/UserContext"
import { Row, Switch } from "antd"
import { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import useSearchParams from "@hooks/useSearchParams"

const useAssignedUserSwitch = () => {
    const [isAssigned, setIsAssigned] = useState(false)
    const params = useSearchParams(true)
    const switchRef = useRef<boolean>(false)
    const { user } = useUser()
    const location = useLocation()
  
    useEffect(() => {
      if (!switchRef.current) {
        const assignedTo = params.getItem('assignedTo')
        assignedTo === user.uuid ? setIsAssigned(true) : setIsAssigned(false)
      }
      switchRef.current = false
    }, [location.search])
  
    const onChange = () => {
      switchRef.current = true
      const assignedTo = params.getItem('assignedTo')
      if (assignedTo === user.uuid) {
        params.removeItem('assignedTo')
        setIsAssigned(false)
      } else {
        params.setItem('assignedTo', user.uuid)
        setIsAssigned(true)
      }
    }
    return { checked: isAssigned, onChange }
  }
  
const AssignedUserSwitch = () => {
    const { t } = useTranslation()
    const switchProps = useAssignedUserSwitch()
    return (
      <Row justify={'start'} gutter={8}>
        <Switch
          {...switchProps}
          checkedChildren={<Icon name="fal fa-user" color="white" />}
          unCheckedChildren={<Icon name="fal fa-users" color="white" />}
        />
        <SubTitle span={18} level={5}>
          {t('ASSIGNEDTOME')}
        </SubTitle>
      </Row>
    )
}

export default AssignedUserSwitch;