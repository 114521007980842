import { Button, Col, Row, Tag, Tooltip, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { IDisk } from '@/types/IDeviceHardware'
import { IDevice } from '@/types/IDevice'
import EditableAlias from '@common/EditableAlias'
import { ColumnsType } from 'antd/es/table'
import DeviceActions from '../DeviceActions'
import { useTranslation } from 'react-i18next'
import humanizeSizes from '@helpers/humanizeSizes'
import DeviceStateTag from '../DeviceStateTag'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'
import useWindowResize from '@hooks/useWindowResize'
import { useGenericContext } from '@hooks/useGenericContext'
import { DrawerContext } from '@contexts/DrawerContext'
import { DeviceContext } from '@contexts/DeviceContext'
import DeviceDrawer from '../DeviceDrawer'

dayjs.extend(duration)
dayjs.extend(relativeTime)

type ItemProps = {
  children: string
}
const { Text } = Typography

const Item = (props: ItemProps) => {
  const { t } = useTranslation()
  return <span {...props}>{t(props.children)}</span>
}

const DeviceDrawerContent = () => {
  const { data } = useGenericContext(DeviceContext)
  const { openDrawer } = useGenericContext(DrawerContext)
  const navigate = useNavigate()
  const { width } = useWindowResize()
  const open = () =>
    width > 720
      ? openDrawer(data)
      : navigate(`/device/${data.uuid}`, { state: data })
  return (
    <>
      <Tooltip title={data?.parent?.name}>
        <Button type="link" onClick={open}>
          <Text style={{ marginRight: '2%' }}>{data.name}</Text>
        </Button>
      </Tooltip>
      <EditableAlias
        name={{ name: data.name, uuid: data.uuid, alias: data.alias }}
      />
    </>
  )
}

export const columns: ColumnsType<IDevice> = [
  {
    title: 'ACTIONS',
    key: 'actions',
    dataIndex: 'actions',
    render: (_, device) => (
      <Row style={{ minWidth: 200 }}>
        <DeviceActions data={device} />
      </Row>
    )
  },
  {
    title: 'NAME',
    key: 'name',
    dataIndex: 'name',
    render: (_, device) => {
      return (
        <DeviceDrawer device={device}>
          <DeviceDrawerContent />
        </DeviceDrawer>
      )
    }
  },
  {
    title: 'STATUS',
    key: 'state',
    dataIndex: 'state',
    render: (e: IDevice['state']) => <DeviceStateTag state={e} />
  },
  {
    title: 'CPUs',
    key: 'cpu',
    dataIndex: 'cpu'
  },
  {
    title: 'RAM',
    key: 'mem',
    dataIndex: 'mem',
    render: data => <>{data ? <span>{data} GB</span> : null}</>
  },
  {
    title: 'DISKS',
    key: 'disks',
    dataIndex: 'disks',
    render: data => <>{data ? <Text>{sumDiskSize(data)}</Text> : null}</>
  },
  {
    title: 'TYPE',
    key: 'type',
    dataIndex: 'type',
    render: type => <Item>{type}</Item>
  },
  {
    title: 'INTERFACES',
    key: 'interfaces',
    dataIndex: 'interfaces',
    render: interfaces => (
      <>
        {Array.isArray(interfaces)
          ? interfaces?.map(elem => (
              <Row>
                {elem?.ips?.map((ip: string) => (
                  <Col span={24} key={ip}>
                    <Text>{ip} </Text>
                  </Col>
                ))}
                {elem?.mac ? <Text key={elem.mac}>{elem?.mac}</Text> : null}
              </Row>
            ))
          : null}
      </>
    )
  },
  {
    title: 'UPTIME',
    key: 'uptime',
    dataIndex: 'uptime',
    render: uptime => (
      <>{uptime ? dayjs.duration(uptime, 'seconds').humanize() : null}</>
    )
  },
  {
    title: 'DC',
    key: 'dc',
    dataIndex: 'dc',
    render: dc => <Tag color="blue-inverse">{dc ? dc.shortname : null}</Tag>
  },
  {
    title: 'GROUP',
    key: 'group',
    dataIndex: 'group',
    render: (_, { group }) => <span>{group ? group.name : null}</span>
  }
]

const sumDiskSize = (disk: IDisk[]) => {
  let sum = humanizeSizes(
    disk.reduce((acc: number, current) => current['size'] + acc, 0)
  )
  return sum
}
