import { MessageInstance } from 'antd/es/message/interface'
import { AxiosError, isAxiosError } from 'axios'

export const handleError = (
  data: AxiosError,
  messageApi: MessageInstance | undefined,
  headerMessage?: string | undefined,
  unexpectedErrorMessage?: string
) => {
  const errorChannel = (message: string) => sendError(message, messageApi)
  console.error('handle error', data, headerMessage, unexpectedErrorMessage)
  if (isAxiosError(data)) {
    const message = `${headerMessage ? headerMessage + ': ' : ''} ${
      data.message
    }`
    errorChannel(message)
    return
  }
  errorChannel(unexpectedErrorMessage || 'Unexpected Error')
}

const sendError = (
  message: string,
  messageApi: MessageInstance | undefined
) => {
  messageApi ? messageApi.error(message) : console.error(message)
}
