import {
  Card,
  Typography,
  Row,
  Flex,
  Space,
  Col,
  Form,
  Button,
  message
} from 'antd'
import TextInput from '@components/common/TextInput'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import { TFunction } from 'i18next'

const ForgotPassword = () => {
  const { t } = useTranslation()

  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: '100vh', backgroundColor: '#005892' }}
    >
      <Col lg={{ span: 8 }} xs={{ span: 22 }}>
        <Card style={{ textAlign: 'center' }} cover={<ImageHeader />}>
          <Space direction="vertical">
            <Typography.Title>{t('RESET_PASSWORD')}</Typography.Title>
            <Typography.Text>{t('MESSAGEFORGOTPASSWORD')} </Typography.Text>
            <ResetPasswordForm />
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

const ImageHeader = () => (
  <Flex justify="center">
    <img
      src="https://app.opendata.center/assets/img/logo-opendatacenter-blue.png"
      title="OPEN DATACENTER"
      style={{ width: '75%' }}
    />
  </Flex>
)

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  return (
    <Form
      name="forgot-password"
      id="forgot-password"
      onFinish={values => onFinish(values, t, form)}
      layout="vertical"
      form={form}
    >
      <TextInput
        name="email"
        rules={[
          { type: 'email', message: t('emailInvalid') },
          { required: true, message: t('emailEmpty') }
        ]}
        InputProps={{ placeholder: t('email') }}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('SENDTOEMAIL')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const onFinish = async (values: { email: string }, t: TFunction, form: any) => {
  try {
    await api.post('/forgot-password', values)
    message.success(t('SENDTOEMAILSUCCESS'), 2)
    form.resetFields()
  } catch (error) {
    message.error(t('SENDTOEMAILERROR'), 5)
  }
}

export default ForgotPassword
