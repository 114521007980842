import { IDeviceHardware } from '@/types/IDeviceHardware'
import { Row } from 'antd'
import { api } from '@helpers/api'
import useFetch from '@hooks/useFetch'
import RenderPage from '@components/common/RenderPage'
import OverviewHardware from '../OverviewHardware'
import CapacityHardware from '../CapacityHardware'
import SpecsHardware from '../SpecsHardware'
import SensorsHardware from '../SensorsHardware'
import PhotoHardware from '../PhotoHardware'
import { DeviceHardwareContextType } from '@/types/IDeviceHardware'
import { DeviceHardwareContext } from '@contexts/DeviceHardwareContext'
import { useGenericContext } from '@hooks/useGenericContext'

export const useDeviceHardwareContext = () =>
  useGenericContext<DeviceHardwareContextType>(DeviceHardwareContext)

const HardwareContent = ({ uuid }: { uuid: string }) => {
  const { data, loading, error } = useFetch<IDeviceHardware, undefined>({
    func: () => api.get(`/device/${uuid}/hardware`),
    initialValue: undefined,
    params: undefined
  })
  return (
    <RenderPage {...{ data, loading, error }}>
      <DeviceHardwareProvider {...{ data, uuid }} />
    </RenderPage>
  )
}

const DeviceHardwareProvider = (props: DeviceHardwareContextType) => {
  return (
    <DeviceHardwareContext.Provider value={{ ...props }}>
      <Row gutter={[16, 16]}>
        <OverviewHardware />
        <CapacityHardware />
        <SpecsHardware />
        <SensorsHardware />
        <PhotoHardware />
      </Row>
    </DeviceHardwareContext.Provider>
  )
}

export default HardwareContent
