import { ComponentType } from 'react'
import { useTheme } from 'styled-components'
import { useUser } from '@contexts/UserContext'
import { ITicket, TicketProps } from '@/types/ITicket'
import ModalTicket from '@components/Ticket/Modal'
import Icon from '@common/Icon'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs, { Dayjs } from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import { Row, Col, Space, Alert, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import Title from '@common/Title'
import { useGenericContext } from '@hooks/useGenericContext'
import { TicketContext } from '@contexts/TicketContext'
dayjs.extend(customParseFormat)

type OrderHeadComponent = ComponentType<{ data: ITicket }>

const ScheduleTicketBanner = ({ data }: { data: ITicket }) => {
  const { t } = useTranslation()
  return (
    <Col span={24} data-cy="page-ticket-schedule-alert">
      <Divider />
      <Alert
        message={`${t('THISCHEDULEDTICKETAT')} ${dayjs(data.schedule_at).format(
          DATEFORMAT
        )}`}
        description={t('SCHEDULEDTICKETINFO')}
        type="info"
        showIcon
      />
    </Col>
  )
}

const ScheduleButton = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const theme = useTheme()
  return (
    <>
      {user?.level !== 1 ? (
        <ModalTicket
          style={{ backgroundColor: theme.blue, color: theme.white }}
          children={t('RESCHEDULE')}
          action="schedule"
          icon={<Icon name="fa-light fa-clock" color="white" />}
        />
      ) : null}
    </>
  )
}

const HeadActions = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Row justify={{ xs: 'center', xl: 'end' }}>
      <Space>
        <ModalTicket
          style={{ backgroundColor: theme.blue, color: theme.white }}
          action="edit"
          children={t('UPDATE')}
          icon={<Icon name="fa-light fa-pen" color="white" />}
        />
        <ScheduleButton />
      </Space>
    </Row>
  )
}

const HeadIcon = ({
  name,
  data,
  ...props
}: {
  name: string
  data: string | boolean | null
}) => {
  if (data) {
    return <Icon {...props} name={name} color="text" size="25px" />
  }
  return null
}

const HeadTitle = () => {
  const { data } = useGenericContext(TicketContext)
  const { t } = useTranslation()
  return (
    <Space align="start" data-cy="page-ticket-title">
      <HeadIcon
        data={data?.is_private}
        data-cy="page-ticket-isprivate"
        name="fa-light fa-lock-keyhole"
      />
      <HeadIcon
        data={data?.schedule_at}
        data-cy="page-ticket-schedule"
        name="fa-light fa-clock"
      />{' '}
      <Title name={`${t('TICKET')} #${data.tkid} - ${data.title}`} />{' '}
    </Space>
  )
}

const headActionsandTime: Array<[OrderHeadComponent, number, number]> = [
  [HeadActions, 1, 2]
]

const ActionsAndTime = () => {
  const { data } = useGenericContext(TicketContext)
  return (
    <Row justify={{ xs: 'start', xl: 'end' }}>
      {headActionsandTime.map((e, index) => {
        const Tag: OrderHeadComponent = e[0]
        return (
          <Col key={index} lg={{ order: e[1] }} xs={{ order: e[2] }} span={24}>
            <Tag data={data} />
          </Col>
        )
      })}
    </Row>
  )
}

const TicketHead = () => {
  const { data } = useGenericContext(TicketContext)
  return (
    <Row style={{ width: '100%' }} align="middle">
      <Col span={24} md={{ span: 18 }}>
        <HeadTitle />
      </Col>
      <Col span={24} md={{ span: 24, offset: 0 }} xl={{ span: 6 }}>
        <ActionsAndTime />
      </Col>
      {data.schedule_at && data.type === 'PRETICKET' ? (
        <ScheduleTicketBanner data={data} />
      ) : null}
    </Row>
  )
}
export default TicketHead
