import { Form, FormProps, ButtonProps } from 'antd'
import Button from '@common/Button'
import { useTranslation } from 'react-i18next'
import TextInput from '@common/TextInput'
import { translateRules } from '@helpers/translateRulesInput'

interface FormLoginProps extends FormProps {
  loading: boolean
}

const emailrules = [
  { required: true, message: 'emailEmpty' },
  { type: 'email', message: 'emailInvalid' }
]
const passwordrules = [
  { required: true, message: 'PASSWORDEMPTY' },
  { min: 5, message: 'PASSWORDINVALID' }
]

const emailInputProps = {
  type: 'email',
  maxLength: 300
}

const Submit = (props: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      block
      children={t('login')}
      type="primary"
      htmlType="submit"
    />
  )
}

const FormLogin = ({ loading, ...props }: FormLoginProps) => {
  const { t } = useTranslation()
  return (
    <Form {...props} id="Login">
      <TextInput
        name="email"
        rules={translateRules(emailrules, t)}
        InputProps={{
          ...emailInputProps,
          placeholder: t('email')
        }}
      />
      <TextInput
        name="password"
        rules={translateRules(passwordrules, t)}
        InputProps={{
          type: 'password',
          placeholder: t('SETPASSWORD'),
          maxLength: 300
        }}
      />
      <Form.Item>
        <Submit loading={loading} />
      </Form.Item>
    </Form>
  )
}

export default FormLogin
