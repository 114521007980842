import { useState } from 'react'
import Modal from '@common/Modal'
import { api } from '@helpers/api'
import Button from '@common/Button'
import { ButtonProps, message } from 'antd'
import Icon from '@common/Icon'
import { useTranslation } from 'react-i18next'

interface FramePropsResponse {
  data: {
    url: string
  }
}
interface FrameModalProps extends ButtonProps {
  link: string
  icon: string
  method?: string
}

interface FrameProps {
  url: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const queryFrame = async (link: string, method: string) => {
  return await api[method](link).then(
    (response: FramePropsResponse) => response
  )
}

const error = (mes: string) => message.error(mes)

export const Frame = ({ url, open, setOpen }: FrameProps) => {
  return (
    <Modal
      style={{ top: 20 }}
      width="100%"
      footer={false}
      open={open}
      onCancel={() => setOpen(false)}
    >
      <iframe
        data-cy="modal-iframe"
        allowFullScreen
        style={{ width: '100%', height: '80vh', marginTop: '40px' }}
        src={url}
      />
    </Modal>
  )
}

const ModalFrame = ({
  link,
  icon,
  method = 'get',
  ...props
}: FrameModalProps) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')
  const { t } = useTranslation()
  const errorMessage = t('ERROROPENMODAL')

  const openFrame = async () => {
    const res = await queryFrame(link, method).catch(err => error(errorMessage))
    setUrl(res.data.url)
    setOpen(true)
  }
  return (
    <>
      <Button
        {...props}
        data-cy="modal-frame"
        icon={<Icon name={icon} color="white" />}
        type="primary"
        onClick={openFrame}
      />
      <Frame url={url} open={open} setOpen={setOpen} />
    </>
  )
}
export default ModalFrame
