import { Space, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { api } from '@helpers/api'
import TicketDrawer from '@components/Ticket/TicketDrawer'
import useFetch from '@hooks/useFetch'
import { useDrawerContext } from '@contexts/DrawerContext'
import type { ITicket } from '@/types/ITicket'

const TicketOrderButton = ({ tickets }: { tickets: string[] }) => {
  return (
    <Space>
      {tickets.map(ticket => (
        <ShowTicket tkid={ticket} />
      ))}
    </Space>
  )
}

const ShowTicket = ({ tkid }: { tkid: string }) => {
  const { data, loading } = useFetch<ITicket, undefined>({
    func: () => api.get(`ticket/${tkid}`)
  })

  return (
    <>
      {data && !loading ? (
        <TicketDrawer ticket={data}>
          <TicketButton data={data} />
        </TicketDrawer>
      ) : null}
    </>
  )
}

const TicketButton = ({ data }: { data: ITicket }) => {
  const { t } = useTranslation()
  const { open } = useDrawerContext(`/ticket/${data.tkid}`, data)
  return (
    <Button
      type="primary"
      name="fa-light fa-plus"
      data-cy="table-open-ticket"
      onClick={open}
    >
      {`${t('TICKET')} #${data.tkid}`}
    </Button>
  )
}

export default TicketOrderButton
