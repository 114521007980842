import { useState } from 'react'
import { Typography, Button, Modal, Tag, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import PDFViewer from '@components/common/PDFViewer'
import { IAttachment } from '@types/IFollowup'

const API_URL = import.meta.env.VITE_OPEN_URL

interface FollowupProps {
  attachments: IAttachment[]
}

interface AttachmentProps {
  attachment: IAttachment
  showPreview: (attachment: IAttachment) => void
}

const Attachment = ({ attachment, showPreview }: AttachmentProps) => {
  const disable = !/^application\/pdf/.test(attachment.mime)
  return (
    <Tag key={attachment.uuid} style={{ width: 225, marginBottom: 5 }}>
      <Button
        style={{
          overflow: 'hidden',
          width: 175,
          textOverflow: 'ellipsis',
          color: 'white'
        }}
        onClick={() => showPreview(attachment)}
        type="link"
        data-cy="open-preview-pdf"
        disabled={disable}
      >
        <Tooltip title={attachment.name}>
          <Typography.Text ellipsis>{attachment.name}</Typography.Text>
        </Tooltip>
      </Button>
      <Button
        type="link"
        icon={<DownloadOutlined />}
        data-cy="download-document"
        download={attachment.name}
        href={`${API_URL}${attachment.path}`}
        size="large"
      />
    </Tag>
  )
}

const FollowupDocument = ({ attachments }: FollowupProps) => {
  const [attachment, setAttachment] = useState<IAttachment | null>(null)
  const [previewVisible, setPreviewVisible] = useState(false)
  const showPreview = (attachment: IAttachment) => {
    setAttachment(attachment)
    setPreviewVisible(true)
  }

  const closePreview = () => {
    setPreviewVisible(false)
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {attachments.map(attachment => (
        <Attachment
          key={attachment.uuid}
          attachment={attachment}
          showPreview={showPreview}
        />
      ))}
      {attachment && (
        <Modal
          title={attachment.name}
          open={previewVisible}
          onCancel={closePreview}
          footer={null}
          width={'130vh'}
          centered
        >
          <PDFViewer url={`${API_URL}${attachment.path}`} />
        </Modal>
      )}
    </div>
  )
}

export default FollowupDocument
