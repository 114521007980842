import { api } from '@helpers/api'

export async function postAttachmentsforTicket(
  filesArray,
  tkid,
  is_private: boolean
) {
  const promises = filesArray.map(async file => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        const contentBase64 = event.target?.result?.split(',')[1]
        resolve({ name: file.name, content: contentBase64 })
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(file.originFileObj)
    })
  })

  try {
    const results = await Promise.all(promises)
    const response = await api.post(`/ticket/${tkid}/followup`, {
      followup: 'ANEXO',
      attachments: results,
      is_private: is_private
    })
    return response.data
  } catch (error) {
    throw error
  }
}
