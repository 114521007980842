import { Link, useNavigate } from 'react-router-dom'
import { Tag, Space, Typography } from 'antd'
import IconButton from '@common/IconButton'
import { IInterfaces, IDiskController } from '@/types/IServer'
import { IDevice } from '@/types/IDevice'
import { useTranslation } from 'react-i18next'
import { generateTabItem } from '@common/Tab'
import type { TabsProps } from 'antd'
import EditableAlias from '@common/EditableAlias'
import { BRASP, BRAGO, BRACE, BRAPR, USAFL, USATX, BRASP2 } from '@constants/dc'
import { ColumnType } from 'antd/es/table'
import DeviceStateTag from '@components/Device/DeviceStateTag'
import DeviceDrawer from '@components/Device/DeviceDrawer'
import { useGenericContext } from '@hooks/useGenericContext'
import { DeviceContext } from '@contexts/DeviceContext'
import { useDrawerContext } from '@contexts/DrawerContext'
const { Text } = Typography

export const columns: ColumnType<IDevice> = [
  {
    title: 'ACTIONS',
    key: 'action',
    dataIndex: 'actions',
    render: (_, device) => (
      <ShowActions device={device} uuid={device.uuid} domain={device.domain} />
    )
  },
  {
    title: 'Dc',
    key: 'dc',
    dataIndex: 'dc',
    render: dc => <span>{dc ? dc.shortname : null}</span>
  },
  {
    title: 'NAME',
    key: 'name',
    dataIndex: 'name',
    render: (_, device) => (
      <>
        <Text style={{ marginRight: '2%' }}>{device.name}</Text>
        <EditableAlias
          name={{ name: device.name, alias: device?.alias, uuid: device.uuid }}
        />
      </>
    )
  },
  {
    title: 'Interfaces',
    key: 'interfaces',
    dataIndex: 'interfaces',
    render: (interfaces: IInterfaces[]) => {
      return (
        <Space direction="vertical">
          {interfaces &&
            interfaces.map(item => (
              <Tag color="blue" key={item.mac}>
                {`MAC: ${item.mac} - IP: `}
                {item.ips.map(ip => (
                  <Tag color="purple" key={ip}>
                    {ip}
                  </Tag>
                ))}
              </Tag>
            ))}
        </Space>
      )
    }
  },
  {
    title: 'DISKS',
    key: 'diskcontrollers',
    dataIndex: 'diskcontrollers',
    render: (disk: IDiskController[]) => {
      return <>{disk && <Disks disk={disk} />}</>
    }
  },
  {
    title: 'STATUS',
    key: 'state',
    dataIndex: 'state',
    render: (state: IDevice['state']) => {
      return <DeviceStateTag state={state} />
    }
  },
  {
    title: 'Cpu',
    key: 'cpu',
    dataIndex: 'cpu'
  },
  {
    title: 'Socket',
    key: 'sockets_count',
    dataIndex: 'sockets_count'
  },
  {
    title: 'Ram',
    key: 'mem',
    dataIndex: 'mem',
    render: mem => <span>{mem ? `${mem} GB` : null}</span>
  },
  {
    title: 'OS',
    key: 'os',
    dataIndex: 'os',
    render: (os: IDevice['os']) => {
      return <span>{os ? os.name : null}</span>
    }
  }
]

const ShowActions = ({
  uuid,
  domain,
  device
}: {
  uuid: string
  domain: string
  device: IDevice
}) => {
  const { t } = useTranslation()
  return (
    <Space size={4}>
      <Link to={domain ? `https://${domain}` : ''} data-cy="table-open-link">
        <IconButton name="fal fa-link" describe={t('ACCESSDEVICE')} />
      </Link>
      <OpenDeviceButton device={device} />
    </Space>
  )
}

const OpenDeviceButton = ({ device }: { device: IDevice }) => {
  const { setData } = useGenericContext(DeviceContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { open } = useDrawerContext(`/device/${device.uuid}`, device)
  const onClick = () => {
    setData(device)
    navigate(window.location.pathname)
    open()
  }
  return (
    <IconButton
      onClick={onClick}
      name="fa-light fa-info"
      describe={t('OPENDEVICE')}
    />
  )
}

const Disks = ({ disk }: { disk: IDiskController[] }) => {
  const diskItems = []
  for (const item of disk) {
    let totalCapacity = 0

    item?.layouts?.forEach(layouts => {
      totalCapacity += parseFloat(layouts.capacity)
    })

    diskItems.push(
      <Space direction="vertical" key={item.model}>
        {item?.layouts?.map(layouts => (
          <Space direction="horizontal" key={layouts.name}>
            <Text>{layouts.name}</Text>
            <Tag color="red">{`${layouts.capacity} GB`}</Tag>
          </Space>
        ))}
        <Tag color="green">Capacidade total: {totalCapacity} GB</Tag>
      </Space>
    )
  }

  return <>{diskItems}</>
}
export const renderingTabFilter = (uri: string) => {
  const { t } = useTranslation()

  const tabData = [
    { key: '1', label: t('ALL'), url: `${uri}` },
    { key: '2', label: `${t('BRA-SP1')}`, url: `${uri}&dcs=${BRASP}&` },
    { key: '3', label: `${t('BRA-SP2')}`, url: `${uri}&dcs=${BRASP2}&` },
    { key: '4', label: t('BRA-GO'), url: `${uri}&dcs=${BRAGO}&` },
    { key: '5', label: t('BRA-CE'), url: `${uri}&dcs=${BRACE}&` },
    { key: '6', label: t('BRA-PR'), url: `${uri}&dcs=${BRAPR}&` },
    { key: '7', label: t('USA-FL'), url: `${uri}&dcs=${USAFL}&` },
    { key: '8', label: t('USA-TX'), url: `${uri}&dcs=${USATX}&` }
  ]

  const renderingWithTab: TabsProps['items'] = tabData.map(item =>
    generateTabItem(item, columns)
  )

  return renderingWithTab
}
