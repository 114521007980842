import { useSectorTab } from '@components/Sector/Table'
import Title from '@components/common/Title'
import { Col, Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import SectorModal from '@components/Sector/Modal'
import Icon from '@components/common/Icon'
import { useTheme } from 'styled-components'

const SectorTabs = () => {
  const items = useSectorTab()
  return (
    <Tabs
      defaultActiveKey="0"
      items={items}
      destroyInactiveTabPane={true}
      type="card"
      style={{ width: '100%' }}
    />
  )
}

const Sectors = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <Row justify="space-between">
          <Title name={t('SECTORS')} />
          <SectorModal
            data-cy="create-sector-open-modal"
            style={{ backgroundColor: theme.green, color: theme.white }}
            icon={<Icon name="fa-light fa-plus" color="white" />}
          >
            {t('CREATESECTOR')}
          </SectorModal>
        </Row>
        <SectorTabs />
      </Col>
    </Row>
  )
}

export default Sectors
