import { useState, useEffect } from 'react'
import { Col, Form, Select, Radio } from 'antd'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import useWindowResize from '@hooks/useWindowResize'
import { TicketRadioProps } from '@/types/ITicketModalItems'
import { useTranslation } from 'react-i18next'

const RadioForAll = ({ name, data, required }: TicketRadioProps) => {
  const { width } = useWindowResize()
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(data?.category.slug)

  useEffect(() => {
    getGenericListsandFilter('ticket/category').then(e => setOptions(e))
  }, [])

  return (
    <Col xl={{ span: 12 }} xs={{ span: 24 }}>
      <Form.Item
        name={name}
        label={`${t(name)}:`}
        valuePropName="value"
        rules={
          required
            ? [{ required: true, message: t('requiredItem') }]
            : undefined
        }
      >
        {width > 720 ? (
          <Radio.Group
            size="middle"
            value={value}
            onChange={e => setValue(e.target.value)}
            optionType="button"
            buttonStyle="solid"
            options={options}
          />
        ) : (
          <Select style={{ width: '100%' }} options={options} />
        )}
      </Form.Item>
    </Col>
  )
}

export default RadioForAll
