import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Tag, Divider } from 'antd'
import CustomDescriptions from '@components/common/CustomDescriptions'
import { Text } from '@components/common/Text'
import Icon from '@components/common/Icon'
import { CustomDescriptionType } from '@/types/IDescriptions'
import { IHwcapacity } from '@/types/IDeviceHardware'
import { useDeviceHardwareContext } from '../HardwareContent'

const CapacityHardware = () => {
  const { t } = useTranslation()
  const { data } = useDeviceHardwareContext()
  const capacity = data?.hwcapacity || []

  return (
    <Col span={24}>
      <Divider>{t('HW_CAPACITY')}</Divider>
      <CustomDescriptions
        column={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }}
        columns={overviewColumnCapacity}
        dataSource={capacity}
      />
    </Col>
  )
}

export const overviewColumnCapacity: CustomDescriptionType<IHwcapacity>[] = [
  {
    label: 'U_SIZE',
    key: 'u_size'
  },
  {
    label: 'CPU_SOCKET_TYPE',
    key: 'cpu_socket_type'
  },
  {
    label: 'CPU_SOCKET_COUNT',
    key: 'cpu_socket_count'
  },
  {
    label: 'MEM_TYPE',
    key: 'mem_type'
  },
  {
    label: 'MEM_SLOTS_COUNT',
    key: 'mem_slots_count'
  },
  {
    label: 'MEM_MAX_UNIT',
    key: 'mem_max_unit'
  },
  {
    label: 'MEM_MAX_CAPACITY',
    key: 'mem_max_capacity'
  },
  {
    label: 'MEM_MAX_FREQ',
    key: 'mem_max_freq'
  },
  {
    label: 'MEM_FREQ_CURRENT',
    key: 'mem_freq_current'
  },
  {
    label: 'DISK_MAX_INCHES',
    key: 'disk_max_inches'
  },
  {
    label: 'DISK_MAX_SLOTS',
    key: 'disk_max_slots'
  },
  {
    label: 'DISK_MAX_CAPACITY',
    key: 'disk_max_capacity'
  },
  {
    label: 'PCI_MAX_SLOTS',
    key: 'pci_max_slots'
  },
  {
    label: 'DATASHEETS',
    key: 'datasheets',
    render: ({ data }) => {
      return (
        <>
          {data?.specs && (
            <Link to={`/${data.specs}`}>
              <Tag color="purple-inverse">
                <Text
                  style={{ maxWidth: '20ch', color: 'white' }}
                  ellipsis={{ tooltip: data.specs }}
                >
                  {data.specs}
                </Text>
                <Icon name="fa-light fa-external-link" color="white" />
              </Tag>
            </Link>
          )}
        </>
      )
    }
  }
]

export default CapacityHardware
