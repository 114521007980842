import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from '@common/Modal';
import { ModalContext } from '@contexts/ModalContext';
import { Button } from 'antd';

interface ModalFilterProps {
  isOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  onCancel: () => void;
  children: React.ReactNode;
}

function ModalFilter({ isOpen, setModalOpen, onCancel, children }: ModalFilterProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClearAndClose = () => {
    // Limpar item do localStorage e navega para a URL base ("/tickets")
    localStorage.removeItem('tickets');
    navigate('/tickets', { replace: true });

    // Fecha o modal
    onCancel();
  };

  return (
    <ModalContext.Provider value={{ open: isOpen, onCancel, onOpen: setModalOpen }}>
      <Modal
        title={t('FILTERS')}
        open={isOpen}
        onCancel={handleClearAndClose} 
        centered
        width="80%"
        height="auto"
        footer={[
          <Button key="submit" type="primary" onClick={onCancel}>
            OK
          </Button>
        ]}
      >
        <div style={{ display: 'flex' }}>{children}</div>
      </Modal>
    </ModalContext.Provider>
  );
}

export default ModalFilter;
