import { Form, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import TextInput from '@common/TextInput'

const GroupForm = ({ onFinish }: any) => {
  const { t } = useTranslation()
  return (
    <Form id='settings' onFinish={onFinish} layout='vertical'>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ flex: 1, marginRight: '16px' }}>
          <TextInput
            name='name'
            InputProps={{ type: 'name', placeholder: t('newGroup'), maxLength: 300 }}
          />
        </div>
        <Form.Item>
          <Button data-cy='submit-group' type='primary' htmlType='submit'>
              {t('CONFIRM')} 
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default GroupForm
