interface IOrderSteps {
  title: string
  content: string
}
export const ordersteps: IOrderSteps[] = [
  {
    title: 'DRAFT',
    content: 'MANAGER_APPROVED_MESSAGE'
  },
  {
    title: 'WAITING_MANAGER_APPROVAL',
    content: 'ORDER_APPROVED_MESSAGE'
  },
  {
    title: 'ORDER_APPROVED',
    content: 'CONTRACT_READY_MESSAGE'
  },
  {
    title: 'CONTRACT_READY',
    content: 'CONTRACT_SENT_MESSAGE'
  },
  {
    title: 'CONTRACT_SENT',
    content: 'CONTRACT_SIGNED_MESSAGE'
  },
  {
    title: 'CONTRACT_SIGNED',
    content: 'CONTRACT_DEPLOY_MESSAGE'
  },
  {
    title: 'DEPLOY',
    content: 'CONTRACT_DEPLOY_MESSAGE'
  }
]
