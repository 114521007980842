import Title from '@common/Title'
import { PlusOutlined } from '@ant-design/icons'
import { Col, Row, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import useFetch from '@hooks/useFetch'
import { IDc } from '@/types/IDc'
import { api } from '@helpers/api'
import RenderPage from '@components/common/RenderPage'
import { DcContextType } from '@/types/IDc'
import { DcContext } from '@contexts/DcContext'
import Summary from '@components/StatusProblems/Summary'
import { useGenericContext } from '@hooks/useGenericContext'
import { renderingWithTab } from '@components/StatusProblems/Table'
import { translateColumns } from '@helpers/translateColumns'
import ModalStatusProblem from '@components/StatusProblems/Modal'
import { useUser } from '@contexts/UserContext'

export const useDcContext = () => useGenericContext<DcContextType>(DcContext)

const StatusProblem = () => {
  const { data, loading, error } = useFetch<IDc, undefined>({
    func: () => api.get('/dc')
  })
  return (
    <RenderPage {...{ data, loading, error }}>
      <StatusProblemProvider data={data.data} />
    </RenderPage>
  )
}

const StatusProblemProvider = (props: DcContextType) => {
  return (
    <DcContext.Provider value={{ ...props }}>
      <StatusProblemContent />
    </DcContext.Provider>
  )
}

const CreateStatusProblem = () => (
  <ModalStatusProblem
    data-cy="create-status-problem"
    type="primary"
    action="create"
    shape="square"
    style={{ right: 50, zIndex: 1 }}
    icon={<PlusOutlined />}
  />
)

const StatusProblemContent = () => {
  const { t } = useTranslation()
  const renderWithTab = renderingWithTab()
  const { user } = useUser()
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Title name={t('DCS_STATUS')}></Title>
      </Col>
      <Col span={24}>
        <Summary />
      </Col>
      <Col span={24}>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={renderWithTab}
          type="card"
          size="large"
        />
      </Col>
      {user.level > 950 ? <CreateStatusProblem /> : null}
    </Row>
  )
}

export default StatusProblem
