export const TICKETSPRIORITIESCOLORS = {
  VERYLOW: { color: 'lime' },
  LOW: { color: 'green' },
  NORMAL: { color: 'gold-inverse' },
  HIGH: { color: 'orange-inverse' },
  VERYHIGH: { color: 'volcano-inverse' },
  URGENT: { color: 'red-inverse' },
  UNDEFINED: { color: 'gray' },
  UNKNOWN: { color: 'gray' }
}
