import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Select, Switch, Input } from 'antd'
import useForm from '@hooks/useForm'
import type { IOption } from '@/types/IOption'
import type { ICreateForm } from '@/types/ICreateForm'
import TextInput from '@common/TextInput'
import { IStatusProblem } from '@/types/IStatusProblem'
import { useDcContext } from '@pages/StatusProblem'
import { IDc } from '@/types/IDc'
import DatePicker from '@components/common/DatePicker'
import dayjs from 'dayjs'

const inputFields = ['title', 'problem']

interface CreateStatusProps extends ICreateForm {
  data: IStatusProblem | undefined
}

const setInititalValues = (data: IStatusProblem | undefined) => {
  if (data) {
    return { ...data, resolve_prediction_at: dayjs(data.resolve_prediction_at) }
  }
}

const FormStatusProblem = ({
  onClose,
  action,
  data,
  ...props
}: CreateStatusProps) => {
  const { t } = useTranslation()
  const { data: dcs } = useDcContext()
  const [form] = Form.useForm()
  const initialValues = setInititalValues(data)
  const close = () => {
    form.resetFields()
    onClose()
  }
  const { onFinish, disabled, contextHolder } = useForm(
    action,
    close,
    '/status/problem',
    data?.uuid
  )

  const renderSelectOptions = (dcs: IDc[]): IOption[] =>
    dcs.map(dc => ({
      value: dc.uuid,
      label: dc.shortname
    }))

  const customOnFinish = (values: IStatusProblem) => {
    if (values.resolved_at === null) {
      delete values.resolved_at
    }
    onFinish(values)
  }

  return (
    <Form
      {...props}
      form={form}
      disabled={disabled}
      layout="vertical"
      initialValues={initialValues}
      onFinish={customOnFinish}
    >
      {contextHolder}
      <Row gutter={12}>
        <Col key={'title'} xs={{ span: 24 }} xl={{ span: 12 }}>
          <TextInput
            key={'title'}
            label={t('TITLE')}
            name={'title'}
            rules={[{ required: true, message: t('requiredItem') }]}
          />
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 12 }}>
          <Form.Item
            rules={[{ required: true, message: t('requiredItem') }]}
            name={'dcs'}
            label={t('DCS')}
          >
            <Select
              style={{ width: '100%' }}
              options={renderSelectOptions(dcs)}
              mode="multiple"
            />
          </Form.Item>
        </Col>
        <Col key={'problem'} span={24}>
          <Form.Item
            label={t('PROBLEM')}
            name={'problem'}
            rules={[{ required: true, message: t('requiredItem') }]}
          >
            <Input.TextArea
              key={'problem'}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }}>
          <DatePicker name="resolve_prediction_at" />
        </Col>
        {action === 'edit' ? (
          <ResolveSwitch solved={data?.resolved_at} />
        ) : null}
      </Row>
    </Form>
  )
}

const ResolveSwitch = ({ solved }: { solved: string | null | undefined }) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()

  const handleSwitchChange = (checked: Boolean) => {
    const resolvedAtValue = checked
      ? dayjs().format('YYYY-MM-DD HH:mm:ss')
      : null
    form.setFieldsValue({ resolved_at: resolvedAtValue })
  }

  return (
    <Col xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }}>
      <Form.Item name="resolved_at" label={`${t('SOLVED')}?`}>
        <Switch
          checked={solved ? true : undefined}
          onChange={handleSwitchChange}
        />
      </Form.Item>
    </Col>
  )
}
export default FormStatusProblem
