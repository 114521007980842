import useModal from '@hooks/useModal'
import { Form, Row, Col, Input, Button, message } from 'antd'
import Modal from '@common/Modal'
import { IAutofill } from '@/types/IAutofill'
import InputWithForm from '@common/InputWithForm'
import { api } from '@helpers/api'
import IconButton from '@common/IconButton'
import { withTranslation, useTranslation } from 'react-i18next'
import { IconButtonProps } from '@/types/IconButtonProps'
import { TFunction } from 'i18next'
import { ModalContext } from '@contexts/ModalContext'
import MarkdownInput from '@components/common/MarkdownInput'

interface ModalAutofillProps extends IconButtonProps {
  t: TFunction
  uuid?: string
  data?: IAutofill
  mode: 'edit' | 'create'
  onCancel: () => void
}

const onRequest = (
  uuid: string | undefined,
  values: IAutofill,
  mode: 'edit' | 'create'
) => {
  if (mode === 'create') {
    return api.post('ticket/autofill', values)
  }
  return api.put(`ticket/autofill/${uuid}`, values)
}

const ModalContentAutofill = withTranslation()(({
  t,
  data,
  mode,
  onCancel
}: Omit<ModalAutofillProps, 'uuid' | 'name'>) => {
  const [form] = Form.useForm()

  const handleFinish = async (values: IAutofill) => {
    try {
      await onRequest(data?.uuid, values, mode)
      message.success(t('SUCCESSAUTOFILL'))
      onCancel()
    } catch (error) {
      message.error(t('ERRORAUTOFILL'))
    }
  }

  return (
    <Form
      name="autofill"
      layout="vertical"
      initialValues={data}
      form={form}
      onFinish={handleFinish}
    >
      <Row style={{ width: '100%' }}>
        <Col {...{ span: 24 }}>
          <InputWithForm required name="title" label={t('NAME')} />
        </Col>
        <Col span={24}>
          <Form.Item label={t('CONTENT')}>
            <MarkdownInput
              name="content"
              rules={[{ required: true, message: t('requiredItem') }]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})

const ModalAutofill = ({
  data,
  mode,
  ...props
}: Omit<ModalAutofillProps, 't' | 'onCancel'>) => {
  const { t } = useTranslation()
  const { open, onOpen, onCancel } = useModal()
  return (
    <ModalContext.Provider value={{ open, onOpen, onCancel }}>
      <Modal
        footer={
          <Button
            data-cy="create-autofill"
            type="primary"
            form="autofill"
            htmlType="submit"
          >
            {t(mode === 'create' ? 'CREATE' : 'EDIT')}
          </Button>
        }
        title={mode === 'create' ? t('CREATEAUTOFILL') : t('EDITAUTOFILL')}
        {...{ open, onOpen, onCancel }}
      >
        <ModalContentAutofill {...{ data, mode, onCancel }} />
      </Modal>
      <IconButton {...props} onClick={onOpen} />
    </ModalContext.Provider>
  )
}

export default ModalAutofill
