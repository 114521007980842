import styled from 'styled-components'

export const Logo = styled.img`
  width: 70%;
  margin-bottom: 10px;
`
export const Image = styled.img`
  width: 20vh;
  margin-top: 30px;
`
