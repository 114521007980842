import { ISUser } from '@/types/IUser'
import { TicketSelectProps } from '@/types/ITicketModalItems'
import { useUser } from '@contexts/UserContext'
import { Row, Select, Badge, Tag, Typography } from 'antd'
import { SearchSelectWithForm } from '@common/SearchSelectWithForm'
import useSearchSelect from '@hooks/useSearchSelect'
import { getSUsers } from '@helpers/getSUsers'

const { Text } = Typography
const { Option } = Select

export const SUserOption = ({ suser, ...props }: { suser: ISUser }) => {
  return (
    <>
      <Row justify="space-between" align="middle">
        <Text>{suser.name}</Text>
        <Badge
          count={suser.tickets_open_count}
          color={suser.tickets_open_count !== 0 ? 'blue' : 'red'}
          showZero
        />
      </Row>
      <Row>
        {suser?.sectors?.length
          ? suser.sectors.map(e => (
              <Tag key={e.sector?.name} color="blue">
                {e.sector?.name}
              </Tag>
            ))
          : null}
      </Row>
    </>
  )
}

const SUsersSelect = ({
  data,
  span = 8,
  ...props
}: Omit<TicketSelectProps, 'name'>) => {
  const { user } = useUser()
  const { loading, options, onSearch, onFocus } = useSearchSelect({
    initialValue: data?.suser ? [data?.suser] : [user],
    func: getSUsers
  })
  return (
    <SearchSelectWithForm
      {...props}
      {...{ span, loading }}
      name="assign_to"
      optionLabelProp="label"
      onSearch={onSearch}
      onFocus={onFocus}
    >
      {!!options
        ? options.map((option, index) => (
            <Option key={index} value={option.uuid} label={option.name}>
              <SUserOption suser={option} key={index} />
            </Option>
          ))
        : null}
    </SearchSelectWithForm>
  )
}

export default SUsersSelect
