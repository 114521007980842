import { useState, useEffect, SetStateAction, Dispatch } from 'react'
import type { ITicket } from '@/types/ITicket'
type CalcTimeProps = {
  data: ITicket
  setCurrent: Dispatch<SetStateAction<number>>
  setDiff: Dispatch<SetStateAction<number | null>>
}
const calcTimeWithReload = ({ data, setCurrent, setDiff }: CalcTimeProps) => {
  setInterval(() => calcTime({ data, setCurrent, setDiff }), 1000)
}

const calcTime = ({ data, setCurrent, setDiff }: CalcTimeProps) => {
  if (data.total_time) {
    setCurrent(prevCurrent => prevCurrent + 1)
    setDiff(prevDiff => (prevDiff as number) - 1)
  } else {
    const now = Date.now() / 1000
    const created = new Date(data.created_at).getTime() / 1000
    setCurrent(now - created - (data.plannedpending_time as number))
    setDiff(
      ((data.expected_time as number) || 0) -
        (now - created - (data.plannedpending_time as number))
    )
  }
}

const calcTimeAsSolved = ({ data, setCurrent, setDiff }: CalcTimeProps) => {
  setCurrent(data.total_time || 0)
  data.total_time
    ? setDiff((data.expected_time || 0) - Math.abs(data.total_time))
    : setDiff(null)
}

const funcsByStatus = {
  NEW: calcTimeWithReload,
  PENDING: calcTime,
  PROCESSING_PLANNED: calcTime,
  PROCESSING_ATTRIBUTED: calcTimeWithReload,
  SOLVED: calcTimeAsSolved,
  CLOSED: calcTimeAsSolved,
  UNKNOWN: calcTimeAsSolved,
  UNDEFINED: calcTimeAsSolved
}

const useTicketTime = (data: ITicket) => {
  const [current, setCurrent] = useState(() => {
    let now = Math.abs(Date.now() / 1000)
    let created = Math.abs(new Date(data.created_at).getTime() / 1000)
    let current = Math.floor(now - created - Number(data.plannedpending_time))
    return current
  })
  const [diff, setDiff] = useState<number | null>(
    data.total_time ? (data.expected_time || 0) - data.total_time : null
  )

  useEffect(() => {
    data && funcsByStatus[data?.status]({ data, setCurrent, setDiff })
  }, [])

  return { current, diff }
}

export default useTicketTime
