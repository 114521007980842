import { Col, Space, Card, Flex } from 'antd'
import dayjs from 'dayjs'
import { IFollowup } from '@/types/IFollowup'
import { useTheme } from 'styled-components'
import TextMarkdown from '@components/common/TextMarkdown'
import { DATEFORMAT } from '@constants/dateformat.js'

const FollowupList = ({ followup }: { followup: IFollowup }) => {
  const theme = useTheme()
  return (
    <Col key={followup?.uuid} span={24}>
      <Card
        style={{
          backgroundColor:
            (followup.is_private && theme.yellowDark) ||
            (followup?.user?.level !== 1 ? theme.blueDark : theme.blue),
          color: theme.white
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <TextMarkdown color="white" text={`**${followup?.user?.name}**`} />
          <TextMarkdown text={followup.followup} color="white" />
          <FollowupTime time={followup.updated_at ?? followup.created_at} />
        </Space>
      </Card>
    </Col>
  )
}

const FollowupTime = ({ time }: { time: string }) => (
  <Flex justify={'flex-end'} align={'flex-end'}>
    {dayjs(time).format(DATEFORMAT)}
  </Flex>
)

export default FollowupList
