import { Col, Row, Select, Space } from 'antd'
import GroupButton from '@components/Device/GroupButton'
import useSearchParams from '@hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { mapCleanFormatFilter } from '@helpers/MapCleanFormatFilter'
import useSearchSelect from '@hooks/useSearchSelect'
import { api } from '@helpers/api'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { SearchParams } from '@/types/SearchParams'

const getGroups = async (search = '') => {
  return api
    .get(`/group?search=${search}&perPage=999`)
    .then(e => mapCleanFormatFilter(e.data, 'uuid', 'name'))
}

const handleChange = (values: string[], params: SearchParams) => {
  if (values.length === 0) params.removeItem('groups')
  params.setItem('groups', values.toString())
}
const FilterDeviceByGroupSelect = () => {
  const params = useSearchParams(true)
  const location = useLocation()
  const storageGroups = params.getItem('groups')
  const { loading, options, onSearch, onFocus } = useSearchSelect({
    func: getGroups
  })

  useEffect(() => {
    if (storageGroups) onSearch(storageGroups)
  }, [location.search])

  const { t } = useTranslation()
  return (
    <Select
      {...{ loading, options, onFocus, onSearch }}
      mode="multiple"
      allowClear
      filterOption={false}
      value={storageGroups?.split(',')}
      placeholder={t('GROUP')}
      onClear={() => params.removeItem('groups')}
      onChange={values => handleChange(values, params)}
      style={{ width: '100%' }}
    />
  )
}

const Filter = () => {
  return (
    <>
      <Col xs={{ span: 24 }} xl={{ span: 12 }}>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          <Col xl={{ span: 18 }} xs={24}>
            <FilterDeviceByGroupSelect />
          </Col>
          <Col xl={{ span: 6 }} xs={24}>
            <GroupButton />
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default Filter
