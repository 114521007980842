import { useEffect, useRef, useState } from 'react'
import { Col, Form, Select, Space, Typography } from 'antd'
import { ConstantProps } from '@/types/ITicketModalItems'
import { useTranslation } from 'react-i18next'
import MarkdownInput from '@components/common/MarkdownInput'
import { api } from '@helpers/api'
import { mapCleanFormatFilter } from '@helpers/MapCleanFormatFilter'
import { TICKETLIMITS } from '@constants/ticketlimits'
import useDebounce from '@hooks/useDebounce'

const { Text } = Typography

const selectAttrs = { showSearch: true, allowClear: true }
const useAutofill = () => {
  const initialRef = useRef(true)
  const [options, setOptions] = useState<ConstantProps>()
  const [search, setSearch] = useState('')
  const { debouncedValue } = useDebounce(search)

  useEffect(() => {
    if (!initialRef.current) {
      api
        .get(`ticket/autofill?search=${debouncedValue}`)
        .then(e => mapCleanFormatFilter(e.data, 'content', 'title'))
        .then(e => setOptions(e))
    }
  }, [debouncedValue])

  const onSearch = (search = '') => {
    initialRef.current = false
    setSearch(search)
  }

  return { options, onSearch }
}
const AutoFillForAll = ({ client = false, isFollowup = false }) => {
  const form = Form.useFormInstance()
  const { options, onSearch } = useAutofill()
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Text>
          <Text type="danger">* </Text>
          {isFollowup ? t('FOLLOWUP') : t('CONTENT')}
        </Text>
        {!client ? (
          <Select
            data-cy="modal-ticket-support-autofill"
            placeholder={t('TYPETOSEARCH')}
            {...selectAttrs}
            style={{ width: '100%' }}
            onFocus={() => onSearch('')}
            filterOption={false}
            onSelect={e =>
              form.setFieldsValue({ [isFollowup ? 'followup' : 'content']: e })
            }
            onSearch={onSearch}
            options={options}
          />
        ) : null}
        <MarkdownInput
          rules={TICKETLIMITS.map(e => ({ ...e, message: t(e.message) }))}
          name={isFollowup ? 'followup' : 'content'}
        />
      </Space>
    </Col>
  )
}
export default AutoFillForAll
