const powered = {
  poweredOff: 'red',
  poweredOn: 'green',
  suspended: 'gray'
}

export const modeOn = [
  {
    label: 'CONSOLE',
    key: 'console'
  },
  {
    label: 'POWEROFFDEVICE',
    key: 'poweroff'
  },
  {
    key: 'reset',
    label: 'RESETDEVICE'
  }
]

export const modeOff = [
  {
    key: 'poweron',
    label: 'POWERONDEVICE'
  }
]

export default powered
