import { SyncOutlined } from '@ant-design/icons'
import { ICompany } from '@/types/ICompany'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { MessageContext } from '@contexts/MessageContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { Col, Form, Row, Tag, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Text = Typography.Text

const useCompaniesList = () => {
  const entity = Form.useWatch('entity')
  const [companies, setCompanies] = useState<ICompany[] | []>([])
  const messageApi = useGenericContext(MessageContext)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (entity) {
      setLoading(true)
      api
        .get(`companiesByEntity/${entity}`)
        .then(e => setCompanies(e.data.data))
        .catch(e => handleError(e, messageApi, 'LOADCOMPANYERROR'))
        .finally(() => setLoading(false))
    }
  }, [entity])
  return { companies, loading }
}

const CompaniesList = () => {
  const { companies, loading } = useCompaniesList()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <Tag icon={<SyncOutlined spin />} color="blue-inverse" />
      ) : (
        <Row>
          <Col span={24}>
            <Text>{companies.length ? t('COMPANIES') : null}</Text>
          </Col>
          {companies.map(e => (
            <Tag color="blue-inverse" key={e.uuid}>
              {e.name}
            </Tag>
          ))}
        </Row>
      )}
    </>
  )
}

export default CompaniesList
