import DeviceIcons from '@components/Device/Icons'
import { IRequestList } from '@/types/ICollection'
import { IDevice } from '@/types/IDevice'
import { api } from '@helpers/api'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import useTicketSelect from '@hooks/useTicketSelect'

const getDevices = async (search = '') => {
  return api
    .get<IRequestList<IDevice[]>>(`/device?search=${search}`)
    .then(e => e.data.data)
}
const DeviceFilterSelect = () => {
  const { options, ...props } = useTicketSelect({
    name: 'device',
    func: getDevices,
  })
  const { t } = useTranslation()
  return (
    <Select
      {...props}
      mode="multiple"
      allowClear
      showSearch
      placeholder={t('FILTERBYDEVICE')}
      data-cy="filterDevice"
      filterOption={false}
      style={{ width: '100%' }}
      options={options?.map((device: IDevice) => ({
        value: device.uuid,
        label: (
          <>
            {device.os ? <DeviceIcons kind={device.os.kind} /> : null}
            {device.name}
          </>)
      }))}
    />
  )
}

export default DeviceFilterSelect
