import { Pool } from '@/types/AutoDeploy'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState
} from 'react'

export type PoolContextType = {
  pool: Pool | undefined
  setPool: Dispatch<SetStateAction<Pool | undefined>>
}
export const PoolContext = createContext<PoolContextType | undefined>(undefined)

const PoolProvider = ({ children }: PropsWithChildren) => {
  const [pool, setPool] = useState<Pool | undefined>()
  return (
    <PoolContext.Provider value={{ pool, setPool }}>
      {children}
    </PoolContext.Provider>
  )
}

export default PoolProvider
