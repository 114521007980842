import { useTranslation } from 'react-i18next'
import SubTitle from '@common/SubTitle'
import { columns as ticketColums } from '@components/Ticket/Table'
import { columns as deviceColums } from '@components/Device/Table'
import { columns as alertColums } from '@components/Monitoring/Table'
import useTable from '@hooks/useTable'
import useWindowResize from '@hooks/useWindowResize'
import Table from '@components/common/Table'
import Card from '@components/common/Card'

const tables = [
  {
    name: 'device',
    columns: deviceColums
  },
  {
    name: 'ticket',
    columns: ticketColums
  },
  {
    name: 'devicealert',
    columns: alertColums
  }
]

interface SearchTableProps {
  name: string
  columns: any
}
const SearchTable = ({ name, columns }: SearchTableProps) => {
  const { t } = useTranslation()
  const { width } = useWindowResize()
  const Tag: any = width > 768 ? Table : Card
  const props = useTable(`/${name}`)
  return (
    <>
      <SubTitle>{t(('RESULTOF' + name + 'S').toUpperCase())}</SubTitle>
      <Tag columns={columns} {...props} />
    </>
  )
}

const Search = () => {
  return (
    <>
      {tables.map(e => (
        <SearchTable {...{ ...e }} />
      ))}
    </>
  )
}

export default Search
