import { useState } from 'react'
import { Col, Form, Select, Tag, Space } from 'antd'
import { ITicket } from '@/types/ITicket'
import { api } from '@helpers/api'
import useSearchSelect from '@hooks/useSearchSelect'
import { useTranslation } from 'react-i18next'
import { IDevice } from '@/types/IDevice'
import DeviceIcons from '@components/Device/Icons'
import { useEffect } from 'react'

const selectAttrs = { showSearch: true, allowClear: true }
const { Option } = Select

const useDeviceSelect = (data: ITicket | undefined) => {
  const company = Form.useWatch('company')
  const [devices, setDevices] = useState(data?.devices?.map(e => e.device))

  useEffect(() => {
    handleCompany()
  }, [company])

  const handleCompany = async () => {
    if (company) setDevices(await searchDevices())
  }

  const searchDevices = async (search = '') => {
    return await api
      .get(`device?search=${search}&company=${company || ''}`)
      .then(e => {
        return e.data.data
      })
  }
  const { options, loading, onFocus, onSearch } = useSearchSelect({
    initialValue: devices,
    func: searchDevices,
    param: devices
  })
  return { options, loading, onFocus, onSearch }
}

const DeviceSelectForAll = ({
  data,
  span = 12
}: {
  data?: ITicket
  span?: number
}) => {
  const { options, loading, onFocus, onSearch } = useDeviceSelect(data)
  const { t } = useTranslation()

  return (
    <Col xs={{ span: 24 }} xl={{ span: span }}>
      <Form.Item
        name="devices"
        label={`${t('devices')}:`}
        rules={[{ required: true, message: t('requiredItem') }]}
        shouldUpdate
      >
        <Select
          placeholder={t('DEVICECHOOSE')}
          {...selectAttrs}
          {...{ loading, onFocus, onSearch }}
          style={{ width: '100%' }}
          mode="multiple"
          optionLabelProp="label"
          filterOption={false}
          data-cy="device-select-edit"
        >
          {options?.map((e: IDevice) => (
            <Option
              value={e.uuid}
              label={e.name}
              key={e.uuid}
              data-cy={`modal-ticket-devices-${e.name}`}
            >
              <Space>
                {e.os ? <DeviceIcons kind={e.os.kind} /> : null}
                {e.name}
                {e.alias ? <Tag color="blue-inverse">{e.alias}</Tag> : null}
                {e.company?.name ? (
                  <Tag color="purple">{e.company.name}</Tag>
                ) : null}
              </Space>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default DeviceSelectForAll
