import { Dispatch, SetStateAction, useState } from 'react'
import { Space, Button } from 'antd'
import Icon from '@components/common/Icon'
import OrderDrawer from '../OrderDrawer'
import IconButton from '@components/common/IconButton'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import type { IOrder } from '@/types/IOrder'
import { useTheme } from 'styled-components'
import DeleteButton from '@components/common/DeleteButton'
import { OrderContext } from '@contexts/OrderContext'

interface ActionsItemsProps {
  data?: IOrder
  setAction?: Dispatch<SetStateAction<string>>
}

const Actions = ({ data }: { data: IOrder }) => {
  const [action, setAction] = useState<string>('edit')
  const numForm = Math.floor(Math.random() * 10000).toString()
  const { t } = useTranslation()

  return (
    <Space>
      <OrderDrawer order={data} action={action} numForm={numForm}>
        <ActionsItems data={data} setAction={setAction} />
      </OrderDrawer>
      <DeleteButton
        url={`order/${data.uuid}`}
        uuid={data.uuid}
        name={`${t('ORDERDELETE')} ${data.customer?.name}`}
      />
    </Space>
  )
}

export const ActionsItems = ({ data, setAction }: ActionsItemsProps) => {
  const theme = useTheme()
  const { openDrawer } = useGenericContext(OrderContext)
  const { t } = useTranslation()
  const onClick = (action: string) => {
    openDrawer(data)
    setAction && setAction(action)
  }
  return (
    <>
      {data ? (
        <Space>
          <IconButton
            name="fa-light fa-plus"
            describe={t('OPEN_ORDER')}
            data-cy="table-open-order"
            onClick={() => onClick('show')}
          />
          {data.status !== 'DEPLOY' && (
            <IconButton
              name="fa-light fa-edit"
              describe={t('EDIT_ORDER')}
              data-cy="table-edit-order"
              onClick={() => onClick('edit')}
            />
          )}
        </Space>
      ) : (
        <Button
          block
          children={t('CREATE_ORDER')}
          icon={<Icon name="fa-regular fa-file-plus" color="white" />}
          style={{ backgroundColor: theme.green, color: theme.white }}
          onClick={() => onClick('create')}
          data-cy="create-order"
        />
      )}
    </>
  )
}

export default Actions
