import ReactPlayer from 'react-player'
import { IAttachment } from '@types'
import { Col } from 'antd'

const API_URL = import.meta.env.VITE_OPEN_URL

const FollowupVideo = ({ attachment }: { attachment: IAttachment }) => {
  return (
    <ReactPlayer
      url={`${API_URL}${attachment.path}`}
      controls
      width={'100%'}
      height={'100%'}
    />
  )
}

export default FollowupVideo
