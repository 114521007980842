import ReactAudioPlayer from 'react-audio-player'
import { IAttachment } from '@types'

const API_URL = import.meta.env.VITE_OPEN_URL

const FollowupAudio = ({ attachment }: { attachment: IAttachment }) => {
  return (
    <ReactAudioPlayer
      src={`${API_URL}${attachment.path}`}
      controls
      style={{ width: '100%' }}
    />
  )
}

export default FollowupAudio
