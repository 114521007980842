export const images = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.webp',
  '.tiff',
  '.ico',
  '.heif'
]

export const videos = [
  '.mp4',
  '.avi',
  '.mov',
  '.mkv',
  '.webm',
  '.flv',
  '.wmv',
  '.mpeg',
  '.mpg',
  '.ogv'
]

export const docs = [
  '.doc',
  '.docx',
  '.rtf',
  '.odt',
  '.xls',
  '.xlsx',
  '.csv',
  '.ppt',
  '.pptx',
  '.txt',
  '.html',
  '.zip',
  '.rar',
  '.gzip',
  '.sh'
]

export const audios = [
  '.mp3',
  '.wav',
  '.ogg',
  '.flac',
  '.aac',
  '.m4a',
  '.wma',
  '.alac',
  '.aiff',
  '.opus'
]
