import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Button, Row, Col } from 'antd'
import Title from '@components/common/Title'
import { useTranslation } from 'react-i18next'
import { useGenericContext } from '@hooks/useGenericContext'
import { OrderContext } from '@contexts/OrderContext'
import type { IOrder, IItemOrderRequest } from '@/types/IOrder'
import useTableContext from '@hooks/useTableContext'

const calculatePrice = (
  data: IOrder | undefined,
  setTotalPrice: Dispatch<SetStateAction<number>>
) => {
  let total = 0

  const calculateItemPrice = (item: IItemOrderRequest) => {
    if (item.children?.items?.length > 0) {
      item.children.items.forEach(child => {
        const selectedChildItem = item?.item?.children.find(
          (order: any) => order.uuid === child?.item.uuid
        )
        if (selectedChildItem) {
          const current = child.current ? child.current : 1
          total += current * selectedChildItem.price
        }
      })
    } else {
      total += checkBoolItem(item)
    }
  }
  data?.items?.map(item => {
    item?.order_request?.items?.map(order => {
      calculateItemPrice(order)
    })
  })
  if (data?.discount_percents) {
    total = total - (total * data.discount_percents) / 100
  }
  setTotalPrice(total)
}

const checkBoolItem = (item: IItemOrderRequest): number => {
  if (item.item.type !== 'ITEM' && item.is_used !== true) return 0
  else return item.current * item.item.price
}

const FormHead = () => {
  const { t } = useTranslation()
  const { numForm, data } = useGenericContext(OrderContext)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  useEffect(() => {
    calculatePrice(data, setTotalPrice)
  }, [data])
  return (
    <Row style={{ marginTop: '10px' }}>
      <Col xl={18} xs={16}>
        <Title
          data-cy="total-price"
          name={`${t('TOTAL_PRICE')}: R$${totalPrice?.toFixed(2)}`}
        />
      </Col>
      <Col xl={{ span: 2, offset: 4 }} xs={4}>
        <Button
          children={t('SAVE_ORDER')}
          type="primary"
          form={numForm}
          key="submit"
          htmlType="submit"
        />
      </Col>
    </Row>
  )
}

export default FormHead
