import {
  Button,
  ButtonProps,
  Col,
  Descriptions,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag
} from 'antd'
import useModal from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import useSearchSelect from '@hooks/useSearchSelect'
import { api } from '@helpers/api'
import { IProduct } from '@/types/IOrder'
import { IProductItem } from '@/types/IProduct'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ProductItemTable } from '../Table'
import { DCS } from '@constants/dc'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Text } from '@components/common/Text'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import { handleError } from '@helpers/handleError'
import useTableContext from '@hooks/useTableContext'
import ProductForm from '../Form'
import HiddenFormItem from '@components/common/HiddenFormItem'
import { ModalContext } from '@contexts/ModalContext'
import { get } from 'lodash'
import verifyPutOrPost from '@helpers/verifyPutOrPost'

const Option = Select.Option

interface ProductModal extends ButtonProps {
  data?: IProduct | undefined
}

const formatInitialValues: any = (data: IProduct | undefined) => {
  if (data)
    return {
      ...data,
      dc: data?.dc?.uuid
    }
}

const SubmitButton = (props: ButtonProps) => {
  return (
    <Form.Item noStyle>
      <Button htmlType="submit" {...props} />
    </Form.Item>
  )
}
const useProductModal = () => {
  const { open, onClose, onOpen } = useModal()
  const drawerChildrenProps = { open, onClose }
  const onFinish = () => {
    onOpen()
  }
  return { onFinish, drawerChildrenProps }
}

const getDCnameByUuid = (uuid: string) => {
  for (let i in DCS) {
    if (DCS[i as keyof typeof DCS] === uuid) return i
  }
}

const handleProductItems = async (
  items: IProductItem[] | null,
  data: any,
  product: string,
  parent?: string
) => {
  if (items?.length) {
    items?.forEach(async item => {
      const { uuid, children, ...itemValues } = item
      const modifiedValues = { ...itemValues, parent: parent }
      const action = uuid
        ? async () =>
            api.put(`/product/${product}/item/${uuid}`, modifiedValues)
        : async () => api.post(`/product/${product}/item`, modifiedValues)

      await action().then(e => {
        handleProductItems(children, data, product, e.data.uuid)
      })
    })
  }
}

const updateProductValues = async (values: any, data: IProduct) => {
  const { items, uuid, ...productValues } = values
  await api.put(`/product/${data.uuid}`, productValues).then(async e => {
    await handleProductItems(items, data.items, data.uuid)
  })
}

const useChildrenDrawer = (data: IProduct | undefined) => {
  const { onClose } = useGenericContext(ModalContext)
  const messageApi = useGenericContext(MessageContext)
  const update = verifyPutOrPost(data)
  const action = data
    ? async (values: any) => await updateProductValues(values, data)
    : async (values: any) =>
        await api.post('/product', values).then(e => {
          return e
        })
  const onConfirm = async (values: any) => {
    try {
      await action(values).then(async () => {
        messageApi.success(update)
        onClose && onClose()
      })
    } catch (e) {
      handleError(e, messageApi, 'error')
    }
  }
  return onConfirm
}

interface ChildrenDrawerProps extends DrawerProps {
  data?: IProduct
}

const ChildrenDrawer = ({ data, ...props }: ChildrenDrawerProps) => {
  const onConfirm = useChildrenDrawer(data)
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const values = form.getFieldsValue()

  return (
    <Drawer
      {...props}
      width={'70%'}
      title={values ? values.name : null}
      extra={
        <Button onClick={() => onConfirm(values)} data-cy="send-values-button">
          {t('CONFIRM')}
        </Button>
      }
    >
      {values ? (
        <>
          <Descriptions
            bordered
            items={['name', 'dc'].map(e => ({
              key: e,
              label: t(e.toUpperCase()),
              children: e !== 'dc' ? values[e] : getDCnameByUuid(values[e])
            }))}
          />
          <Divider>{t('ITEMS')}</Divider>
          <ProductItemTable record={values} />
        </>
      ) : null}
    </Drawer>
  )
}

interface ProductValuesSelectProps {
  value: IProduct | undefined
  setValue: Dispatch<SetStateAction<IProduct | undefined>>
}

const ProductValuesSelect = ({ value, setValue }: ProductValuesSelectProps) => {
  const { t } = useTranslation()
  const form = useFormInstance()
  const { options, ...props } = useSearchSelect<IProduct[], any>({
    func: async (search = '') =>
      await api
        .get(`/product?search=${search}`)
        .then(e => e.data.data as IProduct[])
  })

  const onSelect = (item: IProduct['uuid']) => {
    let selectedOption = options?.filter(option => option.uuid === item)[0]
    if (selectedOption) {
      selectedOption = {
        ...selectedOption,
        dc: selectedOption.dc.uuid
      }
      form.setFieldsValue(selectedOption)
      setValue(selectedOption)
    }
  }

  return (
    <Row style={{ marginLeft: '8px' }} align="middle">
      <Text>{value ? t('CLONEDBY') + ':' : null}</Text>
      <Select
        data-cy="clone-product-select"
        value={value?.uuid}
        {...props}
        placeholder={t('CLONEBYEXISTINGPRODUCT')}
        onSelect={onSelect}
      >
        {options?.map(product => (
          <Option key={product.uuid} label={product.name}>
            <Space>
              {product.name}
              <Tag color="blue-inverse">{product.dc.shortname}</Tag>
            </Space>
          </Option>
        ))}
      </Select>
    </Row>
  )
}

const DrawerHeader = ({ data }: Pick<ProductModal, 'data'>) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<IProduct | undefined>(undefined)
  const form = Form.useFormInstance()
  return (
    <Space>
      <Col>{data ? <HiddenFormItem name="uuid" /> : null}</Col>
      <SubmitButton
        onClick={() => form.submit()}
        type="primary"
        data-cy="confirm-values-button"
      >
        {t('CONFIRMVALUES')}
      </SubmitButton>
      <Col span={24}>
        <ProductValuesSelect {...{ ...{ value, setValue } }} />
      </Col>
    </Space>
  )
}

const ProductModal = ({ data, ...props }: ProductModal) => {
  const { onFinish, drawerChildrenProps } = useProductModal()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { open, onClose, onOpen } = useModal()
  return (
    <ModalContext.Provider value={{ open, onClose, onOpen }}>
      <Form
        form={form}
        initialValues={formatInitialValues(data)}
        onFinish={onFinish}
        layout="vertical"
      >
        <Drawer
          width={'100%'}
          open={open}
          onClose={onClose}
          extra={<DrawerHeader data={data} />}
          title={
            !data ? t('CREATEPRODUCT') : t('UPDATEPRODUCT') + ' ' + data?.name
          }
        >
          <ProductForm />
          <ChildrenDrawer {...drawerChildrenProps} data={data} />
        </Drawer>
        <Button {...props} onClick={onOpen} />
      </Form>
    </ModalContext.Provider>
  )
}

export default ProductModal
