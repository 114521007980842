const getDirection = (key: string) => {
  const params = window.localStorage.getItem('tickets')
  const sortParams = params && new URLSearchParams(params).get('sort')
  const sort = sortParams?.replace('-', '')
  const order = sortParams && sortParams.includes('-')
  const direction = order === true ? 'descend' : 'ascend'
  if (!sortParams || sort !== key) return null
  return direction
}

export const setSortOrder = (columns: any) => {
  columns.forEach(obj => {
    if (obj.sorter) {
      obj.defaultSortOrder = getDirection(obj.key)
    }
  })
  return columns
}
