import { PropsWithChildren } from 'react'
import useWindowResize from '@hooks/useWindowResize'
import { Typography } from 'antd'
import Marquee from 'react-fast-marquee'
const { Text } =  Typography

interface ResponsiveMarqueeProps extends PropsWithChildren {
  speed: number
}

const ResponsiveMarquee = ({children,...props}: ResponsiveMarqueeProps) => {
  const {width} = useWindowResize()
  const Tag = width < 720 ? Marquee : Text
  return (
    <Tag {...props}>
      {children}
    </Tag>
  )
}

export default ResponsiveMarquee
