import type { ColumnsType } from 'antd/es/table'
import { Tag, Typography } from 'antd'
import type { TabsProps } from 'antd'
import Icon from '@common/Icon'
import { generateTabItem } from '@common/Tab'
import { DATEFORMAT } from '@constants/dateformat'
import dayjs from 'dayjs'
import { IDevice } from '@/types/IDevice'
import DeviceDrawer from '@components/Device/DeviceDrawer'
import { DeviceContext } from '@contexts/DeviceContext'
import { useGenericContext } from '@hooks/useGenericContext'
import { useDrawerContext } from '@contexts/DrawerContext'
import TranslateText from '@components/common/TranslateText'
const { Text } = Typography

interface IMonitoring {
  title: string
  problem_at: string
  device: IDevice
  resolved_at: string | null
  severity: 'WARNING'
}

const DeviceTag = () => {
  const { data } = useGenericContext(DeviceContext)
  const { open } = useDrawerContext(`/device/${data.uuid}`, data)
  return (
    <button onClick={open}>
      <Tag color="blue-inverse" key={data.uuid}>
        {data.name} <Icon name="fa-light fa-external-link" color="white" />
      </Tag>
    </button>
  )
}

export const columns: ColumnsType<IMonitoring> = [
  {
    title: 'DATE',
    dataIndex: 'problem_at',
    key: 'problem_at',
    render: problem_at => <Text>{dayjs(problem_at).format(DATEFORMAT)}</Text>
  },
  {
    title: 'DEVICE',
    dataIndex: 'device',
    key: 'device',
    render: device => (
      <DeviceDrawer device={device}>
        <DeviceTag />
      </DeviceDrawer>
    )
  },
  {
    title: 'PROBLEM',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'SEVERITY',
    key: 'severity',
    dataIndex: 'severity',
    render: severity => <TranslateText name={severity}/>
  },
  {
    title: 'STATUS',
    key: 'resolved_at',
    dataIndex: 'resolved_at',
    render: resolved_at => {
      return (
        <>
          {!resolved_at ? (
            <Tag color="red" key="UNSOLVED">
              <TranslateText name={"UNSOLVED"}/>
            </Tag>
          ) : (
            <Tag color="green" key="SOLVED">
              <TranslateText name="SOLVED"/>
            </Tag>
          )}
        </>
      )
    }
  }
]

export const renderingWithTab: TabsProps['items'] = [
  generateTabItem({ key: '1', label: 'ALL', url: '/devicealert' }, columns),
  generateTabItem(
    { key: '2', label: 'SOLVED', url: '/devicealert?resolved=true' },
    columns
  ),
  generateTabItem(
    { key: '3', label: 'UNSOLVED', url: '/devicealert?resolved=false' },
    columns
  )
]
