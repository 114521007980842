import { Form, Col, Space, Switch, Typography, Row } from 'antd'
import { FormInstance } from 'antd'
import { useGenericContext } from '@hooks/useGenericContext'
import { ZoneRecord } from '@/types/IDns'
import { DnsContext } from '@contexts/DnsContext'
import { SelectInput } from '@components/common/SelectInput'
import useForm from '@hooks/useForm'
import { ModalContext } from '@contexts/ModalContext'
import InputWithForm from '@components/common/InputWithForm'
import { useTranslation } from 'react-i18next'

interface ZoneRecordProps {
  id: string
  data?: ZoneRecord
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues = (zoneRecord?: ZoneRecord) => {
  if (zoneRecord) {
    return {
      ...zoneRecord,
      hostname: zoneRecord.hostname,
      type: zoneRecord.type,
      data: zoneRecord.data
    }
  }
}

export const OnlineSwitch = ({ defaultValue = true }) => {
  const { t } = useTranslation()
  return (
    <Space>
      <Form.Item name={'online'} valuePropName="checked" label={t('STATUS')}>
        <Switch defaultChecked={defaultValue} />
      </Form.Item>
      <Typography.Text>Online</Typography.Text>
    </Space>
  )
}

const EditDns = ({ id }: ZoneRecordProps) => {
  const [form] = Form.useForm()
  const { setZoneRecord, zoneRecord } = useGenericContext(DnsContext)
  const { onCancel } = useGenericContext(ModalContext)
  const { t } = useTranslation()

  const close = () => {
    form.resetFields()
    onCancel && onCancel()
  }

  const { onFinish, contextHolder } = useForm(
    'edit',
    close,
    `/dns/zone/${zoneRecord.zone.zone_name}/record/${zoneRecord.uuid}`
  )

  return (
    <Form
      id={id}
      form={form}
      layout="vertical"
      initialValues={initialValues(zoneRecord)}
      onFinish={onFinish}
      onValuesChange={(_, allValues) =>
        setZoneRecord(prev => ({ ...prev, ...allValues }))
      }
    >
      {contextHolder}
      <InputWithForm
        label={t('HOSTNAME')}
        name={'hostname'}
        rules={[
          {
            required: true,
            pattern: new RegExp('^[^A-Z]*$'),
            message: t('HOSTNAMELOWERCASE')
          }
        ]}
      />
      <Row gutter={[8, 8]}>
        <Col lg={18} xs={24}>
          <SelectInput
            constant="DNS_TYPES"
            inputName="type"
            label={t('TYPE')} //
            required
            span={24}
          />
        </Col>
        <OnlineSwitch />
      </Row>
      <InputWithForm
        rules={[{ required: true }]}
        label={t('VALUE')}
        name={'data'}
      />
    </Form>
  )
}

export default EditDns
