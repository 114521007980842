import { Col, Form, Select } from 'antd'
import { TicketSelectProps } from '@/types/ITicketModalItems'
import { useTranslation } from 'react-i18next'

const SelectForAll = ({
  name = '',
  span = 6,
  loading,
  showSearch = false,
  required = false,
  ...props
}: TicketSelectProps) => {
  const { t } = useTranslation()
  return (
    <Col xs={{ span: 24 }} xl={{ span }}>
      <Form.Item
        name={name}
        rules={[{ required, message: t('requiredItem') }]}
        label={`${t(name)}:`}
      >
        <Select
          placeholder={t('CHOOSEANITEM')}
          showSearch={showSearch}
          loading={loading}
          {...props}
        />
      </Form.Item>
    </Col>
  )
}

export default SelectForAll
