import { DeviceContext } from '@contexts/DeviceContext'
import { api } from '@helpers/api'
import { useGenericContext } from '@hooks/useGenericContext'
import { useEffect, useState } from 'react'

const GraphTab = () => {
  const { data } = useGenericContext(DeviceContext)
  const [source, setSource] = useState('')

  useEffect(() => {
    api
      .get(`device/${data.uuid}/graphcomplete`)
      .then(e => setSource(e.data.url))
  }, [])

  return <iframe style={{ width: '100%', height: '90vh' }} src={source} />
}

export default GraphTab
