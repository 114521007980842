import { TICKETSPRIORITIESCOLORS } from '@constants/ticketspriorities'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { ITicket } from '@/types/ITicket'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

type StatusPriorityTagProps =
  | {
      obj: typeof TICKETSTATUSESCOLORS
      data: ITicket['status']
    }
  | {
      obj: typeof TICKETSPRIORITIESCOLORS
      data: ITicket['priority']
    }

const StatusPriorityTag = ({ data, obj }: StatusPriorityTagProps) => {
  const { t } = useTranslation()
  const item = obj[data]
  return (
    <>
      {item && item.hasOwnProperty('color') ? (
        <Tag color={item.color || 'blue'}>{t(data)}</Tag>
      ) : null}
    </>
  )
}

export default StatusPriorityTag
