import { useTranslation } from 'react-i18next'
import { Form, Row, Button, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import useForm from '@hooks/useForm'
import { api } from '@helpers/api'
import { OnboardText } from '@pages/Onboard/style'
import FormField from '@components/common/FormField'
import { useFormFields } from '@hooks/useFormFields'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import { MessageContext } from '@contexts/MessageContext'
import type { IOnboard } from '@/types/IOnboard'

const { Title } = Typography

const useCompanyData = (companyUuid: string) => {
  const [companyName, setCompanyName] = useState('')
  const [isPostponed, setIsPostponed] = useState(false)

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await api.get(`company/${companyUuid}`)
        setCompanyName(response.data.name)

        const userResponse = await api.get('session/user')
        setIsPostponed(userResponse.data.postponed)
      } catch (error) {
        setCompanyName('Company')
      }
    }
    fetchCompanyData()
  }, [companyUuid])

  return { companyName, isPostponed, setIsPostponed }
}

const formatTime = (time: dayjs.Dayjs | null) =>
  time ? time.format('HH:mm:ss') : null

const handleFormSubmit = async (values: IOnboard, onFinish: Function) => {
  const formattedValues = {
    ...values,
    birthday: values.birthday
      ? dayjs(values.birthday).format('YYYY-MM-DD')
      : null,
    cs_working_time_start_at: formatTime(values.cs_working_time_start_at),
    cs_working_time_end_at: formatTime(values.cs_working_time_end_at),
    cs_backup_time_start_at: formatTime(values.cs_backup_time_start_at),
    cs_backup_time_end_at: formatTime(values.cs_backup_time_end_at),
    cs_maintenance_time_start_at: formatTime(
      values.cs_maintenance_time_start_at
    ),
    cs_maintenance_time_end_at: formatTime(values.cs_maintenance_time_end_at)
  }

  onFinish(formattedValues)
}

const FormOnboardLayout = ({
  formFields,
  isPostponed,
  handlePostponed,
  handleSubmitButton,
  disabled
}: any) => (
  <>
    <Row
      gutter={[16, 20]}
      style={{
        width: '100%',
        padding: '3%',
        marginLeft: '2.5%',
        justifyContent: 'end'
      }}
    >
      {formFields.map((field: any) => (
        <FormField
          key={field.name}
          name={field.name}
          label={field.label}
          rules={field.rules}
          xs={24}
          md={12}
        >
          {field.component}
        </FormField>
      ))}
    </Row>
    <Row gutter={[16, 20]} style={{ width: '100%' }} justify="end">
      <Form.Item>
        {!isPostponed && (
          <Button type="primary" onClick={handlePostponed}>
            Postpone
          </Button>
        )}
        <Button
          type="primary"
          onClick={handleSubmitButton}
          disabled={disabled}
          style={{ marginLeft: '10px' }}
        >
          Submit
        </Button>
      </Form.Item>
    </Row>
  </>
)

const FormOnboard = ({ companyUuid }: { companyUuid: string }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { onFinish, disabled, contextHolder, responseData } = useForm(
    'create',
    () => setTimeout(() => navigate('/'), 2000),
    `/onboard/customer/${companyUuid}`
  )
  const messageApi = useGenericContext(MessageContext)

  const { companyName, isPostponed, setIsPostponed } =
    useCompanyData(companyUuid)
  const navigate = useNavigate()
  const formFields = useFormFields()

  const handlePostponed = async () => {
    await api
      .put(`/session/user/postponed`, { postponed: true })
      .then(e => {
        localStorage.setItem('POSTPONED', 'true')
        setIsPostponed(true)
        navigate('/')
      })
      .catch(err => handleError(err, messageApi, t('ERROR_POSTPONED')))
  }

  useEffect(() => {
    if (responseData) {
      messageApi.success(t('FORMONBOARD.SUCCESS_MESSAGE'), 7)
    }
  }, [responseData])

  return (
    <>
      <OnboardText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <p style={{ textAlign: 'center' }}>{t('FORMONBOARD.JOURNEY')}</p>
          <p style={{ textAlign: 'center' }}>
            {t('FORMONBOARD.QUESTIONNAIRE')}
          </p>
        </div>
      </OnboardText>
      <Title
        level={2}
        style={{
          marginBottom: '1%',
          marginTop: '1%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {companyName}
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={values => handleFormSubmit(values, onFinish)}
        style={{ width: '95vw' }}
        data-cy="form-onboard"
      >
        {contextHolder}
        <FormOnboardLayout
          formFields={formFields}
          isPostponed={isPostponed}
          handlePostponed={handlePostponed}
          handleSubmitButton={() => form.submit()}
          disabled={disabled}
        />
      </Form>
    </>
  )
}

export default FormOnboard
