import {
  FileDoneOutlined,
  SyncOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'

export const TICKETSTATUSESCOLORS = {
  NEW: { color: 'blue-inverse', icon: <InfoCircleOutlined /> },
  PROCESSING_ATTRIBUTED: {
    color: 'volcano-inverse',
    icon: <SyncOutlined spin />
  },
  PROCESSING_PLANNED: { color: '#FF7310', icon: <FileDoneOutlined /> },
  PENDING: { color: '#edb007', icon: <ClockCircleOutlined /> },
  SOLVED: { color: 'lime-inverse', icon: <CheckCircleOutlined /> },
  CLOSED: { color: '#333333', icon: <MinusCircleOutlined /> },
  UNDEFINED: { color: '#c6c6c6', icon: null },
  PRETICKET: { color: 'blue-inverse', icon: null },
  UNKNOWN: { color: '#c6c6c6', icon: null }
}
