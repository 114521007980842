import { ComponentType, MouseEventHandler, useContext, useState } from 'react'
import { Button, ButtonProps, FloatButton } from 'antd'
import CreateTicket from '@components/Ticket/CreationForm'
import EditTicket from '@components/Ticket/EditionForm'
import useModal from '@hooks/useModal'
import { useTheme } from 'styled-components'
import Modal from '@common/Modal'
import { TicketContext } from '@contexts/TicketContext'
import { ModalContext } from '@contexts/ModalContext'
import { useTranslation } from 'react-i18next'

interface ModalTicketProps extends Omit<ButtonProps, 'type'> {
  type?: string
  action: 'create' | 'edit' | 'schedule'
  tkid?: string | number
  float?: boolean
  tooltip?: string
}

interface ITicketOptions {
  component: ComponentType<{
    id: string
    type: string
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  }>
  call: string
}

interface ModalFooterProps {
  form: string
  action: 'create' | 'edit' | 'schedule'
  onClose: MouseEventHandler<HTMLElement>
  isDisabled: boolean
}

const options = (
  tkid: string | number | undefined
): Record<string, ITicketOptions> => {
  const { t } = useTranslation()
  return {
    create: {
      component: CreateTicket,
      call: t('CREATETICKET')
    },
    edit: {
      component: EditTicket,
      call: `${t('UPDATETICKET')} #${tkid || undefined}`
    },
    schedule: {
      component: EditTicket,
      call: `${t('RESCHEDULETICKET')} #${tkid}`
    }
  }
}

const CustomButton = ({ float = false, ...props }) => {
  const Tag = float ? FloatButton : Button
  return <Tag {...props} />
}

const ModalFooter = ({ onClose, form, isDisabled }: ModalFooterProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <>
      <Button
        onClick={onClose}
        danger
        type="primary"
        children={t('CANCEL')}
        data-cy="modal-ticket-cancel"
      />
      <Button
        children={t('CONFIRM')}
        data-cy="modal-ticket-confirm"
        style={{ backgroundColor: theme.blue, color: theme.white }}
        form={form}
        key="submit"
        htmlType="submit"
        disabled={isDisabled}
      />
    </>
  )
}

const ModalTicket = ({ action, ...props }: ModalTicketProps) => {
  const { open, onCancel, onOpen } = useModal()
  const context = useContext(TicketContext)
  const numForm = Math.floor(Math.random() * 10000).toString()
  const option = options(context?.data.tkid)
  const [disabled, setDisabled] = useState(false)

  const Component = option[action as keyof typeof options].component

  return (
    <ModalContext.Provider value={{ open, onCancel, onOpen }}>
      <Modal
        title={option[action as keyof typeof options].call}
        open={open}
        footer={
          <ModalFooter
            onClose={onCancel}
            action={action}
            form={numForm}
            isDisabled={disabled}
          />
        }
        onCancel={onCancel}
        centered
        width="55%"
      >
        <Component type={action} id={numForm} setDisabled={setDisabled} />
      </Modal>
      <CustomButton
        {...props}
        data-cy={`modal-ticket-${action}`}
        onClick={onOpen}
      />
    </ModalContext.Provider>
  )
}

export default ModalTicket
