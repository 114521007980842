import { useState } from 'react'
import { Button, ButtonProps, Tooltip } from 'antd'
import Modal from '@common/Modal'
import Icon from '@components/common/Icon'
import useModal from '@hooks/useModal'
import PDFViewer from '@components/common/PDFViewer'
import { IOrder } from '@/types/IOrder'
import { useTranslation } from 'react-i18next'
import { getOrderDocument } from '@helpers/getOrderDocument'

interface ModalCompanyProps extends ButtonProps {
  data: IOrder
}

const ModalDocument = ({ data, ...props }: ModalCompanyProps) => {
  const { open, onOpen, onCancel } = useModal()
  const { t } = useTranslation()
  const [url, setUrl] = useState<string>('')

  const handleClick = async () => {
    const response = await getOrderDocument(data.uuid)
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const pdfUrl = URL.createObjectURL(blob)
    setUrl(pdfUrl)
    onOpen()
  }

  return (
    <>
      <Modal
        footer={null}
        width={'80vh'}
        centered
        open={open}
        onCancel={onCancel}
        style={{ marginTop: '20px' }}
      >
        <PDFViewer url={url} />
      </Modal>

      <Tooltip title={t('SHOW')}>
        <Button
          {...props}
          type="primary"
          icon={<Icon name="fa-solid fa-eye" color="white" />}
          onClick={handleClick}
        />
      </Tooltip>
    </>
  )
}

export default ModalDocument
