import { FloatButton, Button, FloatButtonProps, ButtonProps } from 'antd'
import Modal from '@common/Modal'
import useModal from '@hooks/useModal'
import FormStatusProblem from '../Form'
import { IStatusProblem } from '@/types/IStatusProblem'
import ModalFooter from '@components/common/ModalFooter'

interface ModalStatusProblemProps extends FloatButtonProps {
  action: 'create' | 'edit'
  data?: IStatusProblem | undefined
}

const ModalStatusProblem = ({
  action,
  data,
  ...props
}: ModalStatusProblemProps) => {
  const { open, onOpen, onCancel } = useModal()
  const numForm = Math.floor(Math.random() * 10000).toString()

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        width="55%"
        centered
        footer={
          <ModalFooter onClose={onCancel} action={action} form={numForm} />
        }
      >
        <FormStatusProblem
          id={numForm}
          onClose={onCancel}
          data={data}
          action={action}
        />
      </Modal>
      {action === 'create' ? (
        <FloatButton onClick={onOpen} {...props} />
      ) : (
        <Button {...props} onClick={onOpen} />
      )}
    </>
  )
}

export default ModalStatusProblem
