import useTable from '@hooks/useTable'
import useWindowResize from '@hooks/useWindowResize'
import Card from '@components/common/Card'
import DeviceList from '../DeviceList'
import { DisplayContext } from '@contexts/DisplayContext'
import { useGenericContext } from '@hooks/useGenericContext'
import Table from '@components/common/Table'
import { DisplayContextType } from '@/types/DisplayContextType'
import { columns } from '@components/Device/Table'

const url = '/device'

const DeviceTableList = () => {
  const { list } = useGenericContext<DisplayContextType>(DisplayContext)
  const props = useTable(url)
  const { width } = useWindowResize()
  if (width < 768) {
    return <Card {...props} columns={columns} />
  }
  return (
    <>
      {list === 'list' ? (
        <DeviceList {...props} />
      ) : (
        <Table {...props} columns={columns} />
      )}
    </>
  )
}

export default DeviceTableList
