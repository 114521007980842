import { Row, Image } from 'antd'
import { useUser } from '@contexts/UserContext'

const OnboardImage = () => {
  return (
    <Image
      src="/assets/images/Email-CS_Fluxograma-5.png"
      alt="Imagem da jornada"
      preview={false}
      style={{ width: '100%', height: 'auto' }}
      data-cy="onboarding-image"
    />
  )
}

const OnboardingSection = () => {
  const { user } = useUser()
  return user.level === 1 ? (
    <Row
      justify="center"
      style={{ padding: '20px', marginTop: '20px' }}
      data-cy="onboarding-section"
    >
      <OnboardImage />
    </Row>
  ) : null
}

export default OnboardingSection
