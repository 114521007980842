import Title from '@common/Title'
import { Row, Col, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { columns } from '@components/Service/Table'
import { useTheme } from 'styled-components'
import SearchByQueryInput from '@common/SearchByQueryInput'
import ModalService from '@components/Service/Modal'
import { getGenericListsandFilter } from '@helpers/getGenericListsandFilter'
import useParamsSelect from '@hooks/useParamsSelect'

const SelectFilterBySector = () => {
  const selectProps = useParamsSelect({
    param: 'sector',
    func: async (search = '') =>
      await getGenericListsandFilter(`sector?search=${search}`),
    paramsToSearch: true
  })

  return (
    <Select
      {...selectProps}
      data-cy="ticket-service-sector-filter"
      style={{ width: '100%' }}
      filterOption={false}
      showSearch
      allowClear
    />
  )
}
const CreateService = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <ModalService
      style={{ backgroundColor: theme.green }}
      name="fa-light fa-plus"
      block
      children={t('CREATESERVICE')}
      data-cy={'create-service'}
    />
  )
}
const serviceFilterArr = [
  { span: 24, xl: { span: 8 }, children: <SearchByQueryInput span={24} /> },
  { span: 24, xl: { span: 8 }, children: <SelectFilterBySector /> },
  { span: 24, md: { offset: 5, span: 3 }, children: <CreateService /> }
]
const Services = () => {
  const { t } = useTranslation()
  return (
    <Row style={{ width: '100%' }} gutter={[8, 10]}>
      <Col span={24}>
        <Title name={t('SERVICES')} />
      </Col>
      {serviceFilterArr.map((item, index) => (
        <Col key={index} {...item} />
      ))}
      <Col span={24}>
        <ResponsiveTableCard url="/ticket/service?" columns={columns} />
      </Col>
    </Row>
  )
}

export default Services
