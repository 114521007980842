import { Card as ACard, Divider, List as AList, Row } from 'antd'
import { ITicket } from '@/types/ITicket'
import List from '@components/List'
import {
  CardInfos,
  CardFilterButtons,
  CardTitle,
  EditTicket
} from '../TicketCardItems'
import { UseTable } from '@/types/UseTable'
import TicketDrawer from '../TicketDrawer'

const Meta = ACard.Meta

const CardDescription = () => (
  <Row>
    <CardInfos />
    <Divider />
    <CardFilterButtons />
  </Row>
)
const TicketCard = ({ ticket }: { ticket: ITicket }) => {
  return (
    <TicketDrawer ticket={ticket}>
      <ACard
        style={{ width: '99%' }}
        title={<CardTitle />}
        extra={<EditTicket />}
      >
        <Meta description={<CardDescription />} />
      </ACard>
    </TicketDrawer>
  )
}

const TicketList = (props: UseTable<any, ITicket>) => (
  <List
    {...props}
    dataSource={props.data.data}
    loading={props.loading}
    renderItem={(item: ITicket) => (
      <AList.Item key={item.uuid}>
        <TicketCard ticket={item} />
      </AList.Item>
    )}
  />
)

export default TicketList
