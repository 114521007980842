import { IUser } from "@/types/IUser"

export const getAdditionalTabs = (dataType: string, items: any[], user: IUser) => {
  let additionalKeys: string[] = []

  if (dataType === 'VM') {
    additionalKeys = ['6', '7']
  } else if (dataType === 'BAREMETAL') {
    additionalKeys = user.level > 1 ? ['4', '5'] : ['5']
  }

  return additionalKeys
    .map(key => items.find(item => item.key === key))
    .filter((tab): tab is (typeof items)[0] => !!tab)
}
