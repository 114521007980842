import { Tag } from 'antd'
import Actions from '../Actions'
import dayjs from 'dayjs'
import { DATEFORMAT } from '@constants/dateformat'
import type { ColumnsType } from 'antd/es/table'
import type { IOrder } from '@/types/IOrder'
import TranslateText from '@components/common/TranslateText'
import OrderSteps from '../Steps'
import { useTranslation } from 'react-i18next'

const RejectedStatus = () => {
  const { t } = useTranslation()
  return <Tag color="red">{t('REJECTED')}</Tag>
}

export const columns: ColumnsType<IOrder> = [
  {
    title: 'ACTIONS',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, data) => <Actions data={data} />
  },
  {
    title: 'COMPANY',
    dataIndex: 'customer',
    key: 'customer',
    render: customer => <span>{customer?.name}</span>
  },
  {
    title: 'ORDER_DATE',
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => dayjs(created_at).format(DATEFORMAT)
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    key: 'type',
    render: type => <TranslateText name={type} />
  },
  {
    title: 'TOTAL_PRICE',
    dataIndex: 'price_total',
    key: 'price_total',
    render: price_total => <span>{`R$ ${price_total}`}</span>
  },
  {
    title: 'STEPS',
    dataIndex: 'status',
    key: 'status',
    render: (_, data) => (
      <>
        {data.status === 'REJECTED' ? (
          <RejectedStatus />
        ) : (
          <OrderSteps data={data} />
        )}
      </>
    )
  }
]
