import { MessageContext } from '@contexts/MessageContext'
import { PoolContext } from '@contexts/PoolContext'
import { api } from '@helpers/api'
import { handleError } from '@helpers/handleError'
import { useGenericContext } from '@hooks/useGenericContext'
import useSearchSelect from '@hooks/useSearchSelect'
import { Col, Form, InputNumber, TreeSelect } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface GenericInputFormItemProps extends PropsWithChildren {
  name: string
  label: string
  initialValue?: number
}

type DiskType = 'nvme' | 'ssd'

type TemplateType = Array<{
  name: string
  slug: string
  type: string
}>

type Templates = {
  [k in 'linux' | 'windows']: TemplateType
}

const AvaliableResources = () => {
  const DC = Form.useWatch('DC')
  return (
    <>
      {DC ? <TemplatesSelect dc={DC} /> : null}
      <CPUInput />
      <MEMInput />
      <DiskSizeInput />
    </>
  )
}

const templatesParser = (templates: Templates) => {
  const OsTypes = Object.keys(templates)
  return OsTypes.map(e => {
    const items = templates[e as keyof typeof templates]
    return {
      title: e,
      value: e,
      selectable: false,
      children: items.map(e => ({
        className: 'SO-' + e.name,
        title: e.name,
        value: e.slug
      }))
    }
  })
}

const TemplatesSelect = ({ dc }: { dc: string }) => {
  const messageApi = useGenericContext(MessageContext)
  const { t } = useTranslation()
  const { options, ...searchProps } = useSearchSelect({
    func: async (search?: string) =>
      await api
        .get(`/autodeploy/template/${dc}?search=${search || ''}`)
        .then(e => templatesParser(e.data))
        .catch(e => handleError(e, messageApi, t('ERRORCALLTEMPLATE')))
  })
  return (
    <Col span={24}>
      <Form.Item
        label={t('OPERATINGSYSTEM')}
        name="template"
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        <TreeSelect
          treeDefaultExpandAll
          data-cy="template-tree-select"
          {...searchProps}
          treeData={options as DefaultOptionType[]}
          allowClear
          showSearch
        ></TreeSelect>
      </Form.Item>
    </Col>
  )
}

const GenericInputFormItem = ({
  children,
  name,
  label,
  initialValue = 1
}: GenericInputFormItemProps) => {
  const { t } = useTranslation()
  return (
    <Col span={24}>
      <Form.Item
        label={t(label)}
        name={name}
        initialValue={initialValue}
        rules={[{ required: true, message: t('requiredItem') }]}
      >
        {children}
      </Form.Item>
    </Col>
  )
}

export const CPUInput = () => {
  const context = useGenericContext(PoolContext)
  return (
    <Col span={24}>
      <GenericInputFormItem label="CPU" name="cpu">
        <InputNumber
          data-cy="create-server-input-cpu"
          max={context.pool?.total.cpus_single_max.max}
          min={1}
        />
      </GenericInputFormItem>
    </Col>
  )
}
export const MEMInput = () => {
  const context = useGenericContext(PoolContext)
  return (
    <GenericInputFormItem name="mem" label="MEMORY">
      <InputNumber
        data-cy="create-server-input-mem"
        min={1}
        max={context.pool?.total.mems_single_max.max}
        addonAfter="GB"
      />
    </GenericInputFormItem>
  )
}

export const DiskSizeInput = () => {
  const context = useGenericContext(PoolContext)
  const diskType: DiskType = Form.useWatch('diskType')
  const max = context.pool?.total.disks_single_max[diskType]?.max
  return (
    <Col span={24}>
      {diskType ? (
        <GenericInputFormItem
          label="CAPACITY"
          name="diskSize"
          initialValue={40}
        >
          <InputNumber
            data-cy="create-server-input-capacity"
            min={40}
            max={max && max / Math.pow(2, 30)}
            addonAfter="GB"
          />
        </GenericInputFormItem>
      ) : null}
    </Col>
  )
}

export default AvaliableResources
