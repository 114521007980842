import { useTranslation } from 'react-i18next'
import FormList from '../FormList'
import { Col, Row } from 'antd'
import InputWithForm from '@components/common/InputWithForm'
import { DCSelect } from '@components/Products/ProductFormItems'
import { SelectInput } from '@components/common/SelectInput'

const ProductForm = () => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[8, 8]} style={{ width: '100%' }}>
          <Col span={8}>
            <InputWithForm required name="name" label={t('NAME')} />
          </Col>
          <DCSelect />
          <SelectInput
            inputName="type"
            span={8}
            label={'DEVICETYPE'}
            constant={'DEVICE_TYPES'}
            required
          />
          <FormList />
        </Row>
      </Col>
    </Row>
  )
}

export default ProductForm
