import { IRequestList } from '@/types/ICollection'
import { ITicket } from '@/types/ITicket'
import { Dispatch, SetStateAction, createContext } from 'react'

interface AssignedContextType {
  data: IRequestList<ITicket> | undefined
  setData: Dispatch<SetStateAction<IRequestList<ITicket> | undefined>>
  count: number
  setCount: Dispatch<SetStateAction<number>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const AssignedContext = createContext<AssignedContextType | undefined>(
  undefined
)
