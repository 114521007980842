import React, { createContext, useState } from 'react'

const ZoneContext = createContext<{
  zoneName: string
  setZoneName: React.Dispatch<React.SetStateAction<string>>
}>({
  zoneName: 'opendata.center.',
  setZoneName: () => {}
})

import { ReactNode } from 'react'

const ZoneProvider = ({ children }: { children: ReactNode }) => {
  const [zoneName, setZoneName] = useState('opendata.center.')

  return (
    <ZoneContext.Provider value={{ zoneName, setZoneName }}>
      {children}
    </ZoneContext.Provider>
  )
}

export { ZoneContext, ZoneProvider }
