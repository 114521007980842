export const BRASP2 = 'ff5ac50a-4167-416c-abf3-e18eea7f5d9e'
export const BRASP = 'cd9a5eee-ce97-4a0c-8de1-0f035f693c1e'
export const BRAGO = 'dddc9cbb-5810-4653-b3ac-b4629ecb3b85'
export const BRACE = '8269f27b-d9e4-4936-8683-714bcec10a59'
export const BRAPR = '35d59703-671b-4ee0-8e08-6a1054e5b411'
export const USAFL = 'd93c3187-dcb3-400e-8535-03672f73f8d1'
export const USATX = 'a9d978f9-bb34-4689-be2d-2a8112aadd32'

export const DCS = {
  BRASP2,
  BRASP,
  BRAGO,
  BRACE,
  USAFL,
  USATX
}
