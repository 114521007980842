import { Table as AntTable, Skeleton, TableProps } from 'antd'
import { useTranslation } from 'react-i18next'
import type { TableCardProps } from '../../types/ITableCard.d.ts'
import { translateColumns } from '@helpers/translateColumns'
import useTable from '@hooks/useTable'
import { UseTable } from '@/types/UseTable'
import { TableContext } from '@contexts/TableContext'

export const TableWithQuery = ({ url, columns, ...props }: TableCardProps) => {
  const queryProps = useTable(url)
  return <Table {...queryProps} {...props} columns={columns} />
}

const Table = <T, D>({
  columns,
  pagination,
  handleTableChange,
  loading,
  data,
  children,
  ...props
}: UseTable<T, D>) => {
  const { t } = useTranslation()

  const dataSource = data.data?.map(({ children, ...item }) => ({
    ...item,
    ...(children && children.length > 0 && { children })
  }))
  return (
    <TableContext.Provider value={{ data: props.data, setData: props.setData }}>
      {children}
      <AntTable
        rowKey="uuid"
        {...props}
        columns={translateColumns(columns, t)}
        dataSource={dataSource}
        pagination={{
          ...pagination,
          total: data.meta?.total | data?.total | 1,
          showTotal: () =>
            `${t('SHOWING')} ${data?.data?.length || 0} ${t('OF')}  ${
              data?.meta?.total || data?.total || 0
            } items`,
          pageSizeOptions: ['5', '10', '20', '50', '100', '200']
        }}
        onChange={handleTableChange}
        loading={loading}
      />
    </TableContext.Provider>
  )
}

export default Table
