import { useState } from 'react'
import { Button, ButtonProps, Drawer } from 'antd'
import useWindowResize from '@hooks/useWindowResize'
import { useTranslation } from 'react-i18next'
import DragAndDrop from '@components/Device/DragAndDrop'

const GroupButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  const { width } = useWindowResize()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Drawer
        title={t('GROUPS')}
        open={isOpen}
        footer={null}
        onClose={() => setIsOpen(false)}
        width={width < 720 ? width : '90vw'}
      >
        <DragAndDrop />
      </Drawer>
      <Button
        block
        {...props}
        data-cy="manage-groups"
        type="primary"
        onClick={() => setIsOpen(true)}
      >
        {t('MANAGEGROUPS')}
      </Button>
    </>
  )
}

export default GroupButton
