import { useEffect, useState } from 'react'
import { useUser } from '@contexts/UserContext'
import { generateTabItem } from '@common/Tab'
import { columns } from '../Table'
import { TICKETSTATUSESCOLORS } from '@constants/ticketstatuses'
import { Badge, Tabs } from 'antd'
import { api } from '@helpers/api'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { setSortOrder } from '@helpers/setSortOrder'

const useTicketTabs = () => {
  const { search } = useLocation()
  const uri = decodeURIComponent(search).slice(1)
  const [data, setData] = useState()

  const getCounts = async () =>
    await api.get(`ticket/count?${uri}`).then(e => {
      setData(e.data)
    })
  useEffect(() => {
    getCounts()
    //    const interval = setInterval(() => {
    //      getCounts()
    //    }, 5000)

    return () => {
      //clearInterval(interval)
    }
  }, [search])
  return { data }
}

type TabType = {
  label?: string
  param?: string
  count?: string
  color: string
}

type CountType = {
  [K in keyof typeof TICKETSTATUSESCOLORS]: string
}

const getCount = (tab: TabType, count: CountType) => {
  if (!tab.param) {
    return Object.values(count)
      .filter(key => key !== count['PRETICKET'])
      .reduce((a, b) => a + b, 0)
  } else if (tab.label === 'PROCESSING') {
    return count.PROCESSING_PLANNED + count.PROCESSING_ATTRIBUTED
  } else if (tab.label === 'SCHEDULED') {
    return count.PRETICKET
  } else return count[tab.label]
}

const Label = ({ count, tab }: { count: CountType; tab: TabType }) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (tab && count) {
      const res = getCount(tab, count)
      setValue(res)
    }
  }, [count])
  return (
    <>
      <Badge
        showZero={true}
        overflowCount={999999}
        count={value}
        color={tab.color}
        style={{ marginLeft: '4px', color: 'white' }}
      />
    </>
  )
}

const ticketTabArr = [
  { param: '', label: 'ALL', color: 'geekblue' },
  {
    param: '?status=NEW',
    label: 'NEW',
    color: 'blue'
  },
  {
    param: '?status=PROCESSING_PLANNED,PROCESSING_ATTRIBUTED',
    label: 'PROCESSING',
    color: TICKETSTATUSESCOLORS.PROCESSING_PLANNED.color
  },
  {
    param: '?status=PENDING',
    label: 'PENDING',
    color: TICKETSTATUSESCOLORS.PENDING.color
  },
  { param: '?status=SOLVED', label: 'SOLVED', color: 'lime' },
  {
    param: '?status=CLOSED',
    label: 'CLOSED',
    color: TICKETSTATUSESCOLORS.CLOSED.color
  },
  {
    param: '?type=PRETICKET&sort=schedule_at',
    label: 'SCHEDULED',
    count: 'PRETICKET',
    color: TICKETSTATUSESCOLORS.SOLVED.color
  }
]

export const renderingTabFilter = (formatUri: string) => {
  const { data } = useTicketTabs()
  const { user } = useUser()
  const { t } = useTranslation()
  const column = setSortOrder(columns)
  return ticketTabArr.map((tab, index) => {
    if (user.level === 1 && tab.label === 'SCHEDULED') return null
    return generateTabItem(
      {
        key: index.toString(),
        label: [t(tab.label), <Label key={index} {...{ count: data, tab }} />],
        url: `${formatUri}${tab.param}`
      },
      column
    )
  })
}

const TicketTabs = () => {
  const { user } = useUser()
  const renderItem = renderingTabFilter('/ticket')
  return (
    <Tabs
      destroyInactiveTabPane={true}
      defaultActiveKey={user.level > 1 ? '2' : '0'}
      items={renderItem}
      type="card"
      size="large"
    />
  )
}

export default TicketTabs
