import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { INotification, NotificationContextType } from '@/types/INotification'
import { api } from '@helpers/api'
import useSocket from '@hooks/useSocket'

type Filter = {
  service_table: string
  key: string
  value: string
}
export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined)

const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<INotification[] | undefined>(undefined)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState<Filter | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api
      .get('/notification/countUnread')
      .then(e => {
        setCount(e.data.count)
      })
      .finally(() => setLoading(false))
  }, [])

  useSocket(() => setCount(prevCount => prevCount + 1))
  return (
    <NotificationContext.Provider
      value={{
        data,
        setData,
        loading,
        count,
        setCount,
        page,
        setPage,
        setLoading,
        filter,
        setFilter
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationsProvider
