import { Row, Col, DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate} from "react-router-dom";
import useSearchParams from "@hooks/useSearchParams";

const { RangePicker } = DatePicker;

const formatDate = (date: any) => dayjs(date).format('DD-MM-YYYY HH:mm');

const onClear = (paramStart: string, paramEnd: string, persist: boolean, params: any, location: any, navigate: any) => {
  params.removeItem(paramStart);
  params.removeItem(paramEnd);

  if (persist) {
    localStorage.clear();
  }

  const newUrl = location.pathname;
  navigate(newUrl, { replace: true });
};

const onChange = (item: any, paramStart: string, paramEnd: string, params: any, location: any, navigate: any, onClear: any) => {
  if (item?.length === 2) {
    const [startDate, endDate] = item;
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    params.setItem(paramStart, formattedStartDate);
    params.setItem(paramEnd, formattedEndDate);

    const newParams = new URLSearchParams(location.search);
    newParams.set(paramStart, formattedStartDate);
    newParams.set(paramEnd, formattedEndDate);

    const newUrl = `${location.pathname}?${newParams.toString()}`;
    navigate(newUrl, { replace: true });
  } else {
    onClear(paramStart, paramEnd, false, params, location, navigate);
  }
};

const useTimeRange = (paramStart: string, paramEnd: string, persist = false) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useSearchParams(persist);

  const [value, setValue] = useState<Array<Dayjs> | undefined>(() => {
    const startDate = params.getItem(paramStart);
    const endDate = params.getItem(paramEnd);
    return startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : undefined;
  });

  useEffect(() => {
    if (!params.getItem(paramStart) && !params.getItem(paramEnd)) {
      setValue(undefined);
    }
  }, [location.search]);

  const handleClear = () => onClear(paramStart, paramEnd, persist, params, location, navigate);
  const handleChange = (item: any) => onChange(item, paramStart, paramEnd, params, location, navigate, handleClear);

  return { onChange: handleChange, value, defaultValue: value, onClear: handleClear };
};

const TimeRangePicker = () => {
    const { t } = useTranslation();
    const disableDate = (date: Dayjs) => dayjs() <= date;
  
    const renderRangePicker = (paramStart: string, paramEnd: string, placeholder: string[]) => {
      const range = useTimeRange(paramStart, paramEnd, true); 
      return (
        <Col>
          <RangePicker
            format="DD-MM-YYYY HH:mm"
            allowClear
            showTime
            {...range}
            disabledDate={disableDate}
            style={{ width: '100%' }}
            placeholder={placeholder}
            data-cy="filterTime"
          />
        </Col>
      );
    };
  
    return (
      <Row gutter={[10, 15]}>
        {renderRangePicker('created_at_start', 'created_at_end', [t('CREATED_START'), t('CREATED_END')])}
        {renderRangePicker('updated_at_start', 'updated_at_end', [t('UPDATED_START'), t('UPDATED_END')])}
      </Row>
    );
  };

export default TimeRangePicker;
