import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '@helpers/api'

const FirstLogin = () => {
  const navigate = useNavigate()
  const { uuid: token } = useParams()

  useEffect(() => {
    api.post('/session/fl', { token }).then(e => {
      if (e.data.token) {
        navigate('/reset-password', { state: e.data.token })
        return
      }
      navigate('/')
    })
  }, [token, navigate])
}

export default FirstLogin
